import React from "react";
import {Grid, Typography} from "@mui/material";
import * as yup from "yup";
import {useFormContext} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {OpenInNewOutlined} from "@mui/icons-material";
import {MIN_DATE, MAX_DATE, APP_PATHS, SCHEDULE_TIME_FORMAT} from "../../../config";
import {Field, ControlledTextField, ControlledTimePicker, ControlledSwitch, IconButton} from "../../generics/inputs";
import {ViewMode} from "../../../types";

export const OVERRIDE_KEYS=["override_start", "override_end"];
export const WEEK_KEYS=[{key: "m", label: "Monday"}, {key: "t", label: "Tuesday"}, {key: "w", label: "Wednesday"}, {key: "th", label: "Thursday"}, {key: "f", label: "Friday"}, {key: "sa", label: "Saturday"}, {key: "su", label: "Sunday"}];
export const SCHEDULE_KEYS=WEEK_KEYS.reduce((acc, {key}) => [...acc, `${key}_start`, `${key}_end`], [] as string[]); // ex: ["m_start", "m_end", "t_start", "t_end", ...]
export const SCHEDULE_SWITCH_KEYS=["is24HourFacility", "isSameDailySchedule"];
const TIME_CONFLICT_MSG="End Time must be greater than Start Time";
const INVALID_DATE="Invalid Date";
const RANGE_TEST="Invalid Range";

/**
 * checkRange
 * @param {any} value
 * @param {yup.TestContext} context
 * @return {boolean}
 */
const checkRange=(value:any, context:yup.TestContext):boolean => {
    const range=Object.values(_.pickBy(context.parent, (v, k):boolean => k.split("_")?.[0]===context.path.split("_")?.[0] && SCHEDULE_KEYS.includes(k)));
    if (range.filter((i:any) => i!==null).length===0) return true;
    if (!range.every((d:string) => moment(d, SCHEDULE_TIME_FORMAT).isValid())) return false;
    return true;
};

interface PropertyDetailsProps{
    fields:Field[]
    parentProperty:any
    recordtype:string
    viewMode:ViewMode
}

// NOTE: FIELDS ORDER MATTERS!!! check the render block's index to match the order of the fields
export const Fields:Field[] = [
    { // 0
        key: "name",
        label: "Property Name",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    { // 1
        key: "organization",
        label: "Operating Agency",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    { // 2
        key: "primaryfunction",
        label: "Primary Function",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    { // 3
        key: "id",
        label: "BDBID",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    { // 4
        key: "property_status",
        label: "Property Status",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    { // 5
        key: "address",
        label: "Complete Address",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    { // 6
        key: "n_floors",
        label: "Number of Floors",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    { // 7
        key: "building_size_sqft",
        label: "Gross Floor Area (sf)",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    { // 8
        key: "year_built",
        label: "Year Built",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    // parent_property_name only for child properties belong to campus,
    // and it's a frontend-generated key for the name of a parent property, not coming from the API
    { // 9
        key: "parent_property_name",
        label: "Parent Property",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    { // 10
        key: "is24HourFacility",
        label: "24 Hour Facility",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            labelPlacement: "end",
            disabled: false,
        },
    },
    { // 11
        key: "isSameDailySchedule",
        label: "Same Daily Schedule",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            labelPlacement: "end",
            disabled: false,
        },
    },
    { // 12
        key: "override_start",
        label: "Start Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 13
        key: "override_end",
        label: "End Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .min(yup.ref("override_start"), TIME_CONFLICT_MSG)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 14
        key: "m_start",
        label: "Start Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 15
        key: "m_end",
        label: "End Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .min(yup.ref("m_start"), TIME_CONFLICT_MSG)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 16
        key: "t_start",
        label: "Start Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 17
        key: "t_end",
        label: "End Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .min(yup.ref("t_start"), TIME_CONFLICT_MSG)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 18
        key: "w_start",
        label: "Start Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 19
        key: "w_end",
        label: "End Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .min(yup.ref("w_start"), TIME_CONFLICT_MSG)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 20
        key: "th_start",
        label: "Start Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 21
        key: "th_end",
        label: "End Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .min(yup.ref("th_start"), TIME_CONFLICT_MSG)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 22
        key: "f_start",
        label: "Start Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 23
        key: "f_end",
        label: "End Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .min(yup.ref("f_start"), TIME_CONFLICT_MSG)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 24
        key: "sa_start",
        label: "Start Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 25
        key: "sa_end",
        label: "End Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .min(yup.ref("sa_start"), TIME_CONFLICT_MSG)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 26
        key: "su_start",
        label: "Start Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
    { // 27
        key: "su_end",
        label: "End Time",
        yup: yup
            .date()
            .min(MIN_DATE)
            .max(MAX_DATE)
            .min(yup.ref("su_start"), TIME_CONFLICT_MSG)
            .nullable()
            .test("check-range", RANGE_TEST, checkRange)
            .typeError(INVALID_DATE),
        type: "timepicker",
        timepickerOptions: {
            readOnly: true,
        },
    },
];

/**
 * PropertyDetails
 * @param {PropertyDetailsProps} props
 * @return {React.ReactElement|null}
 */
function PropertyDetails(props:PropertyDetailsProps):React.ReactElement|null {
    const methods=useFormContext();
    const navigate=useNavigate();
    const overrideRow:React.ReactElement[]=[];
    const overrideStartIdx=Fields.findIndex((i:Field) => i.key==="override_start");
    const overrideEndIdx=Fields.findIndex((i:Field) => i.key==="override_end");
    if (methods.getValues().isSameDailySchedule===true) {
        overrideRow.push(<Grid item xs={2} key="override-row-picker"><Typography sx={{marginTop: "14px"}} variant="body1">Daily</Typography></Grid>);
        props.fields.slice(overrideStartIdx, overrideEndIdx + 1).forEach((field:Field, index:number) => {
            overrideRow.push(<Grid xs={4} item key={field.key}><ControlledTimePicker field={field} control={methods.control} errors={methods.formState.errors} /></Grid>);
            if (index===0) overrideRow.push(<Grid item key={`${field.key}-to-keyword`}><Typography sx={{marginTop: "14px"}} variant="body1">to</Typography></Grid>);
        });
    }

    // set link parent_property_name
    const link=props.fields.find((i:Field) => i.key==="parent_property_name");
    if (link?.textfieldOptions && props.parentProperty?.data.id) {
        link.textfieldOptions.endAdornment={
            component: IconButton,
            onClick: (value:string) => (args:React.MouseEvent) => {
                navigate(`${APP_PATHS.CAMPUS.replace(/:propertyId/g, props.parentProperty?.data.id)}`);
            },
            children: OpenInNewOutlined,
        };
    }

    return (
        <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
            {/* ready only property fields */}
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={6} spacing={2}>
                {/* [0-7] */}
                {props.fields.slice(0, 8).map((field:Field) => (
                    <Grid item xs={12} key={field.key}><ControlledTextField field={field} control={methods.control} /></Grid>
                ))}
                {/* year_built [8] */}
                {props.recordtype === "Building" && <Grid item xs={12}><ControlledTextField field={props.fields[8]} control={methods.control} /></Grid>}

            </Grid>
            {/* Second Column */}
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={6} spacing={0}>
                {/* parent_property_name [9] */}
                {props.parentProperty?.data.name && <Grid item xs={12}><ControlledTextField field={props.fields[9]} control={methods.control} /></Grid>}

                {/* Occupancy Schedule fields */}
                {/* column header item */}
                <Grid item xs={12}><Typography variant="body1" color="secondary.light">Occupancy Schedule</Typography></Grid>
                {/* switch items [10-11] */}
                {props.fields.filter((field:Field) => SCHEDULE_SWITCH_KEYS.includes(field.key)).map((field:Field) => (
                    <Grid key={field.key} item xs={12} sm={6} md={6} lg={6} xl={6}><ControlledSwitch key={field.key} field={field} control={methods.control} /></Grid>
                ))}
                {/* occupancy schedule item */}
                <Grid container item spacing={2} xs={12} sx={{marginTop: "12px"}}>
                    {/* override items [12-13] */}
                    {methods.getValues().isSameDailySchedule===true && props.viewMode==="EDIT_MODE" && (<Grid container item spacing={2}>{overrideRow}</Grid>)}
                    {/* iterate over week and create row [14-27] */}
                    {WEEK_KEYS.map(({label: day}, i) => {
                        const row:React.ReactElement[]=[];
                        const startIdx = 14 + (i*2);
                        const endIdx = startIdx + 1;
                        props.fields.slice(startIdx, endIdx + 1).forEach((field:Field, index:number) => {
                            row.push(<Grid xs={4} item key={field.key}><ControlledTimePicker field={field} control={methods.control} errors={methods.formState.errors} /></Grid>);
                            if (index===0) row.push(<Grid item key={`${field.key}-to-keyword`}><Typography sx={{marginTop: "14px"}} variant="body1">to</Typography></Grid>);
                        });

                        return (
                            <Grid key={`${day}-property-details-schedule`} container item spacing={2}>
                                {/* day | start | to | end */}
                                <Grid item xs={2}><Typography sx={{marginTop: "14px"}} variant="body1">{day}</Typography></Grid>
                                {row}
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PropertyDetails;

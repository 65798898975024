import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Grid} from "@mui/material";
import * as yup from "yup";
import {Button, ControlledSlider, Field, ControlledAutocomplete} from "../../generics/inputs";
import {populateValues} from "../../../handlers";

interface PropertiesFilterProps {
    onClose: (args:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onSubmit: (values:any) => void
    agencies?: string[]
    primaryfunctions?: string[]
    recordtypes?: string[]
    propertystatus?: string[]
    values?:any
}

/**
 * valueLabelFormat - Formats the value label for the slider.
 * @example 1000 -> "1K"
 * @param {number} value
 * @return {string}
 */
const valueLabelFormat = (value: number): string => {
    const units = ["", "K", "M"];
    let unitIndex = 0;
    let scaledValue = value;
    while (scaledValue >= 1000 && unitIndex < units.length - 1) {
        unitIndex += 1;
        scaledValue /= 1000;
    }
    return `${scaledValue}${units[unitIndex]}`;
};

export const Fields:Field[] = [
    {
        key: "organization_name",
        label: "Operating Agency",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
        },
    },
    {
        key: "primaryfunction",
        label: "Primary Function",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
        },
    },
    {
        key: "recordtype",
        label: "Record Type",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
        },
    },
    {
        key: "property_status",
        label: "Property Status",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
        },
    },
    {
        key: "grossfloorarea",
        label: "Gross Floor Area (sf)",
        yup: yup.object(),
        type: "slider",
        sliderOptions: {
            keys: {start: "building_size_sqft__le", end: "building_size_sqft__ge"},
            sliderMin: 0,
            sliderMax: 1000000,
            increment: 25000,
            valueLabelFormat,
            default: {building_size_sqft__le: 0, building_size_sqft__ge: 1000000},
            formatValue: true,
        },
    },
    {
        key: "yearbuilt",
        label: "Year Built",
        yup: yup.object(),
        type: "slider",
        sliderOptions: {
            keys: {start: "year_built__le", end: "year_built__ge"},
            sliderMin: 1870,
            sliderMax: 2023,
            increment: 10,
            default: {year_built__le: 1870, year_built__ge: 2023},
        },
    },
];

/**
 * PropertiesFilter
 * @param {PropertiesFilterProps}
 * @return {React.ReactElement}
 */
function PropertiesFilter(props: PropertiesFilterProps): React.ReactElement {
    // set field options
    const propKeyMapper=[
        {options: props.agencies, key: "organization_name"},
        {options: props.primaryfunctions, key: "primaryfunction"},
        {options: props.recordtypes, key: "recordtype"},
        {options: props.propertystatus, key: "property_status"},
    ];
    propKeyMapper.forEach((item:any) => { populateValues(item.options, Fields, item.key); });

    // construct yup object
    const yupObject: any = {};
    Fields.forEach((field:Field) => { yupObject[field.key] = field.yup; });

    const {handleSubmit, formState: {errors}, control, reset} = useForm({
        mode: "onSubmit",
        resolver: yupResolver(yup.object(yupObject)),
    });

    // setting current form values
    useEffect(() => {
        if (props?.values) reset(props?.values);
    }, [props?.values, reset]);

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Grid container>
                <Grid container paddingTop={2.5} paddingX={3} spacing={2}>
                    {Fields.map((field:Field) => {
                        // NOTE: escape if prop (options) does not exist;
                        const propKey=propKeyMapper.find((i:any) => i.key===field.key);
                        if (propKey!==undefined && propKey?.options===undefined) return null;
                        return (
                            <Grid key={field.key} item xs={field.type === "autocomplete" ? 6 : 12}>
                                { field.type === "autocomplete" && <ControlledAutocomplete field={field} control={control} errors={errors} />}
                                { field.type === "slider" && <ControlledSlider field={field} control={control} />}
                            </Grid>
                        );
                    })}
                </Grid>

                <Grid item display="flex" marginLeft="auto" padding={1}>
                    <Button label="cancel" color="secondary" onClick={props.onClose} />
                    <Button label="apply" type="submit" />
                </Grid>
            </Grid>
        </form>
    );
}

PropertiesFilter.defaultProps={
    values: undefined,
    agencies: undefined,
    primaryfunctions: undefined,
    recordtypes: undefined,
    propertystatus: undefined,
};

export default PropertiesFilter;

import React from "react";
import {Link} from "react-router-dom";
import {
    Tabs as TabsBase,
    Tab as TabBase,
} from "@mui/material";
import {TabType, TabProps} from "../../types";

interface Props{
    tab:TabType
    tabs:TabProps[]
}

/**
 * Tabs
 * @return {React.ReactElement}
 */
function Tabs(props:Props):React.ReactElement {
    return (
        <TabsBase value={props.tab} sx={{borderBottom: 1, borderColor: "divider"}}>
            {props.tabs.map((tab:TabProps) => (
                <TabBase icon={<tab.icon />} iconPosition="start" value={tab.tabKey} key={tab.tabKey} label={tab.label} component={Link} to={tab.to} />
            ))}
        </TabsBase>
    );
}

export default Tabs;

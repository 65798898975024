import React from "react";
import {Typography, Grid} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";
import {Button} from "./inputs";

interface EmptyStateBlockProps {
    icon:SvgIconComponent
    header:string
    subHeaders?:string[]
    primaryAction?:ActionButtonProps
    secondaryAction?:ActionButtonProps
}

interface ActionButtonProps {
    onClick:(args:React.MouseEvent) => void
    label:string
    startIcon:React.ReactElement
    disabled?:boolean
}

/**
 * EmptyStateBlock
 * @param {EmptyStateBlockProps} props
 * @return {React.ReactElement}
 */
function EmptyStateBlock(props: EmptyStateBlockProps): React.ReactElement {
    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" gap={2} sx={{height: "100%"}}>
            {/* Icon */}
            <Grid item><props.icon sx={{width: "150px", height: "150px", color: "#333B391F"}} /></Grid>

            {/* Header */}
            <Grid item><Typography variant="h4">{props.header}</Typography></Grid>

            {/* SubHeader container */}
            {props.subHeaders&&(
                <Grid container direction="column" alignItems="center">
                    {/* SubHeaders */}
                    {props.subHeaders.map((subHeader) => (
                        <Grid item key={subHeader}><Typography variant="body1">{subHeader}</Typography></Grid>
                    ))}
                </Grid>
            )}

            {/* Action Button container */}
            {(props.secondaryAction||props.primaryAction)&&(
                <Grid item>
                    <Grid container gap={2}>
                        {/* Secondary Action */}
                        {props.secondaryAction&&(
                            <Grid item>
                                <Button variant="contained" size="large" color="secondary" {...props.secondaryAction} />
                            </Grid>
                        )}

                        {/* Primary Action */}
                        {props.primaryAction&&(
                            <Grid item>
                                <Button variant="contained" size="large" color="primary" {...props.primaryAction} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

EmptyStateBlock.defaultProps = {
    subHeaders: undefined,
    primaryAction: undefined,
    secondaryAction: undefined,
};

export default EmptyStateBlock;

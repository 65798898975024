import React from "react";
import {Dialog as DialogBase, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {FieldErrors, UseFormHandleSubmit} from "react-hook-form";
import {enqueueSnackbar} from "notistack";
import {Button} from "./inputs";
import {FORM_REQUIRED_SNACK_MESSAGE} from "../../config";

interface ActionProps{
    onClick:(args:any) => void
    label?:string
}

interface Props{
    open:boolean
    onClose:ActionProps
    onPrimary?:ActionProps
    submitHandler?:UseFormHandleSubmit<any, any>
    title:string|React.ReactElement
    content:React.ReactElement
    maxWidth?:"xs"|"sm"|"md"|"lg"|"xl"
}

/**
 * Dialog
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Dialog(props:Props):React.ReactElement {
    /**
     * onError
     * @param {FieldErrors} args
     */
    const onError=(args:FieldErrors):void => { enqueueSnackbar(FORM_REQUIRED_SNACK_MESSAGE, {variant: "error"}); };

    const content=(
        <>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>{props.content}</DialogContent>
            <DialogActions>
                {props.onClose && <Button sx={{width: "auto"}} label={`${props.onClose.label||"Cancel"}`} variant="text" color="secondary" onClick={props.onClose.onClick} />}
                {props.onPrimary && <Button sx={{width: "auto"}} type={props.submitHandler?"submit":"button"} label={`${props.onPrimary.label||"Submit"}`} variant="text" onClick={props.submitHandler?undefined:props.onPrimary.onClick} />}
            </DialogActions>
        </>
    );
    return (
        <DialogBase open={props.open} onClose={props.onClose.onClick} maxWidth={props.maxWidth||"xs"} fullWidth>
            {/* when submitHandler, wrap with form otherwise pass regular content */}
            {props.submitHandler && props.onPrimary
                ?<form onSubmit={props.submitHandler(props.onPrimary?.onClick, onError)}>{content}</form>
                :content}
        </DialogBase>
    );
}

Dialog.defaultProps={
    onPrimary: undefined,
    submitHandler: undefined,
    maxWidth: undefined,
};

export default Dialog;

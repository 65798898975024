import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Grid} from "@mui/material";
import * as yup from "yup";
import {Button, Field, ControlledAutocomplete, ControlledSwitch} from "../../generics/inputs";
import {populateValues} from "../../../handlers";

interface EquipmentFilterProps {
    onClose: (args:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onSubmit: (values:any) => void
    categories: string[]
    equipments: string[]
    types: string[]
    values?:any
}

export const Fields:Field[] = [
    {
        key: "plant",
        label: "Category",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
        },
    },
    {
        key: "type",
        label: "Type",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
        },
    },
    {
        key: "equipment",
        label: "Equipment",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
        },
    },
    {
        key: "vfd",
        label: "VFD",
        yup: yup.mixed().nullable(),
        type: "switch",
    },
];

/**
 * EquipmentFilter
 * @param {EquipmentFilterProps} props
 * @return {React.ReactElement}
 */
function EquipmentFilter(props:EquipmentFilterProps):React.ReactElement {
    // set field options
    [
        {options: props.categories, key: "plant"},
        {options: props.equipments, key: "equipment"},
        {options: props.types, key: "type"},
    ].forEach((item:any) => { populateValues(item.options, Fields, item.key); });

    // construct yup object
    const yupObject: any = {};
    Fields.forEach((field:Field) => { yupObject[field.key] = field.yup; });

    const {handleSubmit, formState: {errors}, control, reset} = useForm({
        mode: "onSubmit",
        resolver: yupResolver(yup.object(yupObject)),
    });

    // setting current form values
    useEffect(() => {
        if (props?.values) reset(props?.values);
    }, [props?.values, reset]);

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Grid container>
                <Grid container columnSpacing={3} paddingTop={2.5} paddingX={3}>
                    {Fields.map((field:Field) => (
                        <Grid key={field.key} item xs={6}>
                            {field.type==="autocomplete" && <ControlledAutocomplete field={field} control={control} errors={errors} />}
                            {field.type==="switch" && <ControlledSwitch field={field} control={control} errors={errors} />}
                        </Grid>
                    ))}
                </Grid>

                <Grid item display="flex" marginLeft="auto" padding={1}>
                    <Button label="cancel" color="secondary" onClick={props.onClose} />
                    <Button label="apply" type="submit" />
                </Grid>
            </Grid>
        </form>
    );
}

EquipmentFilter.defaultProps={
    values: undefined,
};

export default EquipmentFilter;

import React, {useEffect, useState, useCallback, SetStateAction, Dispatch} from "react";
import {Box, Typography} from "@mui/material";
import {ArchiveOutlined, LanOutlined, AddOutlined, SmsFailedOutlined, DeleteOutlined, UnarchiveOutlined, ControlPointDuplicateOutlined} from "@mui/icons-material";
import {GridRenderCellParams, GridColDef} from "@mui/x-data-grid";
import {useNavigate, useLocation} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {AxiosResponse} from "axios";
import _ from "lodash";
import {APP_PATHS, PROPERTY_ID_BAS, ARCHIVED_REASONS, BAS_ID} from "../config";
import {resolveFilterChipValues, constructBasArchiveConfig, constructBasDeleteConfig} from "../handlers";
import {ParentProps, FilterChipType, DialogType, SearchType} from "../types";
import {useContext} from "../components/generics/Context";
import {useCall, BaseState} from "../components/generics/Call";
// components
import DataGrid from "../components/generics/DataGrid";
import {BaseSkeleton} from "../components/generics/DataGridUtils";
import Link from "../components/generics/Link";
import EmptyStateBlock from "../components/generics/EmptyStateBlock";
import {Button} from "../components/generics/inputs";
import {Search as SearchFilter, validateSearch, Archive, Prompt} from "../components/forms";

interface Props extends ParentProps{
    // NOTE: Empty For future expansion.
}

const BAS_COLUMNS_DEF:GridColDef[]=[
    {
        field: "software",
        headerName: "Software",
        flex: 1.0,
        renderCell: (params:GridRenderCellParams) => (
            <Link
                to={APP_PATHS.BAS_DETAILS
                    .replace(/:propertyId/g, params.row.property_id)
                    .replace(/:basId/g, params.row.id)}
            >
                {params.value}
            </Link>
        ),
    },
    {field: "manufacturer", headerName: "Manufacturer", flex: 1.0},
    {field: "trending_access", headerName: "Trending Access", flex: 0.5},
    {field: "year_installed", headerName: "Year Installed", flex: 0.3},
    {field: "version", headerName: "Version", flex: 0.5},
    {field: "bas_service_contract", headerName: "Service contract", flex: 0.5},
    {field: "remote_access_capability", headerName: "Remote Access", flex: 0.5},

];

export interface State extends BaseState{
    row:AxiosResponse|null
    bases:AxiosResponse|null
    search:SearchType|null
    showArchived:boolean
}

/**
 * Bas
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Bas(props:Props):React.ReactElement {
    const context=useContext();
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        search: null,
        showArchived: false,
        dialog: "NONE",
        record: null,
        row: null,
        bases: null,
        formValues: null,
    });
    const location:any=useLocation();
    const navigate=useNavigate();
    const {call, get}=useCall({state, setState: (setState as any)});

    /**
     * onDialogClick
     * @param {DialogType} type
     * @param {State} newState
     * @return {void}
     */
    const onDialogClick=(type:DialogType, newState?:State) => (args:React.MouseEvent):void => setState({...(newState||_.cloneDeep(state)), dialog: type});

    /**
     * resetState
     */
    const resetState=useCallback(() => {
        const stateClone=_.cloneDeep(state);
        stateClone.dialog="NONE";
        stateClone.formValues=null;
        stateClone.row=null;
        stateClone.record=null;
        stateClone.bases=null;
        setState(stateClone);
    }, [state]);
    /**
     * onArchiveSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onArchiveSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.row?.data.software} BAS archived`, {variant: "success"});
        // trigger refresh cycle
        resetState();
        context.setViewState("REFRESH");
    }, [context, resetState, state.row?.data.software]);

    /**
     * onRestoreSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onRestoreSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.row?.data.software} BAS restored`, {variant: "success"});
        // trigger refresh cycle
        resetState();
        context.setViewState("REFRESH");
    }, [context, resetState, state.row?.data.software]);

    /**
     * onDeleteSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onDeleteSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.row?.data.software} BAS Deleted`, {variant: "success"});
        // trigger refresh cycle
        resetState();
        context.setViewState("REFRESH");
    }, [context, resetState, state.row?.data.software]);

    /**
     * onArchiveSubmit
     * @param {any} values
     * @return {void}
     */
    const onArchiveSubmit= (values:any):void => {
        try {
            call(constructBasArchiveConfig(values, props.property, state.row as AxiosResponse, true), values, onArchiveSuccess, "archive");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onRestoreSubmit
     * @param {any} values
     * @return {void}
     */
    const onRestoreSubmit= async (values:any):Promise<void> => {
        try {
            call(constructBasArchiveConfig(null, props.property, state.row as AxiosResponse, false), null, onRestoreSuccess, "restore");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onDeleteSubmit
     * @param {any} values
     * @return {void}
     */
    const onDeleteSubmit= async (values:any):Promise<void> => {
        try {
            call(constructBasDeleteConfig(state.record as AxiosResponse), null, onDeleteSuccess, "delete");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onSearchSubmit
     * @param {any} value
     * @return {Promise<void>}
     */
    const onSearchSubmit=async (value:any):Promise<void> => {
        if (await validateSearch(value.search)!==undefined) setState({..._.cloneDeep(state), search: {key: "software__contains", value: value.search}});
    };

    /**
     * onSearchClear
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onSearchClear=(args:React.MouseEvent):void => setState({..._.cloneDeep(state), search: null});

    /**
     * transformData
     * @param {AxiosResponse} response
     * @param {GridColDef[]} colDef
     * @return {AxiosResponse}
     */
    const transformData=(response:AxiosResponse, colDef:GridColDef[]):AxiosResponse => {
        const resClone=_.cloneDeep(response);
        resClone.data.results=resClone.data.results.filter((item:any) => ((item.archived===state.showArchived) && (state.search?item.software.includes(state.search.value):true)));
        resClone.data.total_count=resClone.data.results.length;
        return resClone;
    };

    // 412 handling
    useEffect(() => {
        if (context.viewState==="PENDING_END" && state.record && state.formValues) {
            if ("archive" in state.formValues) call(constructBasArchiveConfig(state.formValues.archive, props.property, state.record, true), state.formValues.archive, onArchiveSuccess, "archive");
            else if ("restore" in state.formValues) call(constructBasArchiveConfig(state.formValues.restore, props.property, state.record, false), state.formValues.restore, onRestoreSuccess, "restore");
            else if ("delete" in state.formValues) call(constructBasDeleteConfig(state.record as AxiosResponse), null, onDeleteSuccess, "delete");
        }
    }, [context.viewState, call, props.property, state.formValues, state.record, onArchiveSuccess, onRestoreSuccess, onDeleteSuccess]);

    useEffect(() => {
        if (state.row?.data && state.record===null) get(`${BAS_ID.replace(/{id}/g, state.row.data?.id)}`, "record");
        if ((props.property?.data.parent_property_id || props.property?.data.id) && state.bases===null) get(`${PROPERTY_ID_BAS.replace(/{id}/g, props.property?.data.parent_property_id||props.property?.data.id)}`, "bases", true);
        if (state.record?.status===410) resetState();
    }, [state.record, get, state, state.row, props.property, resetState]);

    useEffect(() => {
        if (location.state?.isPostCanceled) {
            enqueueSnackbar("Add BAS canceled", {variant: "warning"});
            window.history.replaceState({}, "");
        }
    }, [location.state?.isPostCanceled]);

    const result:FilterChipType=resolveFilterChipValues(null, null, null, state.search, state.showArchived);

    if (context.viewState==="LOADING") return <BaseSkeleton />;

    return (
        <Box>
            {/* archive dialog */}
            {state.row?.data && (
                <Archive
                    status={state.dialog}
                    dialogType="BAS_ARCHIVE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={onArchiveSubmit}
                    title={state.row.data.software}
                    message={(
                        <Box>
                            <Typography variant="body1">
                                Archived BAS will not be included in exports or reports for this property. The BAS association with equipment is maintained.
                            </Typography>
                            <Typography variant="body1">Archived BAS can be restored.</Typography>
                        </Box>
                    )}
                    reasons={ARCHIVED_REASONS}
                />
            )}
            {/* restore dialog */}
            {state.row?.data && (
                <Prompt
                    status={state.dialog}
                    dialogType="BAS_RESTORE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={{onClick: onRestoreSubmit, label: "Restore"}}
                    title={`Restore ${state.row?.data.software}`}
                    message={(<Typography variant="body1">Restored BAS will be included in exports or reports for this property.</Typography>)}
                />
            )}
            {/* delete dialog */}
            {state.row?.data && (
                <Prompt
                    status={state.dialog}
                    dialogType="BAS_DELETE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={{onClick: onDeleteSubmit, label: "Delete"}}
                    title={`Delete ${state.row?.data.software}`}
                    message={(<Typography variant="body1">This action cannot be undone.</Typography>)}
                />
            )}
            {/* Table */}
            <DataGrid
                columns={BAS_COLUMNS_DEF}
                rows={state.bases}
                transformData={transformData}
                search={<SearchFilter values={state.search?.value} onSubmit={onSearchSubmit} onClear={onSearchClear} helperText="Search for BAS in this property using software name" />}
                state={state}
                setState={setState}
                filterFields
                actions={
                    result.query.get("archived")==="true"
                        ?[
                            {
                                label: "Restore",
                                key: "restore",
                                icon: <UnarchiveOutlined />,
                                onClick: (row) => (args:React.MouseEvent):void => {
                                    onDialogClick("BAS_RESTORE", {..._.cloneDeep(state), row: {data: row} as AxiosResponse})(args);
                                },
                            },
                            // {
                            //     label: "Delete",
                            //     key: "delete",
                            //     color: "warning",
                            //     icon: <DeleteOutlined />,
                            //     onClick: (row) => (args:React.MouseEvent):void => {
                            //         onDialogClick("BAS_DELETE", {..._.cloneDeep(state), row: {data: row} as AxiosResponse})(args);
                            //     },
                            // }
                        ]
                        :[{
                            label: "Duplicate",
                            key: "duplicate",
                            icon: <ControlPointDuplicateOutlined />,
                            onClick: (row) => (args:React.MouseEvent):void => {
                                enqueueSnackbar("Building Automation System duplicated", {variant: "success"});
                                navigate(
                                    APP_PATHS.BAS_POST.replace(/:propertyId/g, props.property.data.id),
                                    {state: {record: {data: _.omit(row, ["software", "version", "id"])}}},
                                );
                            },
                        },
                        {
                            label: "Archive",
                            key: "archive",
                            icon: <ArchiveOutlined />,
                            onClick: (row) => (args:React.MouseEvent):void => {
                                onDialogClick("BAS_ARCHIVE", {..._.cloneDeep(state), row: {data: row} as AxiosResponse})(args);
                            },
                        }]
                }
                isQuery={result.isQuery}
                baseNoRowsOverlay={(
                    <EmptyStateBlock
                        header="No BAS for this property"
                        subHeaders={["Start by adding a BAS and then proceed to add equipment"]}
                        icon={LanOutlined}
                        primaryAction={{
                            onClick: (args:React.MouseEvent) => navigate(APP_PATHS.BAS_POST.replace(/:propertyId/g, props.property.data.id)),
                            label: "ADD BAS",
                            startIcon: <AddOutlined />,
                        }}
                    />
                )}
                noRowsOverlay={(
                    <EmptyStateBlock
                        header="No BAS match search criteria."
                        subHeaders={["Try a different search term or filter."]}
                        icon={SmsFailedOutlined}
                    />
                )}
                primaryAction={<Button sx={{width: "auto"}} label="Add BAS" onClick={(args:any) => navigate(APP_PATHS.BAS_POST.replace(/:propertyId/g, props.property.data.id))} variant="contained" size="large" startIcon={<AddOutlined />} />}
            />
        </Box>
    );
}

export default Bas;

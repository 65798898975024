import React from "react";
import {Breadcrumbs as BaseBreadcrumbs, Typography} from "@mui/material";
import {Link as LinkBase} from "react-router-dom";
import {NavigateNext} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {APP_PATHS, APP_PATHS_KEYS} from "../../config";
import {EQUIPMENTS} from "../forms";

export const Link = styled(LinkBase)(({theme}) => ({
    ...theme.mixins.toolbar,
}));

/**
 * Breadcrumbs
 * @return {React.ReactElement}
 */
function Breadcrumbs():React.ReactElement {
    // link crumbs
    const links=[];
    // push base HOME
    links.push(<Link key={APP_PATHS.HOME} color="inherit" to={APP_PATHS.HOME}>Home</Link>);
    // generate an array of subpaths
    const subpaths=window.location.pathname.split("/").filter((v:string) => v.length!==0);
    subpaths.forEach((sub:string, index:number) => {
        // assemble crumb path
        const to=`/${subpaths.slice(0, index+1).join("/")}`;
        const equipment=EQUIPMENTS.find((e:any) => e.key===sub);
        // escape ids as path will be invalid
        if (!Number.isNaN(Number.parseInt(sub, 10))) links.push(<Typography key={to}>{sub}</Typography>);
        else if (equipment!==undefined) links.push(<Typography key={to}>{equipment.label}</Typography>);
        else if (APP_PATHS_KEYS.includes(sub)) links.push(<Typography sx={{textTransform: "capitalize"}} key={to}>{sub}</Typography>);
        else links.push(<Link sx={{textTransform: sub==="bas"?"uppercase":"capitalize"}} key={to} color="inherit" to={to}>{sub}</Link>);
    });
    return (
        <BaseBreadcrumbs separator={<NavigateNext fontSize="small" />}>
            {links}
        </BaseBreadcrumbs>
    );
}

export default Breadcrumbs;

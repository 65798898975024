import React from "react";
import * as yup from "yup";
import {EquipmentType, Component, PointType} from "../../types";
import {Fields as PointFields} from "./common/Point";
import {Field} from "../generics/inputs";

// systems
import Ahu, {Fields as AhuFields, toApi as ahuToApi, fromApi as ahuFromApi} from "./equipment/system/Ahu";
import Hvu, {Fields as HvuFields, toApi as hvuToApi, fromApi as hvuFromApi} from "./equipment/system/Hvu";
import Pu, {Fields as PuFields, toApi as puToApi, fromApi as puFromApi} from "./equipment/system/Pu";
import Eru, {Fields as EruFields, toApi as eruToApi, fromApi as eruFromApi} from "./equipment/system/Eru";
import ExhaustFan, {Fields as ExhaustFanFields, toApi as exhaustFanToApi, fromApi as exhaustFanFromApi} from "./equipment/system/ExhaustFan";
import Avb, {CavbFields, VavbFields, toApi as avbToApi, fromApi as avbFromApi} from "./equipment/system/Avb";
import ChilledBeam, {Fields as ChilledBeamFields, toApi as chilledBeamToApi, fromApi as chilledBeamFromApi} from "./equipment/system/ChilledBeam";
import Cuh, {Fields as CuhFields, toApi as cuhToApi, fromApi as cuhFromApi} from "./equipment/system/Cuh";
import Fcu, {Fields as FcuFields, toApi as fcuToApi, fromApi as fcuFromApi} from "./equipment/system/Fcu";
import HeatPump, {Fields as HeatPumpFields, toApi as heatPumpToApi, fromApi as heatPumpFromApi} from "./equipment/system/HeatPump";
import Ptac, {Fields as PtacFields, toApi as ptacToApi, fromApi as ptacFromApi} from "./equipment/system/Ptac";
import Radiantunit, {Fields as RadiantunitFields, toApi as radiantunitToApi, fromApi as radiantunitFromApi} from "./equipment/system/Radiantunit";
import Vrfu, {Fields as VrfuFields, toApi as vrfuToApi, fromApi as vrfuFromApi} from "./equipment/system/Vrfu";
import ACChiller, {Fields as ACChillerFields, toApi as aCChillerToApi, fromApi as aCChillerFromApi} from "./equipment/system/ACChiller";
import Chwloop, {Fields as ChwloopFields, toApi as chwloopToApi, fromApi as chwloopFromApi} from "./equipment/system/Chwloop";
import Conwloop, {Fields as ConwloopFields, toApi as conwloopToApi, fromApi as conwloopFromApi} from "./equipment/system/Conwloop";
import WCChiller, {Fields as WCChillerFields, toApi as wCChillerToApi, fromApi as wCChillerFromApi} from "./equipment/system/WCChiller";
import WCCondenser, {Fields as WCCondenserFields, toApi as wCCondenserToApi, fromApi as wCCondenserFromApi} from "./equipment/system/WCCondenser";
import Dhw, {Fields as DhwFields, toApi as dhwToApi, fromApi as dhwFromApi} from "./equipment/system/Dhw";
import DistrictSteam, {Fields as DistrictSteamFields, toApi as districtSteamToApi, fromApi as districtSteamFromApi} from "./equipment/system/DistrictSteam";
import HWBoiler, {Fields as HWBoilerFields, toApi as hWBoilerToApi, fromApi as hWBoilerFromApi} from "./equipment/system/HWBoiler";
import Hwd, {Fields as HwdFields, toApi as hwdToApi, fromApi as hwdFromApi} from "./equipment/system/Hwd";
import SWBoiler, {Fields as SWBoilerFields, toApi as sWBoilerToApi, fromApi as sWBoilerFromApi} from "./equipment/system/SWBoiler";
// components
import Base, {Fields as BaseFields, resolveValues as resolveBaseValues} from "./equipment/component/Base";

export {default as PropertiesFilter, Fields as PropertiesFields} from "./filters/PropertiesFilter";
export {default as EquipmentFilter, Fields as EquipmentFields} from "./filters/EquipmentFilter";
export {default as Search, Fields as SearchFields, validateSearch} from "./filters/Search";
export {default as Campus, Fields as CampusFields} from "./property/Campus";
export {default as AddEquipmentFilter} from "./filters/AddEquipmentFilter";
export {default as PropertyDetails, Fields as PropertyDetailsFields, SCHEDULE_SWITCH_KEYS, SCHEDULE_KEYS, OVERRIDE_KEYS, WEEK_KEYS} from "./property/PropertyDetails";
export {default as PropertyDocuments, Fields as PropertyDocumentsFields} from "./property/PropertyDocuments";
export {default as OperatorDetails, Fields as OperatorDetailsFields} from "./property/OperatorDetails";
export {default as EnertracDetails, Fields as EnertracDetailsFields} from "./property/EnertracDetails";
export {default as RealTimeMeters, Fields as RealTimeMetersFields} from "./property/RealTimeMeters";
export {default as Overview, Fields as OverviewFields} from "./bas/Overview";
export {default as RemoteAccess, Fields as RemoteAccessFields} from "./bas/RemoteAccess";
export {default as Trending, Fields as TrendingFields} from "./bas/Trending";
export {default as ControlFunctions, Fields as ControlFunctionsFields} from "./bas/ControlFunctions";
export {default as Base, Fields as BaseFields, resolveValues as resolveBaseValues} from "./equipment/component/Base";
export {default as Ahu, Fields as AhuFields} from "./equipment/system/Ahu";
export {default as Archive} from "./common/Archive";
export {default as Prompt} from "./common/Prompt";
export {default as Point, Fields as PointFields} from "./common/Point";

/**
 * AddBaseFields
 * @param {string[]|PointType[]} options
 * @param {pointsOptions[]} label
 * @return {baseFields:Field[]}
 */
const AddBaseFields=(options:string[]|PointType[], label:string):Field[] => {
    // Map options to keys for label concatenation.
    const keys:Record<string, string>={
        air_flow_rate: "Air Flow Rate (CFM)",
        speed: "Fan Speed (%)",
        status: "Fan Status",
    };
    const opt:PointType[]=[];
    options.forEach((v:string|PointType) => (opt.push({key: typeof v==="string"?v:v.key, label: `${label} ${typeof v==="string"?keys[v]:v.label}`})));
    const baseFields:Field[] = [
        ...BaseFields,
        {
            key: "points",
            label: "Points",
            yup: yup.array().of(yup.object().shape(PointFields.reduce((a:any, v:any) => ({...a, [v.key]: v.yup}), {}))),
            type: "points",
            pointsOptions: {
                disabled: true,
                options: opt,
            },
        },
    ];
    return baseFields;
};

/**
 * AddBaseComponents
 * @param {key: string, variant: string, label: string} options
 * @return {Component[]}
 */

const AHU_COMPONENTS:Component[]=[
    {
        form: Base,
        resolveValues: resolveBaseValues,
        key: "ahu_fan",
        variant: {key: "discharge", label: "Discharge Fan"},
        fields: AddBaseFields(["air_flow_rate", "speed", "status"], "Discharge"),
    },
    {
        form: Base,
        resolveValues: resolveBaseValues,
        key: "ahu_fan",
        variant: {key: "return", label: "Return Fan"},
        fields: AddBaseFields(["air_flow_rate", "speed", "status"], "Return"),
    },
    {
        form: Base,
        resolveValues: resolveBaseValues,
        key: "ahu_fan",
        variant: {key: "exhaust", label: "Exhaust Fan"},
        fields: AddBaseFields(["air_flow_rate", "speed", "status"], "Exhaust"),
    },
];

// Only discharge varient for now.
const HVU_COMPONENTS:Component[]=[
    {
        form: Base,
        resolveValues: resolveBaseValues,
        key: "hvu_fan",
        variant: {key: "discharge", label: "Discharge Fan"},
        fields: AddBaseFields(["air_flow_rate", "speed", "status"], "Discharge"),
    },
];

// Only discharge varient for now.
const PU_COMPONENTS:Component[]=[
    {
        form: Base,
        resolveValues: resolveBaseValues,
        key: "pu_fan",
        variant: {key: "discharge", label: "Discharge Fan"},
        fields: AddBaseFields(["air_flow_rate", "speed", "status"], "Discharge"),
    },
];

const AVB_COMPONENT:Component={
    form: Base,
    resolveValues: resolveBaseValues,
    fields: AddBaseFields(["air_flow_rate", "speed", "status"], "Discharge"),
    key: "avb_discharge_fan",
    label: "Discharge Fan",
};

export const EQUIPMENTS:EquipmentType[]=[
    {
        key: "ahu",
        label: "Air Handler Unit",
        variant: "generic",
        category: "airhandling",
        system: Ahu,
        resolveValues: {toApi: ahuToApi, fromApi: ahuFromApi},
        systemFields: AhuFields,
        component: AHU_COMPONENTS,
    },
    {
        key: "ahu",
        label: "Rooftop Unit",
        variant: "rooftop",
        category: "airhandling",
        system: Ahu,
        resolveValues: {toApi: ahuToApi, fromApi: ahuFromApi},
        systemFields: AhuFields,
        component: AHU_COMPONENTS,
    },
    {
        key: "ahu",
        label: "Dedicated Outdoor Air System",
        variant: "dedicated_outdoor_air",
        category: "airhandling",
        system: Ahu,
        resolveValues: {toApi: ahuToApi, fromApi: ahuFromApi},
        systemFields: AhuFields,
        component: AHU_COMPONENTS,
    },
    {
        key: "hvu",
        label: "Heating Ventilation Unit",
        category: "airhandling",
        system: Hvu,
        resolveValues: {toApi: hvuToApi, fromApi: hvuFromApi},
        systemFields: HvuFields,
        component: HVU_COMPONENTS,
    },
    {
        key: "pu",
        label: "Packaged Unit",
        category: "airhandling",
        system: Pu,
        resolveValues: {toApi: puToApi, fromApi: puFromApi},
        systemFields: PuFields,
        component: PU_COMPONENTS,
    },
    {
        key: "eru",
        label: "Energy Recovery Unit",
        category: "airhandling",
        system: Eru,
        resolveValues: {toApi: eruToApi, fromApi: eruFromApi},
        systemFields: EruFields,
    },
    {
        key: "exhaust_fan", // CHECK
        label: "Exhaust Fan",
        category: "airhandling",
        system: ExhaustFan,
        resolveValues: {toApi: exhaustFanToApi, fromApi: exhaustFanFromApi},
        systemFields: ExhaustFanFields,
    },
    {
        key: "avb",
        label: "Variable Air Volume Boxes",
        variant: "variable",
        category: "terminal",
        system: Avb,
        resolveValues: {toApi: avbToApi, fromApi: avbFromApi},
        systemFields: VavbFields,
        component: AVB_COMPONENT,
    },
    {
        key: "avb",
        label: "Constant Air Volume Boxes",
        variant: "constant",
        category: "terminal",
        system: Avb,
        resolveValues: {toApi: avbToApi, fromApi: avbFromApi},
        systemFields: CavbFields,
        component: AVB_COMPONENT,
    },
    {
        key: "chilledbeam",
        label: "Chilled Beam",
        category: "terminal",
        system: ChilledBeam,
        resolveValues: {toApi: chilledBeamToApi, fromApi: chilledBeamFromApi},
        systemFields: ChilledBeamFields,
    },
    {
        key: "cuh",
        label: "Cabinet Unit Heaters",
        category: "terminal",
        system: Cuh,
        resolveValues: {toApi: cuhToApi, fromApi: cuhFromApi},
        systemFields: CuhFields,
        component: {
            form: Base,
            resolveValues: resolveBaseValues,
            fields: AddBaseFields(["air_flow_rate", "speed", "status"], "Discharge"),
            key: "cuh_discharge_fan",
            label: "Discharge Fan",
        },
    },
    {
        key: "fcu",
        label: "Fan Coil Unit",
        category: "terminal",
        system: Fcu,
        resolveValues: {toApi: fcuToApi, fromApi: fcuFromApi},
        systemFields: FcuFields,
        component: {
            form: Base,
            resolveValues: resolveBaseValues,
            fields: AddBaseFields(["air_flow_rate", "speed", "status"], "Discharge"),
            key: "fcu_discharge_fan",
            label: "Discharge Fan",
        },
    },
    {
        key: "heatpump",
        label: "Heat Pump",
        category: "terminal",
        system: HeatPump,
        resolveValues: {toApi: heatPumpToApi, fromApi: heatPumpFromApi},
        systemFields: HeatPumpFields,
        component: {
            form: Base,
            resolveValues: resolveBaseValues,
            fields: AddBaseFields(["air_flow_rate", "speed", "status"], "Discharge"),
            key: "heatpump_discharge_fan",
            label: "Discharge Fan",
        },
    },
    {
        key: "ptac",
        label: "Packaged Terminal Air Conditioner",
        category: "terminal",
        system: Ptac,
        resolveValues: {toApi: ptacToApi, fromApi: ptacFromApi},
        systemFields: PtacFields,
        component: {
            form: Base,
            resolveValues: resolveBaseValues,
            fields: AddBaseFields(["air_flow_rate", "speed", "status"], "Discharge"),
            key: "ptac_discharge_fan",
            label: "Discharge Fan",
        },
    },
    {
        key: "radiantunit",
        label: "Radiator",
        variant: "radiator",
        category: "terminal",
        system: Radiantunit,
        resolveValues: {toApi: radiantunitToApi, fromApi: radiantunitFromApi},
        systemFields: RadiantunitFields,
    },
    {
        key: "radiantunit",
        label: "Radiant Panel",
        variant: "radiant_panel",
        category: "terminal",
        system: Radiantunit,
        resolveValues: {toApi: radiantunitToApi, fromApi: radiantunitFromApi},
        systemFields: RadiantunitFields,
    },
    {
        key: "vrfu",
        label: "Variable Refrigerant Flow Unit",
        category: "terminal",
        system: Vrfu,
        resolveValues: {toApi: vrfuToApi, fromApi: vrfuFromApi},
        systemFields: VrfuFields,
        component: {
            form: Base,
            resolveValues: resolveBaseValues,
            fields: AddBaseFields(["air_flow_rate", "speed", "status"], "Discharge"),
            key: "vrfu_discharge_fan",
            label: "Discharge Fan",
        },
    },
    {
        key: "acchiller",
        label: "Air-Cooled Chiller",
        category: "cooling",
        system: ACChiller,
        resolveValues: {toApi: aCChillerToApi, fromApi: aCChillerFromApi},
        systemFields: ACChillerFields,
    },
    {
        key: "chwloop",
        label: "Chilled Water Loop",
        category: "cooling",
        system: Chwloop,
        resolveValues: {toApi: chwloopToApi, fromApi: chwloopFromApi},
        systemFields: ChwloopFields,
        component: {
            form: Base,
            resolveValues: resolveBaseValues,
            fields: AddBaseFields([{key: "speed", label: "Speed (%)"}, {key: "status", label: "Status"}], "Chilled Water Pump"),
            key: "chwloop_pump", // chilledwaterloop_pump
            label: "Chilled Water Pump",
        },
    },
    {
        key: "conwloop",
        label: "Condenser Water Loop",
        category: "cooling",
        system: Conwloop,
        resolveValues: {toApi: conwloopToApi, fromApi: conwloopFromApi},
        systemFields: ConwloopFields,
        component: {
            form: Base,
            resolveValues: resolveBaseValues,
            fields: AddBaseFields([{key: "status", label: "Status"}], "Condenser Water Pump"),
            key: "conwloop_pump", // condenserwaterloop_pump
            label: "Condenser Water Pump",
        },
    },
    {
        key: "wcchiller",
        label: "Water-Cooled Chiller",
        category: "cooling",
        system: WCChiller,
        resolveValues: {toApi: wCChillerToApi, fromApi: wCChillerFromApi},
        systemFields: WCChillerFields,
    },
    {
        key: "wccondenser",
        label: "Water-Cooled Condenser",
        category: "cooling",
        system: WCCondenser,
        resolveValues: {toApi: wCCondenserToApi, fromApi: wCCondenserFromApi},
        systemFields: WCCondenserFields,
    },
    {
        key: "dhw",
        label: "Domestic Hot Water Heater",
        category: "heating",
        system: Dhw,
        resolveValues: {toApi: dhwToApi, fromApi: dhwFromApi},
        systemFields: DhwFields,
    },
    {
        key: "districtsteam",
        label: "District Steam",
        category: "heating",
        system: DistrictSteam,
        resolveValues: {toApi: districtSteamToApi, fromApi: districtSteamFromApi},
        systemFields: DistrictSteamFields,
    },
    {
        key: "hwboiler",
        label: "Hot Water Boiler",
        category: "heating",
        system: HWBoiler,
        resolveValues: {toApi: hWBoilerToApi, fromApi: hWBoilerFromApi},
        systemFields: HWBoilerFields,
    },
    {
        key: "hwd",
        label: "Hot Water Distribution",
        category: "heating",
        system: Hwd,
        resolveValues: {toApi: hwdToApi, fromApi: hwdFromApi},
        systemFields: HwdFields,
        component: {
            form: Base,
            resolveValues: resolveBaseValues,
            fields: AddBaseFields([{key: "speed", label: "Speed (%)"}, {key: "status", label: "Status"}], "Hot Water Pump"),
            key: "hwpump",
            label: "Hot Water Pump",
        },
    },
    {
        key: "swboiler",
        label: "Steam Boiler",
        category: "heating",
        system: SWBoiler,
        resolveValues: {toApi: sWBoilerToApi, fromApi: sWBoilerFromApi},
        systemFields: SWBoilerFields,
    },
];

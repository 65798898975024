import {AxiosRequestConfig, AxiosResponse} from "axios";
import {FieldValues} from "react-hook-form";
import moment from "moment";
import _ from "lodash";
import {
    PROPERTY_ID_ROUTE,
    PROPERTY_DOCUMENT_ROUTE,
    PROPERTY_METERINFO_ROUTE,
    METERINFO_TYPE_LABEL_MAP,
    METERINFO_SOURCE_LABEL_MAP,
    DOCUMENT_LABEL_MAP,
    DOCUMENT_TYPE_LABEL_MAP,
    SCHEDULE_TIME_FORMAT,
    FAMILIARITY_LABEL_MAP,
} from "../config";
import {Field} from "../components/generics/inputs";
import {cleanValue} from "./index";
import {
    PropertyDetailsFields,
    OperatorDetailsFields,
    SCHEDULE_KEYS,
    EnertracDetailsFields,
} from "../components/forms";

/**
 * initPropertyForm
 * @return {any}
 */
const initPropertyForm=():any => ({
    ...PropertyDetailsFields.reduce((a:any, v:any) => ({...a, [v.key]: undefined}), {}),
    ...OperatorDetailsFields.reduce((a:any, v:any) => ({...a, [v.key]: undefined}), {}),
    ...EnertracDetailsFields.reduce((a:any, v:any) => ({...a, [v.key]: undefined}), {}),
    documents: [],
    meterinfo: [],
});

/**
 * resolvePropertyRequest
 * @param {any} values
 */
const resolvePropertyRequest=(values:any):any => ({
    ...PropertyDetailsFields
        .slice(8, PropertyDetailsFields.length)
        .filter((field:Field) => !(["override_start", "override_end", "isSameDailySchedule", "is24HourFacility", "parent_property_name"].includes(field.key)))
        .reduce((a:any, v:any) => {
            let cv:any=cleanValue(values[v.key]);
            if (SCHEDULE_KEYS.includes(v.key)) {
                if (cv===undefined) cv=null;
                else cv=moment(cv).format(SCHEDULE_TIME_FORMAT).toString();
            } else if (cv===undefined) cv="";
            return ({...a, [v.key]: cv});
        }, {}),
    ...OperatorDetailsFields.reduce((a:any, v:any) => {
        let cv=cleanValue(values[v.key]);
        if (cv===undefined) cv="";
        return ({...a, [v.key]: cv});
    }, {}),
    ...EnertracDetailsFields.reduce(
        (a:any, v:any) => {
            let cv:any=cleanValue(values[v.key]);
            if (v.key==="familiarity") {
                cv=cleanValue(FAMILIARITY_LABEL_MAP.find((i:any) => i.label===values[v.key])?.key);
                if (cv===undefined) cv=null;
            }
            if (cv===undefined) cv="";
            return ({...a, [v.key]: cv});
        },
        {},
    ),
});

/**
 * resolveDocumentsRequests
 * @param {FieldValues} values
 * @param {AxiosResponse|null} documents
 * @return {any}
 */
const resolveDocumentsRequests=(values:FieldValues, documents:AxiosResponse|null):any => {
    /**
     * cleanDoc
     * @param {any} doc
     * @param {string} tag
     * @return {any}
     */
    const cleanDoc=(doc:any, tag?:string):any => ({
        ...doc,
        label: DOCUMENT_LABEL_MAP.find((i:any) => i.label===doc.label)?.key,
        type: DOCUMENT_TYPE_LABEL_MAP.find((i:any) => i.label===doc.type)?.key,
        ...(tag && {etag_version: tag}),
    });
    // escape if no api docs
    if (!documents || !Array.isArray(values.documents)) return {post: [], delete: [], patch: []};

    return {
        // POST requests - docs to be posted. Any FORM doc NOT found in API docs
        post: (
            values.documents
                .filter((formDoc:any) => documents.data.results.find((i:any) => i.id===formDoc.id)===undefined)
                .map((formDoc:any) => cleanDoc(formDoc))
        ),
        // DELETE requests - docs to be deleted. Any API doc NOT found in FORM docs
        delete: (
            documents.data.results
                .filter((apiDoc:any) => values.documents.find((i:any) => i.id===apiDoc.id)===undefined)
        ),
        // PATCH requests - docs to be patched. Any 2 docs (form and api) of SAME id however different content
        patch: (
            values.documents
                .filter((formDoc:any) => {
                    const apiDoc=documents.data.results.find((i:any) => i.id===formDoc.id);
                    // trimmed api doc
                    let apiDocTrimmed;
                    if (apiDoc!==undefined) apiDocTrimmed=["id", "label", "link", "type", "etag_version"].reduce((a:any, v:any) => ({...a, [v]: apiDoc[v]}), {});
                    if (apiDocTrimmed!==undefined && !_.isEqual(cleanDoc(formDoc), apiDocTrimmed)) return true;
                    return false;
                })
                .map((formDoc:any) => cleanDoc(formDoc, documents.data.results.find((i:any) => i.id===formDoc.id).etag_version))
        ),
    };
};

/**
 * resolveMeterinfoRequests
 * @param {FieldValues} values
 * @param {AxiosResponse|null} meterinfo
 * @return {any}
 */
const resolveMeterinfoRequests=(values:FieldValues, meterinfo:AxiosResponse|null):any => {
    /**
     * cleanMtr
     * @param {any} mtr
     * @param {string} tag
     * @return {any}
     */
    const cleanMtr=(mtr:any, tag?:string):any => ({
        ...mtr,
        type: METERINFO_TYPE_LABEL_MAP.find((i:any) => i.label===mtr.type)?.key,
        source: METERINFO_SOURCE_LABEL_MAP.find((i:any) => i.label===mtr.source)?.key,
        meter_id: mtr.meter_id.toString(),
        ...(tag && {etag_version: tag}),
    });
    // escape of no api meterinfo
    if (!meterinfo || !Array.isArray(values.meterinfo)) return {post: [], delete: [], patch: []};

    return {
        // POST requests - mtrs to be posted. Any FORM mtr NOT found in API mtrs
        post: (
            values.meterinfo
                .filter((formMtr:any) => meterinfo.data.results.find((i:any) => i.id===formMtr.id)===undefined)
                .map((formMtr:any) => cleanMtr(formMtr))
        ),
        // DELETE requests - mtrs to be deleted. Any API mtr NOT found in FORM mtrs
        delete: (
            meterinfo.data.results
                .filter((apiMtr:any) => values.meterinfo.find((i:any) => i.id===apiMtr.id)===undefined)
        ),
        // PATCH requests - mtrs to be patched. Any 2 mtrs (form and api) of SAME id however different content
        patch: (
            values.meterinfo
                .filter((formMtr:any) => {
                    const apiMtr=meterinfo.data.results.find((i:any) => i.id===formMtr.id);
                    // trimmed api mtr
                    let apiMtrTrimmed;
                    if (apiMtr!==undefined) apiMtrTrimmed=["id", "type", "meter_id", "source", "notes", "etag_version"].reduce((a:any, v:any) => ({...a, [v]: apiMtr[v]}), {});
                    if (apiMtrTrimmed!==undefined && !_.isEqual(cleanMtr(formMtr), apiMtrTrimmed)) return true;
                    return false;
                })
                .map((formMtr:any) => cleanMtr(formMtr, meterinfo.data.results.find((i:any) => i.id===formMtr.id).etag_version))
        ),
    };
};

/**
 * constructPropertyPatchConfigs
 * @param {FieldValues|null} values
 * @param {AxiosResponse} property
 * @param {AxiosResponse|null} meterinfo
 * @param {AxiosResponse|null} documents
 * @return {AxiosRequestConfig[]}
 */
const constructPropertyPatchConfigs=(values:FieldValues|null, property:AxiosResponse, meterinfo:AxiosResponse|null, documents:AxiosResponse|null):AxiosRequestConfig[] => {
    if (values===null) return [];
    const {id, etag_version}=property.data; // eslint-disable-line camelcase
    const docs=resolveDocumentsRequests(values, documents);
    const mtrs=resolveMeterinfoRequests(values, meterinfo);
    const prty=resolvePropertyRequest(values);
    // console.log("docs", docs);
    const configs:AxiosRequestConfig[]=[
        {
            method: "PATCH",
            url: PROPERTY_ID_ROUTE.replace(/{id}/g, id),
            data: prty,
            headers: {"If-Match": etag_version}, // eslint-disable-line camelcase
        },
    ];

    // docs delete requests.
    docs.delete.forEach((doc:any) => {
        configs.push(
            {
                method: "delete",
                url: `${PROPERTY_DOCUMENT_ROUTE.replace(/\/{id}/g, "")}${doc.id}`,
                headers: {"If-Match": doc.etag_version}, // eslint-disable-line camelcase
            },
        );
    });
    // docs post requests.
    docs.post.forEach((doc:any) => {
        configs.push(
            {
                method: "post",
                url: PROPERTY_DOCUMENT_ROUTE.replace(/\/{id}/g, ""),
                data: _.omit({...doc, property_id: id}, ["etag_version", "id"]),
            },
        );
    });
    // docs patch requests.
    docs.patch.forEach((doc:any) => {
        configs.push(
            {
                method: "patch",
                url: `${PROPERTY_DOCUMENT_ROUTE.replace(/\/{id}/g, "")}${doc.id}`,
                data: _.omit(doc, ["etag_version", "id"]),
                headers: {"If-Match": doc.etag_version}, // eslint-disable-line camelcase
            },
        );
    });

    // mtrs delete requests.
    mtrs.delete.forEach((mtr:any) => {
        configs.push(
            {
                method: "delete",
                url: `${PROPERTY_METERINFO_ROUTE.replace(/\/{id}/g, "")}${mtr.id}`,
                headers: {"If-Match": mtr.etag_version}, // eslint-disable-line camelcase
            },
        );
    });
    // mtrs post requests.
    mtrs.post.forEach((mtr:any) => {
        configs.push(
            {
                method: "post",
                url: PROPERTY_METERINFO_ROUTE.replace(/\/{id}/g, ""),
                data: _.omit({...mtr, property_id: id}, ["etag_version", "id"]),
            },
        );
    });
    // mtrs patch requests.
    mtrs.patch.forEach((mtr:any) => {
        configs.push(
            {
                method: "patch",
                url: `${PROPERTY_METERINFO_ROUTE.replace(/\/{id}/g, "")}${mtr.id}`,
                data: _.omit(mtr, ["etag_version", "id"]),
                headers: {"If-Match": mtr.etag_version}, // eslint-disable-line camelcase
            },
        );
    });
    return configs;
};

/**
 * initDoc
 * @param {any} item
 * @return {any}
 */
const initDoc=(item?:any):any => {
    if (item) {
        return ({
            label: DOCUMENT_LABEL_MAP.find((i:any) => i.key===item.label)?.label,
            type: DOCUMENT_TYPE_LABEL_MAP.find((i:any) => i.key===item.type)?.label,
            link: item.link,
            id: item.id,
            etag_version: item.etag_version,
        });
    }
    return {label: undefined, type: undefined, link: undefined, id: undefined, etag_version: undefined};
};

/**
 * initMeter
 * @param {any} item
 * @return {any}
 */
const initMeter=(item?:any):any => {
    if (item) {
        return ({
            type: METERINFO_TYPE_LABEL_MAP.find((i:any) => i.key===item.type)?.label,
            meter_id: item.meter_id,
            source: METERINFO_SOURCE_LABEL_MAP.find((i:any) => i.key===item.source)?.label,
            notes: item.notes,
            id: item.id,
            etag_version: item.etag_version,
        });
    }
    return {type: undefined, meter_id: undefined, source: undefined, notes: undefined, id: undefined, etag_version: undefined};
};

export {
    // resolveDocumentsRequests,
    // resolveMeterinfoRequests,
    constructPropertyPatchConfigs,
    initPropertyForm,
    initDoc,
    initMeter,
};

import React, {useEffect, useState, memo} from "react";
import {Box, LinearProgress} from "@mui/material";
import {AxiosResponse} from "axios";
import _ from "lodash";
import {UseFieldArrayReturn, useFieldArray, UseFormReturn} from "react-hook-form";
import {Field} from "../generics/inputs";
import {Point, PointFields} from "../forms";
import {alterEditableFieldsViewMode, initPoint, resolveFormKey, resolvePoints} from "../../handlers";
import {POINT_BOOLEANS, POINT_MAP} from "../../config";

interface Props{
    field:Field
    form:UseFormReturn
    values?:AxiosResponse|null // eslint-disable-line react/require-default-props
}

/**
 * isSelectedAll
 * @param {any} values
 * @param {number} pointsLength
 * @return {string[]}
 */
const isSelectedAll=(values:any, pointsLength:number):string[] => {
    if (values===undefined||!Array.isArray(values)) return [];
    return (
        POINT_BOOLEANS
            .filter((v:any) => (values.filter((i:any) => i.point_booleans.includes(v.label)).length===pointsLength))
            .map((i:any) => i.label)
    );
};

/**
 * PointsGrid
 * @param {Props} props
 * @return {React.ReactElement}
 */
function PointsGrid(props:Props):React.ReactElement {
    const [selectors, setSelectors]=useState<string[]>([]);
    // points field array
    const {append, fields, remove}:UseFieldArrayReturn=useFieldArray({control: props.form.control, name: "points"});
    // incomming record (api)
    const values=props.values?.status===200 && ("data" in props.values) && props.values.data.points?props.values.data:null;

    // form field array population
    useEffect(() => {
        if (fields.length===0) {
            const points:any=[]; // init points
            remove(); // reset array before appending array
            if (values) Object.keys(values.points).forEach((point:string) => points.push(initPoint(point, values.points[point])));
            else if ("pointsOptions" in props.field) props.field?.pointsOptions?.options.forEach((point) => points.push(initPoint(typeof point==="string"?point:point.key)));
            append(points); // push to form
        }
    }, [props.field, append, values, remove, fields.length]);

    // reseting selectors
    useEffect(() => {
        const pointsLength=values?Object.keys(values.points).length:props.field?.pointsOptions?.options.length as number;
        const subscription = props.form.watch(_.debounce((formValues:any, {name, type}:any) => {
            if (name) {
                const formKey=resolveFormKey(name);
                if (formKey.isNested && formKey.key.sub==="point_booleans") setSelectors(isSelectedAll(formValues.points, pointsLength));
            }
        }, 0));

        const formValues=props.form.getValues();
        if (values || formValues) setSelectors(isSelectedAll(values?resolvePoints(values.points):formValues.points, pointsLength));

        return () => subscription.unsubscribe();
    }, [props.form, values, props.field.pointsOptions]);

    /**
     * onSelectorChange
     * @param {string} value
     * @return {void}
     */
    const onSelectorChange=(value:string) => (args:React.MouseEvent):void => {
        let newValues=selectors;
        // resolve new selector values
        if (newValues.includes(value)) newValues=selectors.filter((i:string) => i!==value);
        else newValues=[...selectors, value];

        // concat & set points (form + current value)
        const formValues=props.form.getValues();
        fields.forEach((field:any, idx:number) => {
            const pointBooleans=[...newValues, ...formValues.points[idx].point_booleans.filter((i:any) => i!==value)];
            props.form.setValue(`points.${idx}.point_booleans`, pointBooleans.filter((p:any, i:number) => pointBooleans.indexOf(p)===i));
        });
        setSelectors(newValues);
    };

    const items:React.ReactElement[]=[];
    // iterate over field array (form array)
    fields.forEach((item:any, index:number) => {
        const clone=_.cloneDeep(PointFields);
        clone.forEach((field:Field) => { field.key=`points.${index}.${field.key}`; }); // eslint-disable-line no-param-reassign

        // Find the point with the matching key
        const key = props.field.pointsOptions?.options.find((option) => (typeof option === "object"?option.key===item.key:option===item.key));

        // If the label is overriden like '{key: <string>, label: <string>}', use the label from the point option object. Otherwise, find the label from the POINT_MAP
        const label = typeof key === "object" ? key?.label : POINT_MAP.find((i:any) => i.key===key)?.label;
        items.push(
            <Point
                key={`${item.id}`}
                form={props.form}
                label={label||""}
                pointKey={item.key}
                selectors={selectors}
                onSelectorChange={onSelectorChange}
                fields={alterEditableFieldsViewMode({form: clone}, props.field.pointsOptions?.disabled===true?"VIEW_MODE":"EDIT_MODE").form}
            />,
        );
    });

    // loading stage
    if (items.length===0) return (<LinearProgress />);

    return (<Box>{items}</Box>);
}

PointsGrid.defaultProps={
    values: undefined,
};

// /**
//  * arePropsEqual
//  * @param {Props} prevProps
//  * @param {Props} nextProps
//  * @return {boolean}
//  */
// const arePropsEqual=(prevProps:Props, nextProps:Props):boolean => true;

const Memoize=memo((props:Props) => <PointsGrid {...props} />);

export default Memoize;

import React, {useEffect, useState} from "react";
import {useAuth0, Auth0ContextInterface, User} from "@auth0/auth0-react";
import {AppBar, Box, Button, CssBaseline, Divider, IconButton, ListItemIcon, Menu, MenuItem, Theme, Toolbar, Typography, useMediaQuery, useTheme, Chip} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {Business, ContentPaste, HelpOutline, Login, Logout, VerifiedUserOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {APP_PATHS, REACT_APP_VERSION, env} from "../../config";

/**
 * MenuBar
 * @return {React.ReactElement}
 */
function MenuBar():React.ReactElement {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const auth:Auth0ContextInterface<User>= useAuth0();
    const navigate=useNavigate();
    const theme:Theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    /**
     * handleClick
     * @param {React.MouseEvent<HTMLElement>} event
     * @return {void}
     */
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => { setAnchorEl(event.currentTarget); };

    /**
     * handleClose
     * @param {Callback} callback
     * @return {Function}
     */
    const handleClose = (callback: any = null) => (args: React.MouseEvent) => {
        if (callback) callback();
        setAnchorEl(null);
    };

    useEffect(() => { if (!isMobile) setAnchorEl(null); }, [isMobile]);

    // auth0 buttons
    const buttons=[
        {
            key: "PROPERTIES",
            label: "Properties",
            isAuthenticated: auth.isAuthenticated,
            icon: <Business />,
            onClick: () => navigate(APP_PATHS.PROPERTIES),
        },
        {
            key: "HELP",
            label: "",
            isAuthenticated: auth.isAuthenticated,
            icon: <HelpOutline />,
            onClick: () => navigate(APP_PATHS.DOCS),
        },
        {
            key: "SIGN_IN",
            label: "Sign in",
            isAuthenticated: !auth.isAuthenticated,
            icon: <Login />,
            onClick: auth.loginWithRedirect,
        },
        {
            key: "SIGN_OUT",
            label: "Sign out",
            isAuthenticated: auth.isAuthenticated,
            icon: <Logout />,
            onClick: () => auth.logout({logoutParams: {returnTo: `${window.location.origin}/?signout=true`}}),
        },
    ];

    /**
     * constructMobileMenu
     * @return {React.ReactElement}
     */
    const constructMobileMenu=():React.ReactElement => (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)&&isMobile}
            onClose={handleClose()}
            onClick={handleClose()}
            slotProps={{
                paper: {elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&::before": {
                            content: "\"\"",
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    }},
            }}
            transformOrigin={{horizontal: "right", vertical: "top"}}
            anchorOrigin={{horizontal: "right", vertical: "bottom"}}
        >
            {/* Main Routes */}
            {buttons.slice(0, buttons.length-3).map((item:any) => {
                if (!item.isAuthenticated) return null;
                return (
                    <MenuItem key={`${item.key}-key`} onClick={handleClose(item.onClick)} sx={{":hover": {backgroundColor: "#dddddd"}}}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        {item.label.length<1?"Help":item.label}
                    </MenuItem>
                );
            })}
            <Divider variant="middle" />
            {/* Help/Signin/Signout Buttons */}
            {buttons.slice(buttons.length-3).map((item:any) => {
                if (!item.isAuthenticated) return null;
                return (
                    <MenuItem key={`${item.key}-key`} onClick={handleClose(item.onClick)} sx={{":hover": {backgroundColor: "#dddddd"}}}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        {item.label.length<1?"Help":item.label}
                    </MenuItem>
                );
            })}
        </Menu>
    );

    /**
     * constructMenu
     * @return {React.ReactElement}
     */
    const constructMenu=():React.ReactElement => {
        const environment=(env.REACT_APP_ENVIRONMENT!==undefined && env.REACT_APP_ENVIRONMENT!=="production" && (
            <Box><Chip sx={{marginBottom: "-20px", textTransform: "capitalize"}} icon={<VerifiedUserOutlined />} label={`${env.REACT_APP_ENVIRONMENT} ${REACT_APP_VERSION||""}`} color="warning" /></Box>
        ));
        return (
            <>
                {/* Left Side Nav */}
                <Box sx={{display: {xs: "none", md: "inherit"}}}>
                    {buttons.slice(0, 1).map((item:any) => {
                        if (!item.isAuthenticated) return null;
                        return (
                            <Button
                                sx={{textTransform: "none", fontSize: "12pt"}}
                                key={`${item.key}-key`}
                                aria-label={`${item.label}`}
                                color="inherit"
                                onClick={item.onClick}
                            >
                                <Box sx={{marginRight: "2px", marginTop: "4px"}}>{item.icon}</Box>
                                {item.label}
                            </Button>
                        );
                    })}
                </Box>
                <Box sx={{flexGrow: 1}} />
                {/* Right Side Nav */}
                <Box sx={{display: {xs: "none", md: "flex"}}}>
                    {/* environment */}
                    {environment}
                    {buttons.slice(1, buttons.length).map((item:any) => {
                        if (!item.isAuthenticated) return null;
                        return (
                            <Button
                                sx={{textTransform: "none", fontSize: "12pt"}}
                                key={`${item.key}-key`}
                                aria-label={`${item.label}`}
                                color="inherit"
                                onClick={item.onClick}
                            >
                                <Box sx={{marginRight: "2px", marginTop: "4px"}}>{item.icon}</Box>
                                {item.label}
                            </Button>
                        );
                    })}
                </Box>
                {/* Mobile Nav */}
                {constructMobileMenu()}
                {/* Button to toggle nav on mobile */}
                <Box sx={{display: {xs: "flex", md: "none"}}}>
                    {/* environment */}
                    {environment}
                    <IconButton color="inherit" onClick={handleClick}>
                        <MenuIcon />
                    </IconButton>
                </Box>
            </>
        );
    };

    /**
     * constructAppBar
     * @return {React.ReactElement}
     */
    const constructAppBar=():React.ReactElement => {
        const isHome=window.location.pathname!==APP_PATHS.HOME;
        return (
            <AppBar position={isHome?"fixed":"absolute"} color="secondary" sx={{paddingX: {xs: 2, sm: 5, md: 10}, ...(isHome?{}:{boxShadow: "none"})}}>
                <Toolbar sx={{margin: "0px !important", padding: "0px !important"}}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href={APP_PATHS.HOME}
                        sx={{
                            display: {xs: "flex", md: "flex"},
                            fontWeight: 600,
                            letterSpacing: ".1rem",
                            color: "inherit",
                            textDecoration: "none",
                            margin: 1,
                        }}
                    >
                        <ContentPaste sx={{marginTop: "4px", marginRight: "2px"}} />
                        BASAT
                    </Typography>
                    {!auth.isLoading && constructMenu()}
                </Toolbar>
            </AppBar>
        );
    };

    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline />
            {constructAppBar()}
        </Box>
    );
}

export default MenuBar;

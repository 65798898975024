import React from "react";
import {Grid} from "@mui/material";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {populateValues} from "../../../handlers";
import {Field, ControlledAutocomplete} from "../../generics/inputs";
import Dialog from "../../generics/Dialog";
import {DialogType} from "../../../types";

interface Props{
    status:DialogType
    dialogType:DialogType
    onClose:(args:React.MouseEvent) => void
    onSubmit:(values:any) => void
    title:string
    message:React.ReactElement
    reasons:string[]
}

export const Fields: Field[] = [
    {
        key: "archived_reason",
        label: "Archive Reason",
        yup: yup.string().required("Please indicate why you are archiving this equipment"),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            helperText: "Please indicate why you are archiving this equipment",
        },
    },
];

/**
 * Archive
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Archive(props:Props):React.ReactElement|null {
    // set reasons options
    populateValues(props.reasons, Fields, "archived_reason");

    // construct yup object
    const yupObject: any = {};
    Fields.forEach((field:Field) => { yupObject[field.key] = field.yup; });

    const {formState: {errors}, control, handleSubmit} = useForm({
        mode: "onChange",
        resolver: yupResolver(yup.object(yupObject)),
    });

    return (
        <Dialog
            open={props.status===props.dialogType}
            title={`Archive ${props.title}`}
            submitHandler={handleSubmit}
            onPrimary={{onClick: props.onSubmit, label: "Archive"}}
            onClose={{onClick: props.onClose}}
            content={(
                <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {/* message */}
                    <Grid item xs={12}>{props.message}</Grid>
                    {/* archived_reason */}
                    <Grid item xs={12}><ControlledAutocomplete field={Fields[0]} control={control} errors={errors} /></Grid>
                </Grid>
            )}
        />
    );
}

export default Archive;

import React from "react";
import {Grid, Collapse, Box} from "@mui/material";
import {UseFormReturn, FieldValues} from "react-hook-form";
import {ControlledSwitch, ControlledTextField, Field, ControlledAutocomplete} from "../../generics/inputs";

interface LayoutConfig{
    fields:Field[]
    methods:UseFormReturn<FieldValues>
    controllingFields:any
    controllingKey:string
    textAreaRows:{min: number, max: number}
    spacing:{min: number, max: number}
    collapsedSize?:number
}

/**
 * constrcutLayout
 * @param {LayoutConfig} config
 * @return {React.ReactElement}
 */
const constructLayout=(config:LayoutConfig):React.ReactElement => {
    // de-structute config
    const {controllingFields, controllingKey, fields, textAreaRows, spacing, methods, collapsedSize}=config;
    // controlling field
    const {status, keys, skipKeys}=controllingFields[controllingKey];
    // resolve notes rows
    const notes=fields.find((f:Field) => f.key===keys[keys.length-1]) as Field;
    if (status && notes.textfieldOptions?.textArea?.rows) notes.textfieldOptions.textArea.rows=textAreaRows.max;
    else if (!status && notes.textfieldOptions?.textArea?.rows) notes.textfieldOptions.textArea.rows=textAreaRows.min;

    return (
        <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
            {/* column 1 */}
            <Grid container spacing={status?spacing.max:spacing.min} item xs={12} sm={12} md={12} lg={6} xl={6}>
                {keys.slice(0, keys.length-1).map((key:string) => {
                    const field:Field=fields.find((f:Field) => f.key===key) as Field;
                    const item=(
                        <Box>
                            {field.type==="textfield" && <ControlledTextField field={field} control={methods.control} errors={methods.formState.errors} />}
                            {field.type==="switch" && <ControlledSwitch field={field} control={methods.control} errors={methods.formState.errors} />}
                            {field.type==="autocomplete" && <ControlledAutocomplete field={field} control={methods.control} errors={methods.formState.errors} />}
                        </Box>
                    );
                    if (field.key===controllingKey || skipKeys?.includes(field.key)===true) return <Grid key={field.key} item xs={12} sm={12} md={12} lg={12} xl={12}>{item}</Grid>;
                    return <Grid key={field.key} item xs={12} sm={12} md={12} lg={12} xl={12}><Collapse timeout={50} in={status}>{item}</Collapse></Grid>;
                })}
            </Grid>
            {/* column 2 */}
            <Grid container spacing={3} item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse timeout={50} in={status} collapsedSize={collapsedSize===undefined?100:collapsedSize}><ControlledTextField field={notes} control={methods.control} errors={methods.formState.errors} /></Collapse>
                </Grid>
            </Grid>
        </Grid>
    );
};

export {
    constructLayout,
};

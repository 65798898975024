import React, {useMemo, useCallback, useEffect} from "react";
import {useAuth0, Auth0ContextInterface, User, RedirectLoginOptions, AppState} from "@auth0/auth0-react";
import {Box, Typography, Button, Chip, styled, Grid, useTheme, Theme, TypographyProps} from "@mui/material";
import {StyledComponent} from "@emotion/styled";
import {AxiosResponse} from "axios";
import {HelpOutline, ExitToApp, BusinessTwoTone} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import BPL_LOGO from "../static/logo.svg";
import {APP_PATHS} from "../config";
import Link from "../components/generics/Link";
import {slackCall} from "../components/generics/Call";

interface WithContainerProps {
    children:React.ReactElement
}

interface PreProps {
    loginWithRedirect: (options?:RedirectLoginOptions<AppState>) => Promise<void>;
}

interface OrderedListItem{
    label:number
    children:React.ReactElement
}

const Header=styled(Typography)(({theme}) => ({
    alignItems: "center",
    fontSize: "3em",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
        fontSize: "2.5em",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.7em",
    },
}));

const ResponsiveText:StyledComponent<TypographyProps>=styled(Typography)(({theme}) => ({
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
    },
}));

/**
 * WithContainer
 * @return {React.ReactElement}
 */
function WithContainer({children}:WithContainerProps):React.ReactElement {
    const theme:Theme=useTheme();
    const backgroundColor = theme.palette.secondary.main;

    return (
        <Grid container height={{xs: `calc(100vh - ${56}px)`, sm: `calc(100vh - ${64}px)`}}>
            <Grid
                item
                xs={12}
                sx={{
                    backgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {/* content */}
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", color: "#FFFFFF", maxWidth: "720px"}}>
                    {children}
                </Box>
            </Grid>
            {/* footer */}
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                    backgroundColor,
                }}
            >
                <Typography sx={{display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                >
                    <Link to="https://cunybpl.org/" underline="none" sx={{color: "#5B6260", ":hover": {color: "white"}}}>
                        Powered by CUNY Building Performance Lab
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
}

/**
 * OrderedListItem
 * @param {OrderedListItem} props
 * @return {React.ReactElement}
 */
function OrderedListItem(props:OrderedListItem):React.ReactElement {
    return (
        <Box display="flex">
            <Chip
                label={`${props.label}`}
                component={Typography}
                color="default"
                size="medium"
                variant="filled"
                padding="4px"
                sx={{
                    marginRight: 2,
                    color: "white",
                    height: "42px",
                    borderRadius: "100px",
                    "& .MuiChip-label": {
                        fontSize: "34px",
                        padding: "3px 6px;",
                    },
                }}
            />
            {props.children}
        </Box>
    );
}

/**
 * Pre signin view
 * @return {React.ReactElement}
 */
function Pre({loginWithRedirect}: PreProps):React.ReactElement {
    return (
        <WithContainer>
            <Box sx={{display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "stretch",
                flex: "1 0 0"}}
            >
                {/* hero */}
                <Box
                    gap={4}
                    sx={{display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                    }}
                >
                    <img src={BPL_LOGO} alt="logo" style={{width: "128px", height: "150px"}} />
                    {/* header */}
                    <Box>
                        <Header variant="h3" sx={{textWrap: "nowrap"}}>Building Automation System</Header>
                        <Header variant="h3" sx={{}}>Assessment Tool</Header>
                    </Box>
                    {/* body */}
                    <Box
                        sx={{
                            marginLeft: {xs: 5, md: 0},
                            marginRight: {xs: 5, md: 0},
                        }}
                    >
                        <Typography variant="body1" sx={{color: "#A6B1AE", textAlign: "center", justifyContent: "center", maxWidth: "872px", fontSize: "18px"}}>
                            The Building Automation System Assessment Tool (BASAT), created by the&nbsp;
                            <Link to="https://nycenergytools.com/resources/tools/building-automation-system-assessment-tool-basat/" underline="always">
                                CUNY Building Performance Lab
                            </Link>
                            &nbsp;seeks to provide a basis for a standardized approach to evaluating existing building systems
                            relative to desired BAS functionality and performance goals.
                        </Typography>
                    </Box>
                    {/* login action */}
                    <Button variant="contained" size="large" color="primary" startIcon={<ExitToApp />} onClick={(args:React.MouseEvent):void => { loginWithRedirect(); }}>Sign In</Button>
                </Box>
            </Box>
        </WithContainer>
    );
}

/**
 * Post signin view
 * @return {React.ReactElement}
 */
function Post():React.ReactElement {
    const navigate=useNavigate();
    // ordered items
    const items:OrderedListItem[]=[
        {
            label: 1,
            children: (
                <ResponsiveText component="div" sx={{textWrap: "nowrap"}}>
                    {/* Grid used to display inline */}
                    <Grid container>
                        <Grid item>Search for a property in the</Grid>
                        <Grid item>
                            <Button
                                sx={{color: "#FFFFFF", textTransform: "capitalize", minWidth: "100px", padding: "0px"}}
                                onClick={() => { navigate("/properties"); }}
                            >
                                <BusinessTwoTone fontSize="inherit" sx={{marginRight: "5px"}} />
                                Properties
                            </Button>
                        </Grid>
                        <Grid item>database.</Grid>
                    </Grid>
                </ResponsiveText>
            ),
        },
        {
            label: 2,
            children: (<ResponsiveText>View or edit information about the property.</ResponsiveText>),
        },
        {
            label: 3,
            children: (<ResponsiveText>View, edit, or add a building automation system and related information.</ResponsiveText>),
        },
        {
            label: 4,
            children: (<ResponsiveText>View, edit or add equipment and related information including point availability and status.</ResponsiveText>),
        },
    ];

    return (
        <WithContainer>
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: {xs: 5, sm: 6, md: 7, lg: 8}}}>
                {/* header */}
                <Header variant="h1" fontSize="3rem">Getting Started</Header>
                {/* ordered list */}
                <Box
                    sx={{
                        border: "solid #E0E0E0 1px",
                        marginLeft: {xs: 2, sm: 2, md: 2, lg: 0, xl: 0},
                        marginRight: {xs: 2, sm: 2, md: 2, lg: 0, xl: 0},
                        padding: {xs: 2, sm: 4, md: 6, lg: 8, xl: 8},
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: {xs: 1, sm: 2, md: 3, lg: 4, xl: 4},
                        alignSelf: "center",
                    }}
                >
                    {items.map((item:OrderedListItem) => (
                        <OrderedListItem key={item.label} label={item.label}>{item.children}</OrderedListItem>
                    ))}
                </Box>
                {/* help section */}
                <Box
                    sx={{
                        marginLeft: {xs: 4, sm: 3, md: 0, lg: 0, xl: 0},
                        marginRight: {xs: 4, sm: 3, md: 0, lg: 0, xl: 0},
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                    <Typography variant="body1" sx={{textAlign: "center"}}>
                        For more details on how to use BASAT, see the&nbsp;
                        <Link to={APP_PATHS.DOCS} underline="none">
                            <>
                                <HelpOutline fontSize="inherit" />
                                User Guide
                            </>
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </WithContainer>
    );
}

/**
 * SplashView
 * @return {React.ReactElement}
 */
function SplashView():React.ReactElement {
    const {isAuthenticated, loginWithRedirect, user}:Auth0ContextInterface<User>= useAuth0();
    const keys:any=useMemo(() => ({}), []);

    /**
     * hash
     * @param {string} v
     * @return {number}
     */
    const hash=(v:string):number => {
        let h=0;
        if (v.length===0) return h;
        for (let i=0; i<v.length; i+=1) {
            const char=v.charCodeAt(i);
            h=((h<<5)-h)+char; // eslint-disable-line no-bitwise
            h&=h; // eslint-disable-line no-bitwise
        }
        return h;
    };

    /**
     * onKeyDown
     * @param {KeyboardEvent} args
     * @return {Promise<void>}
     */
    const onKeyDown=useCallback(async (args:KeyboardEvent):Promise<void> => {
        const shortcut=Object.keys(keys)
            .reduce((a:any, v:any) => ([...a, (keys[v] && v)]), [])
            .filter((i:any) => i!==false)
            .join("")
            .split("")
            .sort((a:any, b:any) => (a<b?-1:1))
            .join("");
        const isShortcut=hash(shortcut)===669266970;

        if (args.type==="keydown") keys[args.code]=true;
        else if (args.type==="keyup") keys[args.code]=false;

        if (isShortcut) await slackCall({data: "This is a test!"} as AxiosResponse, user as User);
    }, [keys, user]);

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown, false);
        document.addEventListener("keyup", onKeyDown, false);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
            document.removeEventListener("keyup", onKeyDown, false);
        };
    }, [onKeyDown]);

    if (isAuthenticated) return <Post />;
    return <Pre loginWithRedirect={loginWithRedirect} />;
}

export default SplashView;

import {useState, Dispatch, SetStateAction} from "react";
import {AxiosError, AxiosResponse} from "axios";
import api from "../api";
import {STATUS_500_RANGE} from "../config";

interface ServerErrorHandlerType{
    message:string
    isError:boolean
    setIsError:Dispatch<SetStateAction<boolean>>
}

/**
 * useServerErrorHandler
 * @return {ServerErrorHandlerType}
 */
const useServerErrorHandler = ():ServerErrorHandlerType => {
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);

    api.interceptors.response.use(
        (response:AxiosResponse) => response,
        (error:AxiosError) => {
            if (error.response && error.response.status >= 500) {
                setMessage(STATUS_500_RANGE[error.response.status]);
                setIsError(true);
            }
            return Promise.reject(error);
        },
    );

    return {message, isError, setIsError};
};

export default useServerErrorHandler;

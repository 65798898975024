import React, {useEffect, useState, useCallback} from "react";
import {Box, Skeleton, Grid, Typography, Chip} from "@mui/material";
import {useParams, Navigate} from "react-router-dom";
import {LanOutlined, BusinessOutlined, HeatPumpOutlined, VillaOutlined} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";
import {AxiosError, AxiosResponse} from "axios";
import {APP_PATHS, PROPERTY_ID_ROUTE} from "../../config";
import {TabType} from "../../types";
import api from "../../api";
// component
import Tabs from "../generics/Tabs";
import Alert from "../generics/Alert";
import {useContext} from "../generics/Context";

// NOTE:
// This is needed to resolve path vs. recordtype;
// Otherwise it can lead to path logical error
// Example property of recordtype "Building" while actual path is "Campus"
const MAPPER:any={
    PROPERTY: "BUILDING",
    CAMPUS: "CAMPUS",
    CAMPUS_PROPERTIES: "CAMPUS",
};

interface Props{
    tab:TabType
    component:(props:any) => React.ReactElement<any, string | React.JSXElementConstructor<any>> // eslint-disable-line react/no-unused-prop-types
}

/**
 * WithTabs
 * @return {React.ReactElement}
 */
function WithTabs(props:Props):React.ReactElement|null {
    const context:any=useContext();
    const params:any=useParams();
    const [property, setProperty]:any=useState(undefined);
    const isPathConflict=property?.data?.recordtype && (MAPPER[props.tab] && MAPPER[props.tab]!==property?.data.recordtype.toUpperCase());
    const isBuilding:boolean=property?.data?.recordtype?.toUpperCase()==="BUILDING";

    /**
     * getProperty
     * @param {number} id
     */
    const getProperty=useCallback(async (id:number):Promise<void> => {
        if (!id || !context.token) return;
        await api.get(PROPERTY_ID_ROUTE.replace(/{id}/g, id.toString()))
            .then((res:AxiosResponse) => setProperty(res))
            .catch((err:AxiosError) => setProperty(err.response));
        if (context.viewState==="PENDING_BEGIN") context.setViewState("PENDING_END");
        else if (context.viewState==="REFRESH") context.setViewState("NONE");
    }, [context]);

    useEffect(() => {
        getProperty(params.propertyId);
    }, [getProperty, params.propertyId]);

    useEffect(() => {
        if (isPathConflict) enqueueSnackbar("Invalid Path", {variant: "info"});
    }, [isPathConflict]);

    // placeholder
    if (property===undefined) {
        return (
            <Grid container columnSpacing={1}>
                {[1, 2, 3].map((item:number) => (<Grid item key={item}><Skeleton variant="text" sx={{width: "84px", height: "42px"}} /></Grid>))}
                <Grid item xs={12}><Skeleton variant="text" sx={{fontSize: "1px"}} /></Grid>
            </Grid>
        );
    }

    // escape with re-direct to 404
    if (isPathConflict || property?.status===404) return <Navigate to="/404" />;

    // escape with error alert
    if (property?.status!==200) return <Alert severity="error" title="Ops. Property Fetch Error" body="Unable to resolve property data." />;

    // create tabs
    const tabs:any=[
        (isBuilding && {
            label: "Property",
            to: APP_PATHS.PROPERTY.replace(/:propertyId/g, params.propertyId),
            tabKey: "PROPERTY",
            icon: BusinessOutlined,
        }),
        (!isBuilding && {
            label: "Campus",
            to: APP_PATHS.CAMPUS.replace(/:propertyId/g, params.propertyId),
            tabKey: "CAMPUS",
            icon: VillaOutlined,
        }),
        (!isBuilding && {
            label: "Campus Properties",
            to: APP_PATHS.CAMPUS_PROPERTIES.replace(/:propertyId/g, params.propertyId),
            tabKey: "CAMPUS_PROPERTIES",
            icon: BusinessOutlined,
        }),
        {
            label: "Building Automation Systems",
            to: APP_PATHS.BAS.replace(/:propertyId/g, params.propertyId),
            tabKey: "BAS",
            icon: LanOutlined,

        },
        {
            label: "Equipment",
            to: APP_PATHS.EQUIPMENT.replace(/:propertyId/g, params.propertyId),
            tabKey: "EQUIPMENT",
            icon: HeatPumpOutlined,
        },
    ];

    return (
        <Box>
            {/* property name & record type */}
            <Grid container columnSpacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item><Typography variant="h4">{property?.data.name}</Typography></Grid>
                <Grid item><Chip sx={{marginBottom: "-20px"}} label={property.data.recordtype} color="default" size="small" variant="filled" /></Grid>
            </Grid>
            {/* tabs */}
            <Tabs tabs={tabs.filter((tab:any) => tab!==false)} tab={props.tab} />
            {/* tab content */}
            <Box sx={{paddingTop: "24px"}}><props.component property={property} /></Box>
        </Box>
    );
}

export default WithTabs;

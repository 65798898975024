/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
import {AxiosResponse} from "axios";
import moment from "moment";
import {GridRowsProp} from "@mui/x-data-grid";
import {ACCESS_LEVEL_LABEL_MAP, AHU_TYPE, COMMUNICATION_PROTOCOLS, COOLING_ELEMENT, FAMILIARITY_LABEL_MAP, HEATING_ELEMENT, HUMIDITY_CONTROL, OA_CONTROL_MULTI_SELECT, OPERATION_TIMES, SCHEDULE_TIME_FORMAT} from "../config";
import {Field} from "../components/generics/inputs";
import {SCHEDULE_KEYS, OperatorDetailsFields, EnertracDetailsFields, WEEK_KEYS, PropertyDetailsFields, OverviewFields, RemoteAccessFields, ControlFunctionsFields, TrendingFields, AhuFields, BaseFields} from "../components/forms";
import {CELL_EMPTY_PLACEHOLDER} from "./index";
import {LOG_COLUMNS_DEF} from "../components/layout/ChangeLog";

type FormatValueFunction = (value: string, fieldModified: string) => string;

// ------------------ Helper Functions ------------------
/**
 * constructLabelMap
 * @description construct a label map by overriding the field labels with custom labels
 * @param {Field[]} fields
 * @param {{[key: string]: string}} customLabels
 * @return {{[key: string]: string}}
 */
const constructLabelMap = (fields: Pick<Field, "key" | "label" >[], customLabels?: {[key: string]: string}): {[key: string]: string} => ({
    ...fields.reduce((acc, field) => ({...acc, [field.key]: field.label}), {}),
    ...customLabels,
});

/**
* formatDateTime
* @param {string} dateTime
* @return {string}
*/
const formatDateTime=(dateTime:string):string => moment(dateTime).format("MM/DD/YYYY h:mm A");

/**
* formatValue
* @param {string} value
* @return {string}
*/
const formatValue = (value: string, fieldModified: string, formatFunc: FormatValueFunction, bases?:AxiosResponse|null): string => {
    const COMMON_VALUE_MAP = {
        true: "True",
        false: "False",
        "": CELL_EMPTY_PLACEHOLDER,
        null: CELL_EMPTY_PLACEHOLDER,
        ...constructLabelMap(FAMILIARITY_LABEL_MAP),
    };

    // Common value formatting
    if (!value) return CELL_EMPTY_PLACEHOLDER;
    if (value in COMMON_VALUE_MAP) return COMMON_VALUE_MAP[value as keyof typeof COMMON_VALUE_MAP];
    // find bas by 'bas_id' and if found return '<bas.software> <bas.version>' or else return 'Deleted (<bas_id>)'
    if (fieldModified === "bas_id" && bases) {
        const bas = bases.data.results.find((result: any) => result.id.toString() === value);
        return bas ? `${bas.software} ${bas.version}` : `Deleted (${value})`;
    }

    // Resource-specific value formatting
    return formatFunc(value, fieldModified);
};

/**
* formatFieldModified
* @param {string} fieldModified
* @param {any} labelMap
* @return {string}
*/
const formatFieldModified = (fieldModified: string, labelMap: any): string => {
    if (fieldModified in COMMON_LABEL_MAP) return COMMON_LABEL_MAP[fieldModified as keyof typeof COMMON_LABEL_MAP];
    if (fieldModified in labelMap) return labelMap[fieldModified];
    return fieldModified;
};

/**
* resolveLogs
* @param {AxiosResponse|null} response
* @return {GridRowsProp[]}
*/
const resolveLogs=(response:AxiosResponse|null, bases?:AxiosResponse|null):GridRowsProp[] => {
    if (!response) return [];
    return response?.data.results.map((result: any, i: any) => ({id: i, ...resolveLog(result, bases)}));
};

/**
* resolveLog
* @param {any} result
* @return {any}
*/
const resolveLog=(result: any, bases?:AxiosResponse|null): any => {
    let formatValueFunc: FormatValueFunction;
    let labelMap;
    if (result.resource_ === "property") {
        formatValueFunc = formatPropertyValue;
        labelMap = PROPERTY_LABEL_MAP;
    } else if (result.resource_ === "bas") {
        formatValueFunc = formatBasValue;
        labelMap = BAS_LABEL_MAP;
    } else if (result.resource_ === "ahu") {
        formatValueFunc = formatSystemValue;
        labelMap = AHU_LABEL_MAP;
    } else {
        // formatValueFunc is not defined for components, as they don't have any specific value formatting
        formatValueFunc = (v:any) => v;
        labelMap=COMPONENT_LABEL_MAP;
    }

    return {
        [LOG_COLUMNS_DEF[0].field]: formatDateTime(result.date_time),
        [LOG_COLUMNS_DEF[1].field]: formatFieldModified(result.field_modified, labelMap),
        [LOG_COLUMNS_DEF[2].field]: formatValue(result.old_val, result.field_modified, formatValueFunc!, bases),
        [LOG_COLUMNS_DEF[3].field]: formatValue(result.new_val, result.field_modified, formatValueFunc!, bases),
        [LOG_COLUMNS_DEF[4].field]: result.user_,
    };
};

// ------------------ Label Mappers ------------------
const COMMON_LABEL_MAP = {
    CREATED: "Created",
    archived: "Archived",
    archived_reason: "Archived Reason",
    bas_id: "Building Automation System",
    ...constructLabelMap(HUMIDITY_CONTROL),
    ...constructLabelMap(OA_CONTROL_MULTI_SELECT),
    ...constructLabelMap(OPERATION_TIMES),
};

// construct SCHEDULE_LABEL_MAP to map schedule keys to labels (e.g., "m_start" -> "Monday Start Time")
const SCHEDULE_LABEL_MAP = SCHEDULE_KEYS.reduce((acc, scheduleKey) => {
    // split scheduleKey to get the day abbreviation (e.g., "m") and find the full day name (e.g., "Monday")
    const dayKey = scheduleKey.split("_")[0];
    const dayLabel = WEEK_KEYS.find(({key: weekKey}) => weekKey === dayKey)?.label;

    // find the label for the time boundary (Start or End Time) for this scheduleKey
    const timeBounderyLabel = PropertyDetailsFields.find((item) => item.key === scheduleKey)?.label;

    // combine day and time boundary labels
    return ({...acc, [scheduleKey]: `${dayLabel} ${timeBounderyLabel}`});
}, {});

const PROPERTY_LABEL_MAP = {
    ...constructLabelMap(OperatorDetailsFields, {operator_notes: "Operator Notes"}),
    ...constructLabelMap(EnertracDetailsFields, {notes: "EnerTrac Notes"}),
    ...SCHEDULE_LABEL_MAP,
};

const BAS_LABEL_MAP = {
    ...constructLabelMap(OverviewFields),
    ...constructLabelMap(RemoteAccessFields, {remote_access_notes: "Remote Access Notes"}),
    ...constructLabelMap(TrendingFields, {trending_charts_notes: "Trending Charts Notes", trending_lines_notes: "Trending Lines Notes", trending_points_notes: "Trending Points Notes"}),
    ...constructLabelMap(ControlFunctionsFields, {zone_temperature_notes: "Constrol Functions Notes"}),
    ...constructLabelMap(COMMUNICATION_PROTOCOLS),
};

const AHU_LABEL_MAP = {
    ...constructLabelMap(AhuFields),
};

const COMPONENT_LABEL_MAP = {
    ...constructLabelMap(BaseFields),
};

// ------------------ Resource Specific Functions ------------------
/**
* formatPropertyValue
* @param {string} value
* @param {string} fieldModified
* @return {string}
*/
const formatPropertyValue: FormatValueFunction = (value, fieldModified) => {
    // Occupancy Schedule
    if (fieldModified in SCHEDULE_LABEL_MAP && moment(value, SCHEDULE_TIME_FORMAT).isValid()) return moment(value, SCHEDULE_TIME_FORMAT).format("hh:mm A");

    return value;
};

/**
* formatBasValue
* @param {string} value
* @param {string} fieldModified
* @return {string}
*/
const formatBasValue: FormatValueFunction = (value, fieldModified) => {
    // Level of access
    if (fieldModified === "level_of_access") return ACCESS_LEVEL_LABEL_MAP.find((item) => item.key === value)?.label || value;

    return value;
};

/**
* formatSystemValue
* @description generic function to format values for all system resources
* @param {string} value
* @param {string} fieldModified
* @return {string}
*/
const formatSystemValue: FormatValueFunction = (value, fieldModified) => {
    if (fieldModified === "type") return AHU_TYPE.find((item) => item.key === value)?.label || value;
    if (fieldModified === "heating_element") return HEATING_ELEMENT.find((item) => item.key === value)?.label || value;
    if (fieldModified === "cooling_element") return COOLING_ELEMENT.find((item) => item.key === value)?.label || value;
    // TODO: resolving other Single-select chip's values will be added later, when the maps(key-label) are available in columnMap.ts
    return value;
};

export {
    resolveLogs,
};

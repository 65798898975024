import React from "react";
import {Auth0Provider as Auth0ProviderBase, AppState, User} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import {AUTH0_CONFIG} from "../../config";

interface Props{
    children:React.ReactElement
}

/**
 * Auth0Provider
 * @return {React.ReactElement}
 */
function Auth0Provider(props:Props):React.ReactElement {
    const navigate = useNavigate();
    /**
     * onRedirectCallback
     * @param {AppState} appState
     * @param {User} user
     */
    const onRedirectCallback = (appState?: AppState, user?: User):void => {
        const query="?signin=true";
        navigate((appState && `${appState.returnTo}${query}`) || `${window.location.pathname}${query}`);
    };
    return (
        <Auth0ProviderBase onRedirectCallback={onRedirectCallback} {...AUTH0_CONFIG}>
            {props.children}
        </Auth0ProviderBase>
    );
}

export default Auth0Provider;

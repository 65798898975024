import React from "react";
import {Grid} from "@mui/material";
import * as yup from "yup";
import {UseFormReturn} from "react-hook-form";
import {AxiosResponse} from "axios";
import _ from "lodash";
import {populateValues, resolveLabels, resolveLabel, resolvePoints, cleanValue} from "../../../../handlers";
import {ControlledAutocomplete, ControlledLabelSelector, ControlledTextField, Field} from "../../../generics/inputs";
import {AHU_TYPE, HEATING_ELEMENT, OPERATION_TIMES, BAS_ARCHIVED_LABEL, OA_CONTROL_SINGLE_SELECT} from "../../../../config";
import {Fields as PointFields} from "../../common/Point";
import {COMMON_FIELDS} from "../../common/Fields";
import {OptionType} from "../../../../types";

interface HvuProps{
    fields:Field[]
    bases:string[]
    form:UseFormReturn
}

/**
 * toApi
 * @param {any} values
 * @param {AxiosResponse} bases
 */
export const toApi=(values:any, bases:AxiosResponse):any => {
    const KEYS=[
        {key: "bas_version", labels: undefined},
        {key: "operation_times", labels: OPERATION_TIMES},
        {key: "type", labels: AHU_TYPE},
        {key: "oa_control", labels: OA_CONTROL_SINGLE_SELECT},
        {key: "heating_element", labels: HEATING_ELEMENT},
    ];
    // remove UI keys
    const payload:any=_.omit(values, KEYS.slice(0, 3).map((i:any) => i.key));
    // resolve key/id => label(s)
    payload.bas_id=bases.data.results.find((r:any) => r.software===values.bas_version.primary && r.version===values.bas_version.secondary)?.id;
    KEYS.slice(1, 2).forEach((i:any) => resolveLabels(values[i.key], payload, i.labels));
    KEYS.slice(2).forEach((i:any) => { payload[i.key]=resolveLabel(i.labels, values[i.key]); });
    payload.sequence_of_operations=values.sequence_of_operations.length===0?null:values.sequence_of_operations;
    payload.points={}; // TODO Add points once they are available.
    return payload;
};

/**
 * fromApi
 * @param {AxiosResponse} record
 * @param {AxiosResponse} bases
 * @param {Field[]} fields
 */
export const fromApi=(record:AxiosResponse, bases:AxiosResponse, fields:Field[]):any => ({
    ...fields?.reduce(
        (a:any, v:any) => {
            const {data}:any=record;
            const bas=bases?.data.results.find((i:any) => i.id===data.bas_id);
            // NOTE: aux func to resolve incomming api keys
            const resolveKey=(labels:any):any => labels.filter((i:any) => ((i.key in data) && data[i.key]===true)).map((i:any) => i.label); // eslint-disable-line require-jsdoc
            const resolveKeyOverride=(labels:any):any => labels.find((i:any) => i.key===data[v.key])?.label; // eslint-disable-line require-jsdoc
            const KEYS=[
                {key: "bas_version", labels: {primary: bas?.software||"None", secondary: bas?.version||"", ...bas?.archived===true && {tertiary: BAS_ARCHIVED_LABEL}} as OptionType},
                {key: "operation_times", labels: resolveKey(OPERATION_TIMES)},
                {key: "type", labels: resolveKeyOverride(AHU_TYPE)},
                {key: "oa_control", labels: resolveKeyOverride(OA_CONTROL_SINGLE_SELECT)},
                {key: "heating_element", labels: resolveKeyOverride(HEATING_ELEMENT)},
                {key: "points", labels: resolvePoints(data.points)},
            ];

            return ({
                ...a,
                [v.key]: KEYS.find((i:any) => i.key===v.key)?.labels||cleanValue(record?.data?.[v.key]),
            });
        },
        {},
    ),
});

export const Fields:Field[] = [
    COMMON_FIELDS.name,
    COMMON_FIELDS.sequence_of_operations,
    COMMON_FIELDS.tag_id,
    {
        key: "oa_control",
        label: "OA Control",
        yup: yup.mixed().nullable(),
        type: "labelselector",
        labelselectorOptions: {
            options: [],
            disabled: true,
            multi: false,
        },
    },
    COMMON_FIELDS.bas_version,
    {
        key: "zones_served",
        label: "Zones Served",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
    },
    {
        key: "type",
        label: "Type",
        yup: yup.mixed().nullable(),
        type: "labelselector",
        labelselectorOptions: {
            options: [],
            disabled: true,
            multi: false,
        },
    },
    {
        key: "heating_element",
        label: "Heating Element",
        yup: yup.mixed().nullable(),
        type: "labelselector",
        labelselectorOptions: {
            options: [],
            disabled: true,
            multi: false,
        },
    },
    COMMON_FIELDS.operation_times,
    COMMON_FIELDS.notes,
    {
        key: "points",
        label: "Points",
        yup: yup.array().of(yup.object().shape(PointFields.reduce((a:any, v:any) => ({...a, [v.key]: v.yup}), {}))),
        type: "points",
        pointsOptions: {
            disabled: true,
            options: [
                "schedule",
            ],
        },
    },
];

/**
 * Hvu
 * @return {React.ReactElement}
 */
function Hvu(props:HvuProps):React.ReactElement {
    // set field options
    [
        {values: props.bases, key: "bas_version"},
        {values: AHU_TYPE, key: "type"},
        {values: OA_CONTROL_SINGLE_SELECT, key: "oa_control"},
        {values: HEATING_ELEMENT, key: "heating_element"},
        {values: OPERATION_TIMES, key: "operation_times"},
    ].forEach((item:any) => populateValues(item.key==="bas_version"?item.values:item.values.map((i:any) => i.label), props.fields, item.key));

    return (
        <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
            {props.fields.slice(0, props.fields.length-5).map((field:Field) => (
                <Grid key={field.key} item xs={12} sm={12} md={12} lg={6} xl={6}>
                    {field.type==="textfield" && <ControlledTextField field={field} control={props.form.control} errors={props.form.formState.errors} />}
                    {field.type==="labelselector" && <ControlledLabelSelector field={field} control={props.form.control} />}
                    {field.type==="autocomplete" && <ControlledAutocomplete field={field} control={props.form.control} errors={props.form.formState.errors} />}
                </Grid>
            ))}
            {/* 2-column layout */}
            <Grid item container spacing={4}>
                <Grid item container xs={12} sm={12} md={12} lg={6} xl={6}>
                    {props.fields.slice(6, props.fields.length-2).map((field:Field) => (
                        <Grid item key={field.key} xs={12}>
                            {field.type==="labelselector" && <ControlledLabelSelector field={field} control={props.form.control} />}
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}><ControlledTextField field={props.fields[props.fields.length-2]} control={props.form.control} errors={props.form.formState.errors} /></Grid>
            </Grid>
            {/* Points TODO: Add points if/when available. */}
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><PointsGrid values={props.values} field={props.fields[props.fields.length-1]} form={props.form} /></Grid> */}
        </Grid>
    );
}

export default Hvu;

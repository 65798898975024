import {Auth0ProviderOptions} from "@auth0/auth0-react";
import moment from "moment";
import {env} from "./env";

export const REACT_APP_VERSION="1.0.0a18";

export const APP_PATHS={
    HOME: "/",
    PROPERTIES: "/properties",
    // Property Tab
    PROPERTY: "/properties/:propertyId/property",
    // Building Automation Systems Tabs
    BAS: "/properties/:propertyId/bas", // list view
    BAS_POST: "/properties/:propertyId/bas/post",
    BAS_DETAILS: "/properties/:propertyId/bas/:basId/details", // details view
    // Equipment Tabs
    EQUIPMENT: "/properties/:propertyId/equipment", // list view
    EQUIPMENT_POST: "/properties/:propertyId/equipment/:systemId/post",
    EQUIPMENT_DETAILS: "/properties/:propertyId/equipment/:systemId/:equipmentId/details", // details view
    // Campus Tabs
    CAMPUS: "/properties/:propertyId/campus",
    CAMPUS_PROPERTIES: "/properties/:propertyId/campus/properties",
    // Documentation
    DOCS: "/docs",
};

export const APP_PATHS_KEYS=["post", "details"];

export const AUTH0_CONFIG:Auth0ProviderOptions={
    domain: env.REACT_APP_AUTH0_DOMAIN,
    clientId: env.REACT_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
        redirect_uri: `${window.location.origin}`,
        scope: env.REACT_APP_AUTH0_SCOPE,
        audience: env.REACT_APP_AUTH0_AUDIENCE,
    },
    // NOTE:
    // cacheLocation default is memory. Problem is that some browsers block third-party cookies.
    // this block causes Silent Authentication to fail on refresh
    // switching to localstorage. The following thread explains the issue in detail
    // https://community.auth0.com/t/why-is-authentication-lost-after-refreshing-my-single-page-application/56276
    useRefreshTokens: true,
    cacheLocation: "localstorage",
};

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
export const STATUS_400_RANGE=[
    400,
    401,
    402,
    403,
    404,
    405,
    406,
    407,
    408,
    409,
    410,
    411,
    412,
    413,
    414,
    415,
    416,
    417,
    418,
    421,
    422,
    423,
    424,
    425,
    426,
    428,
    429,
    431,
    451,
];

export const STATUS_500_RANGE:any={
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Gateway Timeout",
    505: "HTTP Version Not Supported",
};

export const MIN_DATE=moment("01-01-1900", "DD-MM-YYYY");
export const MAX_DATE=moment("01-01-2050", "DD-MM-YYYY");
export const SCHEDULE_TIME_FORMAT="HH:mm:ss";
export const API_PRIMARY_FIELD_REGEX={regex: /^[0-9a-zA-Z_\-\\.\s]*$/, message: "Only \"- _ .\" allowed"};
export const ALPHA_NUMERIC_FIELD_REGEX={regex: /^[a-zA-Z0-9]*$/, message: "Only letters and numbers"};
export const FORM_REQUIRED_SNACK_MESSAGE="Please enter required fields";
export const BAS_ARCHIVED_LABEL="Archived";
export const CALL_DELAY=100;
export const CALL_DELAY_INCREMENT=100;
export const MAX_DOCUMENTS_LENGTH=10;
export const MAX_METERINFO_LENGTH=10;

export * from "./endpoints";
export * from "./columnMap";
export {env} from "./env";

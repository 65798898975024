import React from "react";
import {AddOutlined} from "@mui/icons-material";
import _ from "lodash";
import {Grid, Typography, List, ListItemButton, ListItemText, ListItemIcon, Divider} from "@mui/material";
import Dialog from "../../generics/Dialog";
import {DialogType, EquipmentType, Component} from "../../../types";

interface AddComponentFilterProps {
    equipment:EquipmentType
    status:DialogType
    dialogType:DialogType
    onClose:(args:React.MouseEvent) => void
    onSubmit:(component:any) => (args:React.MouseEvent) => void
}

/**
 * AddComponentFilter
 * @param {AddComponentFilterProps} props
 * @return {React.ReactElement|null}
 */
function AddComponentFilter(props:AddComponentFilterProps):React.ReactElement|null {
    const {component}=props.equipment;
    if (component===undefined) return null;

    // resolve if component has variant types
    let list:Component[]=[];
    if (Array.isArray(component)) list=component;
    else list=[component];

    // construct components list
    const componentsElements:React.ReactElement[]=[];
    list.forEach((c:Component, index:number) => {
        componentsElements.push(
            <ListItemButton onClick={props.onSubmit({...c})} key={`${c.variant?.key || c.key}-${c.variant?.label || c.label}`}>
                <ListItemText primary={c.variant?.label || c.label} />
                <ListItemIcon sx={{minWidth: "0"}}><AddOutlined /></ListItemIcon>
            </ListItemButton>,
        );
        if (index<(list.length)-1) componentsElements.push(<Divider key={`${c.variant?.key}-${c.variant?.label}-divider`} />);
    });

    return (
        <Dialog
            open={props.status===props.dialogType}
            onClose={{onClick: props.onClose}}
            title="Add Component"
            content={(
                <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {/* components */}
                    <Grid item xs={12}>
                        <Typography variant="body1">Click on the desired component to add:</Typography>
                        <List
                            sx={{
                                borderRadius: "var(--borderRadius, 4px)",
                                border: "1px solid var(--components-input-outlined-enabledBorder, rgba(0, 0, 0, 0.23))",
                            }}
                        >
                            {componentsElements}
                        </List>
                    </Grid>
                </Grid>
            )}
        />
    );
}

export default AddComponentFilter;

import React, {memo} from "react";
import {Box, Grid, Typography} from "@mui/material";
import * as yup from "yup";
import {useForm, UseFormReturn} from "react-hook-form";
import {CheckOutlined, CloseOutlined} from "@mui/icons-material";
import {Field, ControlledLabelSelector, ControlledTextField, Chip} from "../../generics/inputs";
import {POINT_BOOLEANS} from "../../../config";
import {populateValues, resolveFormKey} from "../../../handlers";

interface Props{
    form:UseFormReturn
    fields:Field[]
    label:string
    pointKey:string
    selectors:string[]
    onSelectorChange: (value:string) => (args:React.MouseEvent) => void
}

interface HeaderProps{
    fields:Field[]
    breaks:any[]
    selectors:string[]
    onSelectorChange: (value:string) => (args:React.MouseEvent) => void
}

export const Fields:Field[] = [
    {
        key: "tag_id",
        label: "Point Tag/ID",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            fieldLabel: true,
        },
    },
    {
        key: "point_booleans",
        label: "Point Booleans",
        yup: yup.mixed().nullable(),
        type: "labelselector",
        labelselectorOptions: {
            options: [],
            disabled: true,
            multi: true,
            fieldLabel: true,
            fullWidth: true,
        },
    },
    {
        key: "notes",
        label: "Note",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            fieldLabel: true,
        },
    },
];

/**
 * Header
 * @param {HeaderProps} props
 * @return {React.ReactElement|null}
 */
function Header(props:HeaderProps):React.ReactElement|null {
    // resolve form key
    const {isNested, key}:any=resolveFormKey(props.fields[0].key);
    if (key.index!=="0") return null;
    // Global Selectors
    const selectors=(
        <Grid container direction="row" justifyContent="space-around" alignItems="flex-start" spacing={1}>
            {POINT_BOOLEANS.map((i:any, idx:number) => {
                const isSelected=props.selectors.includes(i.label);
                return (
                    <Grid item key={`${i.key}-selector`} xs={Math.round(12/POINT_BOOLEANS.length)}>
                        <Chip
                            sx={{width: "100%", textTransform: "capitalize"}}
                            disabled={props.fields[key.index]?.textfieldOptions?.readOnly}
                            color={isSelected?"primary":"secondary"}
                            variant={isSelected?"filled":"outlined"}
                            label={`All ${idx===2?i.label.replace("Has", "Have"):i.label}`}
                            onDelete={() => {}}
                            onClick={props.onSelectorChange(i.label)}
                            deleteIcon={isSelected?<CheckOutlined />:<CloseOutlined />}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );

    return (
        <Grid container sx={{marginBottom: "24px"}}>
            <Grid item {...props.breaks[0]}><Typography variant="body1">Point Name</Typography></Grid>
            {props.fields.map((field:Field, idx:number) => {
                if (idx===1) return <Grid item {...props.breaks[idx+1]} sx={{paddingLeft: "7px", paddingRight: "7px"}} key={`${field.key}-${key.index}-header`}>{selectors}</Grid>;
                return (<Grid item {...props.breaks[idx+1]} sx={{paddingLeft: idx===2?"18px":"6px"}} key={`${field.key}-${key.index}-header`}><Typography variant="body1">{field.label}</Typography></Grid>);
            })}
        </Grid>
    );
}

/**
 * Point
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Point(props:Props):React.ReactElement {
    let form:UseFormReturn=useForm({mode: "onSubmit"});
    if (props.form) form=props.form;

    // set field options
    populateValues(POINT_BOOLEANS.map((i:any) => i.label), props.fields, "point_booleans");

    // breakpoints
    const breaks=[
        {xs: 2.75, sm: 2.75, md: 2.75, lg: 2.75, xl: 2.75},
        {xs: 1.25, sm: 1.25, md: 1.25, lg: 1.25, xl: 1.25},
        {xs: 5, sm: 5, md: 5, lg: 5, xl: 5},
        {xs: 3, sm: 3, md: 3, lg: 3, xl: 3},
    ];

    return (
        <Box>
            {/* header */}
            <Header fields={props.fields} breaks={breaks} selectors={props.selectors} onSelectorChange={props.onSelectorChange} />
            <Box
                sx={{
                    height: "80px",
                    marginTop: "15px",
                    paddingTop: "12px",
                    backgroundColor: {
                        "&:hover": {
                            backgroundColor: "#eeeeee",
                            marginLeft: "-32px",
                            marginRight: "-32px",
                            paddingLeft: "32px",
                            paddingRight: "32px",
                        },
                    },
                }}
            >
                <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {/* fields */}
                    {/* point key */}
                    <Grid item {...breaks[0]} sx={{marginTop: "16px"}}><Typography variant="body1">{props.label}</Typography></Grid>
                    {/* point form fields */}
                    {props.fields.map((field:Field, idx:number) => {
                        if (field.type==="labelselector") return <Grid item sx={{marginTop: "12px"}} key={`${field.key}`} {...breaks[idx+1]}><ControlledLabelSelector field={field} control={form.control} /></Grid>;
                        if (field.type==="textfield") return <Grid item key={`${field.key}`} {...breaks[idx+1]}><ControlledTextField field={field} control={form.control} errors={form.formState.errors} /></Grid>;
                        return null;
                    })}
                </Grid>
            </Box>
        </Box>
    );
}

// /**
//  * arePropsEqual
//  * @param {Props} prevProps
//  * @param {Props} nextProps
//  * @return {boolean}
//  */
// const arePropsEqual=(prevProps:Props, nextProps:Props):boolean => true;

const Memoize=memo((props:Props) => <Point {...props} />);

export default Memoize;

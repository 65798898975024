import React, {useEffect} from "react";
import {Grid, GridOwnProps} from "@mui/material";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {Field, ControlledTextField} from "../../generics/inputs";

interface CampusProps {
    values: {
        name: string,
        organization: string,
        primaryfunction: any,
        id: any,
        address: any,
        building_size_sqft: any,
        number_of_properties: any,
    }
}

export const Fields:Field[] = [
    {
        key: "name",
        label: "Property Name",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    {
        key: "organization",
        label: "Operating Agency",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    {
        key: "primaryfunction",
        label: "Primary Function",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    {
        key: "id",
        label: "BDBID",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    {
        key: "property_status",
        label: "Property Status",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    {
        key: "address",
        label: "Complete Address",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    {
        key: "building_size_sqft",
        label: "Campus Gross Floor Area (sf)",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
    {
        key: "number_of_properties",
        label: "Number of Properties",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            disabled: true,
        },
    },
];

/**
 * Campus
 * @param {CampusProps} props
 * @return {React.ReactElement}
 */
function Campus(props: CampusProps): React.ReactElement {
    const {reset, control} = useForm();
    const ColumnsConfigs:GridOwnProps = {
        container: true,
        item: true,
        direction: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        spacing: 2,
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
    };

    useEffect(() => {
        if (props?.values) reset(props?.values);
    }, [props?.values, reset]);

    return (
        <form>
            <Grid container direction="row" justifyContent="center" alignItems="start" spacing={4}>
                <Grid {...ColumnsConfigs}>
                    {Fields.slice(0, 4).map((field) => (
                        <Grid item key={field.key}><ControlledTextField control={control} field={field} /></Grid>
                    ))}
                </Grid>
                <Grid {...ColumnsConfigs}>
                    {Fields.slice(4, 7).map((field) => (
                        <Grid item key={field.key}><ControlledTextField control={control} field={field} /></Grid>
                    ))}
                </Grid>
            </Grid>
        </form>
    );
}

export default Campus;

import React, {useEffect, useState, Dispatch, SetStateAction} from "react";
import {Box, Paper, LinearProgress} from "@mui/material";
import {FileUploadOutlined, SmsFailedOutlined} from "@mui/icons-material";
import {GridRenderCellParams, GridColDef} from "@mui/x-data-grid";
import {AxiosResponse} from "axios";
import _ from "lodash";
import {APP_PATHS, PROPERTY_ROUTE, ORGANIZATION_ROUTE, PRIMARY_FUNCTION_ROUTE} from "../config";
import {resolveFilterChipValues, CELL_EMPTY_PLACEHOLDER} from "../handlers";
import {FilterChipType, SearchType} from "../types";
// components
import DataGrid from "../components/generics/DataGrid";
import Link from "../components/generics/Link";
import EmptyStateBlock from "../components/generics/EmptyStateBlock";
import {PropertiesFilter, PropertiesFields, Search as SearchFilter, validateSearch} from "../components/forms";
import {useCall, BaseState} from "../components/generics/Call";

// TODO Add this to columnMap.ts
const PROPERTY_STATUS_OPTIONS=[
    "Out of Service - Temp",
    "Out of Service - Perm",
    "Under Review", "Active",
    "Mark for Deletion",
    "Future Site",
];

// TODO Add this to columnMap.ts
const RECORD_TYPES_OPTIONS=["Building", "Campus"];

const PROPERTY_COLUMNS_DEF:GridColDef[]=[
    {
        field: "name",
        headerName: "Property Name",
        flex: 1.3,
        renderCell: (params:GridRenderCellParams) => {
            let to=APP_PATHS.PROPERTY;
            if (params.row.recordtype?.toUpperCase()==="CAMPUS") to=APP_PATHS.CAMPUS;
            return <Link to={to.replace(/:propertyId/g, params.row.id)}>{params.value}</Link>;
        },
    },
    {field: "address", headerName: "Complete Address", flex: 1},
    {field: "property_status", headerName: "Property Status", flex: 0.5},
    {field: "id", headerName: "BDBID"},
    {field: "organization", headerName: "Operating Agency", flex: 0.5},
    {field: "recordtype", headerName: "Record Type"},
    {field: "primaryfunction", headerName: "Primary Function", flex: 0.5},
    {
        field: "building_size_sqft",
        headerName: "Gross Floor Area (sf)",
        flex: 0.5,
        renderCell: (params:GridRenderCellParams) => <Box>{params.value?.toLocaleString()}</Box>,
    },
    {field: "year_built", headerName: "Year Built"},

];

export interface State extends Omit<BaseState, "dialog"|"record"|"formValues">{
    search:SearchType|null
    filterStatus:boolean
    filterValues:any
    filterChipKey:any
    agencies:AxiosResponse|null
    primaryfunction:AxiosResponse|null
}

/**
 * Properties
 * @return {React.ReactElement}
 */
function Properties():React.ReactElement {
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        search: null,
        filterStatus: false,
        filterValues: null,
        filterChipKey: null,
        agencies: null,
        primaryfunction: null,
    });
    const {get}=useCall({state, setState: setState as any});

    /**
     * onFilterFieldsChange
     * @param {string} fieldKey
     * @return {void}
     */
    const onFilterFieldsChange=(fieldKey:string) => (args:React.MouseEvent):void => {
        const stateClone=_.cloneDeep(state);
        stateClone.filterChipKey=fieldKey;
        // remove field key from form
        const newFilterValues=Object.keys(state.filterValues)
            .filter((key:string) => key!==fieldKey)
            .reduce(
                (obj:any, key:string) => {
                    obj[key]=state.filterValues[key]; // eslint-disable-line no-param-reassign
                    return obj;
                },
                {},
            );
        stateClone.filterValues=newFilterValues;
        setState(stateClone);
    };

    /**
     * transformData
     * @param {AxiosResponse} response
     * @param {GridColDef[]} colDef
     * @return {AxiosResponse}
     */
    const transformData=(response:AxiosResponse, colDef:GridColDef[]):AxiosResponse => {
        response.data.results.forEach((res: any) => {
            res.year_built = (res.recordtype.toLowerCase() === "campus") ? CELL_EMPTY_PLACEHOLDER : res.year_built;
        });
        return response;
    };

    /**
     * onSubmit
     * @param {any} values
     * @return {void}
     */
    const onSubmit=(values:any):void => {
        const stateClone=_.cloneDeep(state);
        stateClone.filterValues=values;
        stateClone.filterStatus=false;
        stateClone.filterChipKey=null;
        setState(stateClone);
    };

    /**
     * onClose
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onClose=(args:React.MouseEvent):void => setState({..._.cloneDeep(state), filterStatus: false});

    /**
     * onSearchSubmit
     * @param {any} value
     * @return {Promise<void>}
     */
    const onSearchSubmit=async (value:any):Promise<void> => {
        // resolve search type along with api key
        if (await validateSearch(value.search)==="TEXT") setState({..._.cloneDeep(state), search: {key: "name__contains", value: value.search}});
        else if (await validateSearch(value.search)==="NUMBER") setState({..._.cloneDeep(state), search: {key: "id__contains", value: value.search}});
    };

    /**
     * onSearchClear
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onSearchClear=(args:React.MouseEvent):void => setState({..._.cloneDeep(state), search: null});

    useEffect(() => {
        if (state.agencies===null) get(ORGANIZATION_ROUTE, "agencies", true);
        if (state.primaryfunction===null) get(PRIMARY_FUNCTION_ROUTE, "primaryfunction", true);
    }, [get, state.agencies, state.primaryfunction]);

    const result:FilterChipType=resolveFilterChipValues(state.filterValues, PropertiesFields, state.filterChipKey, state.search, null);

    if (state.agencies===null || state.primaryfunction===null) return <LinearProgress />;

    return (
        <Box>
            {/* Table */}
            <DataGrid
                columns={PROPERTY_COLUMNS_DEF}
                url={`${PROPERTY_ROUTE}${result.isQuery?`?${result.query.toString()}`:""}`}
                filter={(
                    <Paper sx={{maxWidth: "700px"}}>
                        <PropertiesFilter
                            agencies={state.agencies?.status===200?state.agencies?.data?.results?.map((item:any) => item.name):[]}
                            recordtypes={RECORD_TYPES_OPTIONS}
                            primaryfunctions={state.primaryfunction?.status===200?state.primaryfunction?.data?.results?.map((item:any) => item.name):[]}
                            propertystatus={PROPERTY_STATUS_OPTIONS}
                            values={state.filterValues}
                            onSubmit={onSubmit}
                            onClose={onClose}
                        />
                    </Paper>
                )}
                search={<SearchFilter values={state.search?.value} onSubmit={onSearchSubmit} onClear={onSearchClear} helperText="Search for Properties using name or BDBID" />}
                filterFields={result.filterFields}
                onFilterFieldsChange={onFilterFieldsChange}
                state={state}
                setState={setState}
                transformData={transformData}
                actions={[
                    {
                        label: "Export",
                        key: "export",
                        icon: <FileUploadOutlined />,
                        onClick: (row) => (args:React.MouseEvent):void => console.log("Export", row),
                    },
                ]}
                isQuery={result.isQuery}
                noRowsOverlay={(
                    <EmptyStateBlock
                        header="No properties match search criteria."
                        subHeaders={["You can search by property name or BDBID.", "You can use filters to narrow down results."]}
                        icon={SmsFailedOutlined}
                    />
                )}
            />
        </Box>
    );
}

export default Properties;

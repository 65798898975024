import React from "react";

/**
 * Docs
 * @return {React.ReactElement}
 */
function Docs():React.ReactElement {
    return (
        <div>
            Docs
        </div>
    );
}

export default Docs;

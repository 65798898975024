import React, {useEffect, useState} from "react";
import {Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {AddOutlined} from "@mui/icons-material";
import * as yup from "yup";
import _ from "lodash";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {populateValues} from "../../../handlers";
import {Field, ControlledTextField, ControlledAutocomplete} from "../../generics/inputs";
import Dialog from "../../generics/Dialog";
import {DialogType} from "../../../types";
import {EQUIPMENT_CATEGORY_MAP} from "../../../config";

export const Fields: Field[] = [
    {
        key: "name",
        label: "",
        yup: yup.mixed().nullable(),
        type: "textfield",
        textfieldOptions: {
            placeholder: "Search for equipment to add",
        },
    },
    {
        key: "category",
        label: "Category",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            helperText: "Filter equipment by category",
        },
    },
];

interface AddEquipmentFilterProps {
    equipments:any[]
    status:DialogType
    dialogType:DialogType
    onClose:(args:React.MouseEvent) => void
    onSubmit:(equipment:any) => (args:React.MouseEvent) => void
}

/**
 * AddEquipmentFilterProps
 * @param {AddEquipmentFilterProps} props
 * @return {React.ReactElement}
 */
function AddEquipmentFilter(props: AddEquipmentFilterProps):React.ReactElement {
    const [equipments, setEquipments]=useState<any>(props.equipments);

    // set category options
    populateValues(EQUIPMENT_CATEGORY_MAP.map((i:any) => i.label), Fields, "category");

    // construct yup object
    const yupObject: any = {};
    Fields.forEach((field:Field) => { yupObject[field.key] = field.yup; });

    const {formState: {errors}, control, watch} = useForm({
        mode: "onChange",
        resolver: yupResolver(yup.object(yupObject)),
    });

    useEffect(() => {
        const subscription = watch((values:any, {name, type}:any) => {
            let newEquipments=props.equipments;
            if (values.category) newEquipments=newEquipments.filter((i:any) => i.category===EQUIPMENT_CATEGORY_MAP.find((l:any) => l.label===values.category).category);
            if (values.name?.length!==0) newEquipments=newEquipments.filter((i:any) => (i.label.toLowerCase()).includes(values.name.toLowerCase()));
            setEquipments(newEquipments);
        });
        return () => subscription.unsubscribe();
    }, [watch, props.equipments]);

    // construct equipment list
    const equipmentElements:React.ReactElement[]=[];
    equipments.forEach((equipment:any, index:number) => {
        equipmentElements.push(
            <ListItemButton onClick={props.onSubmit(_.omit(equipment, ["system", "component", "systemFields", "resolveValues"]))} key={`${equipment.key}-${equipment.label}`}>
                <ListItemText primary={equipment.label} />
                <ListItemIcon sx={{minWidth: "0"}}><AddOutlined /></ListItemIcon>
            </ListItemButton>,
        );
        if (index<props.equipments.length-1) equipmentElements.push(<Divider key={`${equipment.key}-${equipment.label}-divider`} />);
    });

    return (
        <Dialog
            open={props.status===props.dialogType}
            onClose={{onClick: props.onClose}}
            title="Add Equipment"
            content={(
                <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {/* search */}
                    <Grid item xs={12}><ControlledTextField field={Fields[0]} control={control} errors={errors} /></Grid>
                    {/* categories */}
                    <Grid item xs={12}><ControlledAutocomplete field={Fields[1]} control={control} errors={errors} /></Grid>
                    {/* equipments */}
                    <Grid item xs={12}>
                        <Typography variant="body1">Click on the equipment to add:</Typography>
                        <List
                            sx={{
                                minHeight: "300px",
                                maxHeight: "300px",
                                overflow: "auto",
                                borderRadius: "var(--borderRadius, 4px)",
                                border: "1px solid var(--components-input-outlined-enabledBorder, rgba(0, 0, 0, 0.23))",
                            }}
                        >
                            {equipmentElements}
                        </List>
                    </Grid>
                </Grid>
            )}
        />
    );
}

export default AddEquipmentFilter;

import React, {useEffect} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {ControlledSearch, Field} from "../../generics/inputs";
import {validateId} from "../../../handlers";
import {SearchValidation} from "../../../types";

export const Fields: Field[] = [{
    key: "search",
    label: "Search",
    yup: yup.string(),
    type: "search",
    searchOptions: {
        default: "",
    }}];

interface SearchProps {
    onSubmit: (value:any) => void
    onClear: (args:React.MouseEvent) => void
    helperText?:string
    values:any
}

/**
 * validateSearch
 * @param {string} value
 * @return {Promise<SearchValidation>}
 */
export const validateSearch = async (value: string): Promise<SearchValidation> => {
    if (value===null || value.match(/^ *$/)!==null) return undefined;
    // test if value is of type number
    if (await validateId(value)) return "NUMBER";
    // else value of type text
    return "TEXT";
};

/**
 * Search
 * @param {SearchProps} props
 * @return {React.ReactElement}
 */
function Search(props: SearchProps):React.ReactElement {
    const {handleSubmit, formState: {errors}, control, reset} = useForm({
        mode: "onSubmit",
        resolver: yupResolver(yup.object({[Fields[0].key]: Fields[0].yup})),
    });

    // setting search helperText
    Fields[0].searchOptions!.helperText=props.helperText;

    useEffect(() => {
        if (props.values!==undefined) reset({search: props.values});
    }, [props.values, reset]);

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <ControlledSearch field={Fields[0]} control={control} onClear={props.onClear} />
        </form>
    );
}

Search.defaultProps={
    helperText: undefined,
};

export default Search;

import React from "react";
import {Grid} from "@mui/material";
import Dialog from "../../generics/Dialog";
import {DialogType} from "../../../types";

interface Props{
    status:DialogType
    dialogType:DialogType
    onClose:(args:React.MouseEvent) => void
    onSubmit:{onClick: (values:any) => void, label:string}
    title:string
    message:React.ReactElement
}

/**
 * Prompt
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Prompt(props:Props):React.ReactElement|null {
    return (
        <Dialog
            open={props.status===props.dialogType}
            title={props.title}
            onPrimary={props.onSubmit}
            onClose={{onClick: props.onClose}}
            content={(
                <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={12}>{props.message}</Grid>
                </Grid>
            )}
        />
    );
}

export default Prompt;

import React, {useState, useEffect, useRef, MutableRefObject, useCallback, Dispatch, SetStateAction} from "react";
import {Box, LinearProgress} from "@mui/material";
import {
    AddOutlined,
    AssignmentOutlined,
    DocumentScannerOutlined,
    EngineeringOutlined,
    QueryStatsOutlined,
    SpeedOutlined,
    SummarizeOutlined,
} from "@mui/icons-material";
import _ from "lodash";
import moment from "moment";
import {FormProvider, useForm, UseFormReturn, UseFieldArrayReturn, useFieldArray, FieldErrors, FieldValues} from "react-hook-form";
import {enqueueSnackbar} from "notistack";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {AxiosResponse} from "axios";
import {ParentProps, ViewMode} from "../types";
import {
    DOCUMENT_LABEL_MAP,
    DOCUMENT_TYPE_LABEL_MAP,
    FAMILIARITY_LABEL_MAP,
    METERINFO_SOURCE_LABEL_MAP,
    METERINFO_TYPE_LABEL_MAP,
    PROPERTY_DOCUMENT_ROUTE,
    PROPERTY_ID_ROUTE,
    PROPERTY_METERINFO_ROUTE,
    SCHEDULE_TIME_FORMAT,
    FORM_REQUIRED_SNACK_MESSAGE,
    MAX_METERINFO_LENGTH,
    MAX_DOCUMENTS_LENGTH,
} from "../config";
// components
import WithMenu, {MenuItem, resolveActions, scrollTo} from "../components/layout/WithMenu";
import WithTable from "../components/layout/WithTable";
import {Field} from "../components/generics/inputs";
import {
    cleanValue,
    alterEditableFieldsViewMode,
    mutateSwitchDependency,
    constructPropertyPatchConfigs,
    initPropertyForm,
    initDoc,
    initMeter,
    resolve24HourFacility,
    resolveSameDailySchedule,
    mutateSwitchLogic,
} from "../handlers";
import {
    PropertyDetails,
    PropertyDetailsFields,
    OperatorDetails,
    OperatorDetailsFields,
    SCHEDULE_KEYS,
    SCHEDULE_SWITCH_KEYS,
    OVERRIDE_KEYS,
    EnertracDetails,
    EnertracDetailsFields,
    PropertyDocuments,
    PropertyDocumentsFields,
    RealTimeMeters,
    RealTimeMetersFields,
} from "../components/forms";
import EmptyStateBlock from "../components/generics/EmptyStateBlock";
import ChangeLog from "../components/layout/ChangeLog";
import HelpPopover from "../components/generics/HelpPopover";
import {useContext} from "../components/generics/Context";
import {useCall, BaseState} from "../components/generics/Call";
import Backdrop from "../components/generics/Backdrop";

export interface PropertyFields{
    [index:string]:any
    propertyDetails:Field[]
    operatorDetails:Field[]
    enertracDetails:Field[]
    propertyDocuments:Field[]
    realTimeMeters:Field[]
}

interface Props extends ParentProps{
    // NOTE: Empty For future expansion.
}

export interface State extends Omit<BaseState, "dialog"|"record">{
    viewMode:ViewMode
    documents:AxiosResponse|null
    meterinfo:AxiosResponse|null
    parentProperty:AxiosResponse|null
}

/**
 * Property
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Property(props:Props):React.ReactElement {
    const context:any=useContext();
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        viewMode: "VIEW_MODE",
        documents: null,
        meterinfo: null,
        parentProperty: null,
        formValues: null,
    });
    const [formFields, setFormFields]:[PropertyFields, Dispatch<SetStateAction<PropertyFields>>]=useState<PropertyFields>({
        propertyDetails: PropertyDetailsFields,
        operatorDetails: OperatorDetailsFields,
        enertracDetails: EnertracDetailsFields,
        propertyDocuments: PropertyDocumentsFields,
        realTimeMeters: RealTimeMetersFields,
    });
    const {get, call}=useCall({state, setState: setState as any});

    const {current: fields}:MutableRefObject<PropertyFields>=useRef(formFields);
    const is24HourFacility:boolean=resolve24HourFacility(props.property.data);
    const isSameDailySchedule:boolean=(resolve24HourFacility(props.property.data)!==true && resolveSameDailySchedule(props.property.data));

    /**
     * resolveYupObj
     * @param {Field[]} f
     * @return {any}
     */
    const resolveYupObj=(f:any, config?:any):any => yup
        .array()
        .of(yup.object().shape(f.reduce((a:any, v:any) => ({...a, [v.key]: v.yup}), {})))
        .test({...config, test: config?.test||(() => true)} as any);

    // construct yup object
    const yupObject:any={};
    Object.keys(fields).forEach((key:string) => fields[key].forEach((field:Field) => {
        if (key==="propertyDocuments") yupObject.documents=resolveYupObj(PropertyDocumentsFields);
        else if (key==="realTimeMeters") {
            yupObject.meterinfo=resolveYupObj(
                RealTimeMetersFields,
                {
                    test: ((v:any, c:yup.TestContext) => {
                        const errors=v
                            .map((i:any, index:number) => ((v.filter((r:any) => r.meter_id===i.meter_id).length>1)
                                ?new yup.ValidationError("duplicate ID", "", `meterinfo.${index}.meter_id`)
                                :null
                            ))
                            .filter(Boolean);
                        if (errors.length===0) return true;
                        return c.createError({message: () => errors});
                    }),
                },
            );
        } else yupObject[field.key] = field.yup;
    }));

    const formReturn:UseFormReturn=useForm({
        mode: "onSubmit",
        resolver: yupResolver(yup.object(yupObject)),
    });

    const documentsArray:UseFieldArrayReturn=useFieldArray({
        control: formReturn.control,
        name: "documents",
    });

    const meterinfoArray:UseFieldArrayReturn=useFieldArray({
        control: formReturn.control,
        name: "meterinfo",
    });

    /**
     * onSuccess
     * @param {AxiosResponse[]} res;
     * @return {void}
     */
    const onSuccess=useCallback((res:AxiosResponse[]):void => {
        // trigger snack msg
        enqueueSnackbar(`${props.property.data.name} property edits saved`, {variant: "success"});
        // trigger refresh cycle
        formReturn.reset(initPropertyForm());
        const stateClone=_.cloneDeep(state);
        stateClone.formValues=null;
        stateClone.documents=null;
        stateClone.meterinfo=null;
        stateClone.viewMode="VIEW_MODE";
        setState(stateClone);
        context.setViewState("REFRESH");
    }, [context, props.property.data.name, formReturn, setState, state]);

    /**
     * onSubmit
     * @param {FieldValues} values
     */
    const onSubmit=async (values:FieldValues):Promise<void> => {
        try {
            // prepare configs and call
            call(constructPropertyPatchConfigs(values, props.property, state.meterinfo, state.documents), values, onSuccess, "edit");
        } catch (e) {
            console.log(e);
        }
    };

    // 412 handling
    useEffect(() => {
        if (context.viewState==="PENDING_END" && state.documents && state.meterinfo && (state.formValues && "edit" in state.formValues)) {
            call(constructPropertyPatchConfigs(state.formValues.edit, props.property, state.meterinfo, state.documents), state.formValues.edit, onSuccess, "edit");
        }
    }, [context.viewState, call, formReturn, state.documents, state.meterinfo, props.property, state.formValues, onSuccess]);

    // GET documents, meterinfo data and parent property data if exists;
    useEffect(() => {
        if (!["NONE", "PENDING_BEGIN"].includes(context.viewState)) return;
        if (state.documents===null) get(`${PROPERTY_DOCUMENT_ROUTE.replace(/{id}/g, props.property.data.id)}`, "documents", true);
        if (state.meterinfo===null) get(`${PROPERTY_METERINFO_ROUTE.replace(/{id}/g, props.property.data.id)}`, "meterinfo", true);
        if (props.property.data.parent_property_id && state.parentProperty===null) get(`${PROPERTY_ID_ROUTE.replace(/{id}/g, props.property.data.parent_property_id)}`, "parentProperty");
    }, [state.documents, state.meterinfo, state.parentProperty, props.property.data.id, get, context.viewState, props.property.data.parent_property_id]);

    // alter editable fields via viewMode
    useEffect(() => {
        let newFields:PropertyFields=alterEditableFieldsViewMode(fields, state.viewMode);
        if (state.viewMode==="EDIT_MODE") {
            if (is24HourFacility) newFields=mutateSwitchLogic(is24HourFacility, "is24HourFacility", newFields);
            else newFields=mutateSwitchLogic(isSameDailySchedule, "isSameDailySchedule", newFields);
        }
        setFormFields(newFields);
    }, [state.viewMode, fields, props.property.data, is24HourFacility, isSameDailySchedule]);

    // populate form values
    useEffect(() => {
        const values={
            // Property route
            ...PropertyDetailsFields.reduce(
                (a:any, v:any) => {
                    let cv:any=cleanValue(props.property.data[v.key]);
                    if (SCHEDULE_KEYS.includes(v.key) && cv) cv=moment(props.property.data[v.key], SCHEDULE_TIME_FORMAT).toDate();
                    else if (v.key==="parent_property_name") cv=cleanValue(state.parentProperty?.data?.name);
                    else if (v.key==="is24HourFacility") cv=is24HourFacility;
                    else if (v.key==="isSameDailySchedule") cv=isSameDailySchedule;
                    else if (v.key==="building_size_sqft") cv=cv?.toLocaleString()||"";

                    return ({
                        ...a,
                        [v.key]: cv,
                    });
                },
                {},
            ),
            ...OperatorDetailsFields.reduce((a:any, v:any) => ({...a, [v.key]: cleanValue(props.property.data[v.key])}), {}),
            ...EnertracDetailsFields.reduce(
                (a:any, v:any) => ({
                    ...a,
                    [v.key]: v.key==="familiarity"
                        ?cleanValue(FAMILIARITY_LABEL_MAP.find((i:any) => i.key===props.property.data[v.key])?.label)
                        :cleanValue(props.property.data[v.key]),
                }),
                {},
            ),
            // document route
            ...(state.documents && Array.isArray(state.documents?.data.results) && (
                {documents: [...state.documents.data.results.map((item:any, index:number) => (initDoc(item)))]}
            )),
            // meterinfo route
            ...(state.meterinfo && Array.isArray(state.meterinfo?.data.results) && (
                {meterinfo: [...state.meterinfo.data.results.map((item:any, index:number) => (initMeter(item)))]}
            )),
        };
        if (context.viewState==="NONE") formReturn.reset(values);
    }, [formReturn, props.property.data, state.viewMode, state.documents, state.meterinfo, context.viewState, state.parentProperty?.data?.name, is24HourFacility, isSameDailySchedule]);

    useEffect(() => {
        const subscription = formReturn.watch(_.debounce((values:any, {name, type}:any) => {
            if (SCHEDULE_SWITCH_KEYS.includes(name) || OVERRIDE_KEYS.includes(name)) {
                let newFormFields:PropertyFields=_.cloneDeep(formFields);
                newFormFields=mutateSwitchDependency(values, name, newFormFields, formReturn.reset);
                setFormFields(newFormFields);
            } else if (name && name.match(/documents.\d+.label/)!==null) {
                // NOTE: block triggered only using regex document.0.label emaple
                // resolving Document type label
                const [root, index, sub]=name.split(".");
                const doc=DOCUMENT_LABEL_MAP.find((i:any) => i.label===values.documents[index].label);
                formReturn.setValue(`${root}.${index}.type`, DOCUMENT_TYPE_LABEL_MAP.find((i:any) => i.key===doc?.type)?.label);
            }
        }, 0));
        return () => subscription.unsubscribe();
    }, [formReturn, setState, formFields]);

    const formReturnValues=formReturn.getValues();

    // menu content
    const menu:MenuItem[]=[
        {
            key: "property-details",
            label: "Property Details",
            header: {
                actions: resolveActions(
                    state.viewMode,
                    undefined,
                    {
                        edit: {onClick: (args:React.MouseEvent) => setState({..._.cloneDeep(state), viewMode: "EDIT_MODE"})},
                        discard: {onClick: (args:React.MouseEvent) => setState({..._.cloneDeep(state), viewMode: "VIEW_MODE"})},
                        update: {
                            onClick: onSubmit,
                            onError: (args:FieldErrors) => enqueueSnackbar(FORM_REQUIRED_SNACK_MESSAGE, {variant: "error"}),
                        },
                    },
                ),
                primaryLabel: props.property.data.name,
                secondaryLabel: props.property.data.recordtype,
                viewMode: state.viewMode,
                rootSticky: true,
            },
            subHeader: {
                label: "Property Details",
                tip: <HelpPopover
                    content={(
                        <Box sx={{maxWidth: "300px"}}>
                            Greyed out fields in this section show property data from the DEM Project Tracking and Performance Management System. These fields cannot be edited. If you think this information is incorrect, please contact us.
                        </Box>
                    )}
                />,
            },
            icon: SummarizeOutlined,
            content: <PropertyDetails fields={formFields.propertyDetails} parentProperty={state.parentProperty} recordtype={props.property.data.recordtype} viewMode={state.viewMode} />,
        },
        {
            key: "property-documents",
            label: "Property Documents",
            icon: AssignmentOutlined,
            subHeader: {
                label: "Property Documents",
                actions: [{
                    key: "add-document",
                    label: "ADD DOCUMENT",
                    startIcon: <AddOutlined />,
                    onClick: (args:React.MouseEvent) => {
                        documentsArray.append(initDoc());
                        scrollTo("property-documents", "bottom");
                        // if documents length exceeds limit, trigger snack
                        if (formReturn.getValues().documents?.length>=MAX_DOCUMENTS_LENGTH) enqueueSnackbar(`Document limit of ${MAX_DOCUMENTS_LENGTH} reached.`, {variant: "info"});
                    },
                    type: "button",
                    variant: "contained",
                    disabled: state.viewMode==="VIEW_MODE" || formReturnValues.documents?.length>=MAX_DOCUMENTS_LENGTH,
                }],
            },
            content: <WithTable
                fieldArrayReturn={documentsArray}
                fields={formFields.propertyDocuments}
                response={["LOADING", "PENDING_END", "PENDING_BEGIN"].includes(context.viewState)?null:state.documents}
                form={{
                    component: PropertyDocuments,
                    key: "documents",
                    setter: initDoc,
                    props: {documents: DOCUMENT_LABEL_MAP.map((i:any) => i.label), viewMode: state.viewMode},
                }}
                noRowsOverlay={(
                    <EmptyStateBlock
                        header="No documents for this property."
                        subHeaders={["To add any relevant property or course related documents, first click EDIT."]}
                        icon={DocumentScannerOutlined}
                    />
                )}
            />,
            noBreak: true,
        },
        {
            key: "operator-details",
            label: "Operator Details",
            icon: EngineeringOutlined,
            subHeader: {label: "Operator Details"},
            content: <OperatorDetails fields={formFields.operatorDetails} />,
        },
        {
            key: "enertrac-details",
            label: "Enertrac Details",
            icon: QueryStatsOutlined,
            subHeader: {label: "Enertrac Details"},
            content: <EnertracDetails fields={formFields.enertracDetails} familiarity={FAMILIARITY_LABEL_MAP.map((i:any) => i.label)} />,
        },
        {
            key: "real-time-meters",
            label: "Real-time Meters",
            icon: SpeedOutlined,
            subHeader: {
                label: "Real-time Meters",
                actions: [{
                    key: "add-meter",
                    label: "ADD REAL-TIME METER",
                    startIcon: <AddOutlined />,
                    onClick: (args:React.MouseEvent) => {
                        meterinfoArray.append(initMeter());
                        scrollTo("real-time-meters", "bottom");
                        // if meterinfo length exceeds limit, trigger snack
                        if (formReturn.getValues().meterinfo?.length>=MAX_METERINFO_LENGTH) enqueueSnackbar(`Real-time meter limit of ${MAX_METERINFO_LENGTH} reached.`, {variant: "info"});
                    },
                    type: "button",
                    variant: "contained",
                    disabled: state.viewMode==="VIEW_MODE" || formReturnValues.meterinfo?.length>=MAX_METERINFO_LENGTH,
                }],
            },
            content: <WithTable
                fieldArrayReturn={meterinfoArray}
                fields={formFields.realTimeMeters}
                response={["LOADING", "PENDING_END", "PENDING_BEGIN"].includes(context.viewState)?null:state.meterinfo}
                form={{
                    component: RealTimeMeters,
                    key: "meterinfo",
                    setter: initMeter,
                    props: {
                        types: METERINFO_TYPE_LABEL_MAP.map((i:any) => i.label),
                        sources: METERINFO_SOURCE_LABEL_MAP.map((i:any) => i.label),
                        viewMode: state.viewMode,
                    },
                }}
                noRowsOverlay={(
                    <EmptyStateBlock
                        header="No real-time meters for this property."
                        subHeaders={["To add real-time meters, first click EDIT."]}
                        icon={SpeedOutlined}
                    />
                )}
            />,
            noBreak: true,
        },
    ];

    if (state.documents===null || state.meterinfo===null || (props.property.data.parent_property_id && state.parentProperty===null) || formReturn.formState.defaultValues===undefined) return <LinearProgress />;

    return (
        <Box>
            {/* lock */}
            <Backdrop open={!["NONE"].includes(context.viewState)} />
            {/* content */}
            <FormProvider {...formReturn}>
                <WithMenu
                    menu={menu}
                    log={<ChangeLog record={props.property} resource="property" />}
                />
            </FormProvider>
        </Box>
    );
}

export default Property;

import {AxiosResponse, AxiosRequestConfig} from "axios";
import {FieldValues} from "react-hook-form";
import _ from "lodash";
import {Field} from "../components/generics/inputs";
import {POINT_BOOLEANS, OPERATION_TIMES, EQUIPMENT_BASE} from "../config";
import {resolveLabels} from ".";
import {Component, EquipmentType, VariantType} from "../types";

/**
 * initPoint
 * @param {string} key
 * @param {any} item
 * @return {any}
 */
const initPoint=(key:string, item?:any):any => {
    if (item) {
        return ({
            key,
            tag_id: item.tag_id,
            point_booleans: POINT_BOOLEANS.filter((i:any) => item[i.key]===true).map((i:any) => i.label),
            notes: item.notes,
        });
    }
    return {key, tag_id: undefined, point_booleans: [], notes: undefined};
};

/**
 * resolvePoints
 * @param {any} items
 * @return {any}
 */
const resolvePoints=(items:any):any => Object.keys(items).map((key:string) => (initPoint(key, items[key])));

/**
 * transformPoints
 * @param {any} points
 * @return {any}
 */
const transformPoints=(points:any):any => {
    const result:any={};
    points.forEach((i:any) => {
        result[i.key]={
            tag_id: i.tag_id,
            notes: i.notes,
            ...POINT_BOOLEANS.reduce((a:any, v:any) => ({...a, [v.key]: i.point_booleans.includes(v.label)}), {}),
        };
    });
    return result;
};

/**
 * initSystemForm
 * @param {Field[]} fields
 * @param {any} values
 * @return {any}
 */
const initSystemForm=(fields:Field[], values?:any):any => ({
    ...fields.reduce((a:any, v:any) => ({...a, [v.key]: values?values[v.key]:undefined}), {}),
});

/**
 * initComponentForm
 * @param {Field[]} fields
 * @param {any} values
 * @return {any}
 */
const initComponentForm=(fields:Field[], values?:any):any => ({
    ...fields.reduce((a:any, v:any) => ({...a, [v.key]: values?values[v.key]:undefined}), {}),
});

/**
 * resolveComponentPayload
 * @param {any} values
 * @param {AxiosResponse} bases
 * @return {any}
 */
const resolveComponentPayload=(values:any, bases:AxiosResponse):any => {
    const KEYS=[
        {key: "bas_version", labels: undefined},
        {key: "operation_times", labels: OPERATION_TIMES},
    ];

    // remove UI keys
    const payload:any=_.omit(values, KEYS.map((i:any) => i.key));
    // resolve key/id => label(s)
    payload.bas_id=bases.data.results.find((r:any) => r.software===values.bas_version.primary && r.version===values.bas_version.secondary)?.id;
    KEYS.slice(1).forEach((i:any) => resolveLabels(values[i.key], payload, i.labels));
    payload.points=transformPoints(values.points);

    return payload;
};

/**
 * constructSystemPostConfig
 * @param {FieldValues|null} values
 * @param {AxiosResponse} property
 * @param {AxiosResponse} bases
 * @param {EquipmentType} equipment
 * @return {AxiosRequestConfig|undefined}
 */
const constructSystemPostConfig=(values:FieldValues|null, property:AxiosResponse, bases:AxiosResponse, equipment:EquipmentType):AxiosRequestConfig|undefined => {
    if (values===null) return undefined;

    const payload=equipment.resolveValues.toApi(values, bases);

    return {
        method: "post",
        url: `${EQUIPMENT_BASE}${equipment.category}/${equipment.key}/`,
        data: {
            ...payload,
            property_id: property.data.id,
            ...equipment.variant && {variant: equipment.variant},
        },
    };
};

/**
 * constructSystemPatchConfig
 * @param {FieldValues|null} values
 * @param {AxiosResponse} property
 * @param {AxiosResponse} bases
 * @param {EquipmentType} equipment
 * @param {AxiosResponse} system
 * @return {AxiosRequestConfig|undefined}
 */
const constructSystemPatchConfig=(values:FieldValues|null, property:AxiosResponse, bases:AxiosResponse, equipment:EquipmentType, system:AxiosResponse):AxiosRequestConfig|undefined => {
    if (values===null) return undefined;

    const payload=equipment.resolveValues.toApi(values, bases);

    return {
        method: "patch",
        url: `${EQUIPMENT_BASE}${equipment.category}/${equipment.key}/${system.data.id}`,
        data: {
            ...payload,
            property_id: property.data.id,
            ...equipment.variant && {variant: equipment.variant},
        },
        headers: {"If-Match": system.data.etag_version}, // eslint-disable-line camelcase
    };
};

/**
 * constructSystemArchiveConfig
 * @param {FieldValues|null} values
 * @param {AxiosResponse} property
 * @param {EquipmentType} equipment
 * @param {AxiosResponse} system
 * @param {boolean} archived
 * @return {AxiosRequestConfig|undefined}
 */
const constructSystemArchiveConfig=(values:FieldValues|null, property:AxiosResponse, equipment:EquipmentType, system:AxiosResponse, archived:boolean):AxiosRequestConfig|undefined => {
    if (values===null && archived) return undefined;

    return {
        method: "patch",
        url: `${EQUIPMENT_BASE}${equipment.category}/${equipment.key}/${system.data.id}`,
        data: {
            property_id: property.data.id,
            archived_reason: archived?values?.archived_reason:"",
            archived,
            name: system.data.name,
            tag_id: system.data.tag_id,
        },
        headers: {"If-Match": system.data.etag_version}, // eslint-disable-line camelcase
    };
};

/**
 * constructSystemDeleteConfig
 * @param {AxiosResponse} system
 * @param {EquipmentType} equipment
 * @return {AxiosRequestConfig|undefined}
 */
const constructSystemDeleteConfig=(system:AxiosResponse, equipment:EquipmentType):AxiosRequestConfig|undefined => ({
    method: "delete",
    url: `${EQUIPMENT_BASE}${equipment.category}/${equipment.key}/${system.data.id}`,
    headers: {"If-Match": system.data.etag_version}, // eslint-disable-line camelcase
});

/**
 * constructComponentPostConfig
 * @param {FieldValues|null} values
 * @param {AxiosResponse} bases
 * @param {AxiosResponse} system
 * @param {EquipmentType} equipment
 * @param {Component} componentType
 * @return {AxiosRequestConfig|undefined}
 */
const constructComponentPostConfig=(values:FieldValues|null, bases:AxiosResponse, system:AxiosResponse, equipment:EquipmentType, componentType:Component):AxiosRequestConfig|undefined => {
    if (values===null) return undefined;

    const payload=resolveComponentPayload(values, bases);

    return {
        method: "post",
        url: `${EQUIPMENT_BASE}${equipment.category}/${componentType?.key}/`,
        data: {
            ...payload,
            ...{[`${equipment.key}_id`]: system.data.id},
            ...componentType.variant && {variant: (componentType.variant as VariantType).key},
        },
    };
};

/**
 * constructComponentPatchConfig
 * @param {FieldValues|null} values
 * @param {AxiosResponse} bases
 * @param {AxiosResponse} system
 * @param {AxiosResponse} component
 * @param {EquipmentType} equipment
 * @param {Component} componentType
 * @return {AxiosRequestConfig|undefined}
 */
const constructComponentPatchConfig=(values:FieldValues|null, bases:AxiosResponse, system:AxiosResponse, component:AxiosResponse, equipment:EquipmentType, componentType:Component):AxiosRequestConfig|undefined => {
    if (values===null) return undefined;

    const payload=resolveComponentPayload(values, bases);

    return {
        method: "patch",
        url: `${EQUIPMENT_BASE}${equipment.category}/${componentType?.key}/${component.data.id}`,
        data: {
            ...payload,
            ...{[`${equipment.key}_id`]: system.data.id},
            ...componentType.variant && {variant: (componentType.variant as VariantType).key},
        },
        headers: {"If-Match": component.data.etag_version}, // eslint-disable-line camelcase
    };
};

/**
 * constructComponentArchiveConfig
 * @param {FieldValues|null} values
 * @param {AxiosResponse} system
 * @param {AxiosResponse} component
 * @param {EquipmentType} equipment
 * @param {Component} componentType
 * @param {boolean} archived
 * @return {AxiosRequestConfig|undefined}
 */
const constructComponentArchiveConfig=(values:FieldValues|null, system:AxiosResponse, component:AxiosResponse, equipment:EquipmentType, componentType:Component, archived:boolean):AxiosRequestConfig|undefined => {
    if (values===null && archived) return undefined;

    return {
        method: "patch",
        url: `${EQUIPMENT_BASE}${equipment.category}/${componentType?.key}/${component.data.id}`,
        data: {
            archived_reason: archived?values?.archived_reason:"",
            archived,
            ...{[`${equipment.key}_id`]: system.data.id},
            name: component.data.name,
            tag_id: component.data.tag_id,
        },
        headers: {"If-Match": component.data.etag_version}, // eslint-disable-line camelcase
    };
};

/**
 * constructComponentDeleteConfig
 * @param {AxiosResponse} component
 * @param {EquipmentType} equipment
 * @param {Component} componentType
 * @return {AxiosRequestConfig|undefined}
 */
const constructComponentDeleteConfig=(component:AxiosResponse, equipment:EquipmentType, componentType:Component):AxiosRequestConfig|undefined => ({
    method: "delete",
    url: `${EQUIPMENT_BASE}${equipment.category}/${componentType?.key}/${component.data.id}`,
    headers: {"If-Match": component.data.etag_version}, // eslint-disable-line camelcase
});

export {
    constructSystemPostConfig,
    constructSystemPatchConfig,
    constructSystemArchiveConfig,
    constructSystemDeleteConfig,
    initSystemForm,
    constructComponentPostConfig,
    constructComponentPatchConfig,
    constructComponentArchiveConfig,
    constructComponentDeleteConfig,
    initComponentForm,
    initPoint,
    resolvePoints,
    transformPoints,
};

import React, {useEffect, useState, useCallback, useRef, Dispatch, SetStateAction} from "react";
import {DataGrid as DataGridBase, GridPaginationModel} from "@mui/x-data-grid";
import {Box, Grid, Fade} from "@mui/material";
import {AxiosError, AxiosResponse} from "axios";
import {Auth0ContextInterface, User, useAuth0} from "@auth0/auth0-react";
import _ from "lodash";
import api from "../../api";
import {BASE_ROUTE, CALL_DELAY, STATUS_400_RANGE, STATUS_500_RANGE} from "../../config";
import {ActionContainer, Placeholder, Toolbar, DataGridProps, NoRowsOverlay} from "./DataGridUtils";
import Alert from "./Alert";
import {useContext} from "./Context";
import {slackCall} from "./Call";

/**
 * DataGrid
 * @return {React.ReactElement}
 */
function DataGrid(props:DataGridProps):React.ReactElement {
    const context:any=useContext();
    const [response, setResponse]:any=useState<AxiosError|AxiosResponse|null>(null);
    const [rows, setRows]:any[]=useState([]);
    const [url, setUrl]:[string|undefined, Dispatch<SetStateAction<string|undefined>>]=useState(props?.url);
    const [row, setRow]:any=useState(null);
    const [isLoading, setIsloading]=useState<boolean>(false);
    const [count, setCount]=useState<number>(0);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({pageSize: 25, page: 0});
    const [actionAnchor, setActionAnchor]:[HTMLElement|null, Dispatch<SetStateAction<HTMLElement|null>>]=useState<null | HTMLElement>(null);
    const actionRef:React.MutableRefObject<any>=useRef();
    const {user}:Auth0ContextInterface<User>= useAuth0();

    /**
     * onRowsPerPageChange
     * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} args
     * @return {void}
     */
    const onRowsPerPageChange=(args: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void => {
        const obj={pageSize: parseInt(args.target.value, 10), page: 0};
        setPaginationModel(obj);
    };

    /**
     * onPageChange
     * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} args
     * @return {void}
     */
    const onPageChange=(args: React.MouseEvent<HTMLButtonElement> | null, newPage: number):void => {
        const obj={...paginationModel};
        obj.page=newPage;
        setPaginationModel(obj);
    };

    /**
     * onActionOpen
     * @param {React.MouseEvent<any>} args
     * @return {void}
     */
    const onActionOpen=(args: React.MouseEvent<any>):void => {
        const id = Number.parseInt(args.currentTarget.dataset.id, 10);
        // resolve selection
        let selection=null;
        // resolve internal row id
        const rowId=props.rowId||"id";
        if (Array.isArray(props?.rows?.data?.results)) selection=props?.rows?.data?.results?.find((r:any) => r[rowId]===id);
        else if (Array.isArray(rows)) selection=rows?.find((r:any) => r[rowId]===id);
        // update row
        setRow(selection);
        // update anchor
        setActionAnchor(args.currentTarget);
    };

    /**
     * onActionClose
     * @param {React.MouseEvent<any>} args
     * @return {void}
     */
    const onActionClose=(args: React.MouseEvent<any>):void => {
        if (actionAnchor==null || actionRef.current.contains(args.nativeEvent.relatedTarget)) return;
        setActionAnchor(null);
    };

    /**
     * onShowArchived
     * @param {React.MouseEvent<any>} args
     * @return {void}
     */
    const onShowArchived=(args:React.MouseEvent):void => {
        if (props.setState) props.setState({..._.cloneDeep(props?.state), showArchived: !props?.state?.showArchived});
    };

    /**
     * toolbar
     */
    const toolbar=():React.ReactElement => (
        <Toolbar
            paginationModel={paginationModel}
            onShowArchived={onShowArchived}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            count={count}
            state={props.state}
            setState={props.setState}
            isLoading={isLoading}
            {...props}
        />
    );

    /**
     * <Fade/> wrapper for noRowsOverlay
     */
    const noRowsOverlayFade=():React.ReactElement => (
        <Fade timeout={1000} in>
            {/* Center the parent div */}
            <div style={{height: "100%"}}>
                {props.noRowsOverlay}
            </div>
        </Fade>
    );

    /**
     * getData
     * @param {string} path
     */
    const getData=useCallback(async (path:string):Promise<void> => {
        /**
         * helper
         * @param {string} _path
         * @return {Promise<void>}
         */
        const helper=async (_path:string):Promise<void> => {
            await api.get(`${_path}`)
                .then((res:AxiosResponse) => {
                    setResponse(res);
                    // format data via transformData function if passed
                    let payload:AxiosResponse=res;
                    if (props.transformData) payload=props.transformData(payload, props.columns);
                    setRows(rows.concat(payload.data.results));
                    setRow(null);
                    setCount(payload.data.total_count);
                })
                .catch(async (err:AxiosError) => {
                    if (err.response && ((err.response.status in STATUS_500_RANGE) || (STATUS_400_RANGE.includes(err.response.status)))) await slackCall(err.response as AxiosResponse, user as User);
                    setResponse(err?.response);
                    setRows([]);
                    setRow(null);
                    setCount(0);
                });
            setIsloading(false);
            // reseting "actionAnchor" as it NOT reseting will result in null life-cycle while rendering actions!
            setActionAnchor(null);
        };
        _.delay(helper, CALL_DELAY, path);
    }, [rows, props, user]);

    // resolve url
    useEffect(() => {
        // default response, rows, row, count when url changes
        // this will trigger re-fetch on next life cycle
        if (props.url!==undefined && props.url!==url) {
            setResponse(null);
            setRows([]);
            setRow(null);
            setCount(0);
            setUrl(props.url);
            setPaginationModel({...paginationModel, page: 0}); // set page to 0 after changing a filter.
        }
        // fetch first payload via url
        if (context.token && url && response===null) getData(url);
        // resolve fetching net payload via next_url
        if (((rows.length/paginationModel.pageSize)===(paginationModel.page+1)) && response?.data?.next_url) {
            setIsloading(true);
            getData(`${BASE_ROUTE}${response.data.next_url}`);
        }
    }, [context.token, response, getData, props.url, url, rows.length, paginationModel]);

    // error block
    if ((props?.rows?.status!==undefined && props.rows?.status!==200) || ((response && response?.status!==200) || context.isError===true)) {
        return <Alert severity="error" title="Ops. Fetch Error" body="Unable to resolve data." />;
    }

    // Placeholder block
    if (props.rows===null || (props.rows===undefined && response===null)) return <Placeholder />;

    // no base rows block
    if (
        (props.isQuery !== true || props.rows !== undefined)
        && props.baseNoRowsOverlay
        && (props?.rows?.data.results.length === 0 || (props.rows === undefined && rows.length === 0))
    ) {
        return (
            <Fade in timeout={1000}>
                <Box sx={{marginTop: 8}}>{props.baseNoRowsOverlay}</Box>
            </Fade>
        );
    }

    // resolve incomming rows source (props or inturnally)
    // resolve transformData props
    let transformedRows=rows;
    if (props.rows && Array.isArray(props.rows.data.results)) {
        transformedRows=props.transformData
            ?props.transformData(props.rows, props.columns).data.results
            :props.rows?.data.results;
    }
    // height is changed based on rows size due to NoRowsOverlay styling
    return (
        <Box sx={{height: (props.rows===undefined && rows.length===0) || (transformedRows.length===0)?"500px":"100%", width: 1}}>
            {/* search filter + primary action */}
            {(props.search || props.primaryAction) && (
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" sx={{marginBottom: "24px"}}>
                    {props.search?<Grid item xs={3}>{props.search}</Grid>:<Grid item xs={3} />}
                    {props.primaryAction && <Grid item>{props.primaryAction}</Grid>}
                </Grid>
            )}
            {/* Grid */}
            <DataGridBase
                initialState={{
                    pagination: {paginationModel: {pageSize: 1}},
                }}
                rows={transformedRows}
                disableRowSelectionOnClick
                columns={props.columns}
                disableColumnSelector
                disableDensitySelector
                disableColumnFilter
                slots={{
                    toolbar,
                    noRowsOverlay: ((props.rows!==undefined || props.isQuery===true) && props.noRowsOverlay)? (() => noRowsOverlayFade()):NoRowsOverlay,
                }}
                slotProps={{
                    row: {
                        ...((props?.state?.filterStatus===false || props?.state?.filterStatus===undefined) && {onMouseEnter: onActionOpen}),
                        ...((props?.state?.filterStatus===false || props?.state?.filterStatus===undefined) && {onMouseLeave: onActionClose}),
                    },
                    baseIconButton: {sx: {color: "#FFFFFF"}},
                }}
                hideFooter
                loading={props.rows!==undefined?false:isLoading}
                paginationModel={paginationModel}
                getRowId={props.getRowId}
                sx={{
                    ".MuiDataGrid-columnSeparator": {
                        color: "#FFFFFF",
                    },
                    ".MuiDataGrid-columnHeaders": {
                        backgroundColor: "#5B6260",
                        color: "#FFFFFF",
                        borderRadius: "0px",
                    },
                    // this is intended to keep row highlighted while action is "hover"ed on!
                    "& .super-app-theme--override": {
                        backgroundColor: "#0000000a",
                    },
                    // this is intended to remove border outline on cell focus
                    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        maxHeight: "50vh",
                    },
                }}
                getRowClassName={(params:any) => {
                    if (actionAnchor?.getAttribute("data-id")===params.id.toString()) return "super-app-theme--override";
                    return "";
                }}
            />
            {/* actions */}
            {props.actions && actionAnchor!==null && (
                <ActionContainer
                    anchor={actionAnchor}
                    setAnchor={setActionAnchor}
                    ref={actionRef}
                    actions={props.actions}
                    row={row}
                />
            )}
        </Box>
    );
}

DataGrid.defaultProps={
    rows: undefined,
    url: undefined,
    getRowId: undefined,
    filter: undefined,
    filterFields: undefined,
    onFilterFieldsChange: undefined,
    actions: undefined,
    setShowArchived: undefined,
    showArchived: undefined,
    transformData: undefined,
    isQuery: undefined,
    baseNoRowsOverlay: undefined,
    noRowsOverlay: undefined,
};

export default DataGrid;

import React, {useState, useCallback, useEffect} from "react";
import {Theme, ThemeProvider, createTheme} from "@mui/material/styles";
import {useAuth0, Auth0ContextInterface, User} from "@auth0/auth0-react";
import CircularProgress from "@mui/material/CircularProgress";
import {Grid} from "@mui/material";
import {Routes as RoutesSwitch, Route} from "react-router-dom";
import {SnackbarProvider, enqueueSnackbar} from "notistack";
import api from "../api";
import useServerErrorHandler from "../handlers/useServerErrorHandler";
import {APP_PATHS} from "../config";
// components
import MenuBar from "../components/layout/MenuBar";
import ProtectedRoute from "../components/generics/ProtectedRoute";
import WithTabs from "../components/layout/WithTabs";
// views
import Http404 from "./Http404";
import SplashView from "./SplashView";
import Properties from "./Properties";
import Property from "./Property";
import Bas from "./Bas";
import BasDetails from "./BasDetails";
import Equipment from "./Equipment";
import EquipmentDetails from "./EquipmentDetails";
import Campus from "./Campus";
import CampusProperties from "./CampusProperties";
import Docs from "./Docs";
import Context from "../components/generics/Context";

const theme:Theme=createTheme({
    // Note: Theme color naming is inverted from Figma (Primary ↔ Secondary)
    palette: {
        primary: {
            main: "#3C8131",
        },
        secondary: {
            main: "#333B39",
        },
    },
});

/**
 * Routes
 * @return {React.ReactElement}
 */
function Routes():React.ReactElement {
    const {isLoading, isAuthenticated, getAccessTokenSilently, logout}:Auth0ContextInterface<User>= useAuth0();
    const [token, setToken]:any=useState(null);
    const {message, isError, setIsError} = useServerErrorHandler();

    /**
     * resolveToken
     * @return {void}
     */
    const resolveToken=useCallback(
        async ():Promise<void> => {
            if (!isAuthenticated) return;
            try {
                const value=await getAccessTokenSilently({cacheMode: "off"});
                setToken(value);
                api.defaults.headers.common.Authorization=`Bearer ${value}`;
                api.defaults.headers.common["Content-Type"]="application/json";
            } catch (e:any) {
                console.log(e);
                logout({logoutParams: {returnTo: `${window.location.origin}/?signout=true`}});
            }
        },
        [setToken, getAccessTokenSilently, isAuthenticated, logout],
    );

    // resolve token from auth0
    useEffect(() => {
        resolveToken();
    }, [resolveToken, setToken]);

    // server error message snack
    useEffect(() => {
        if (isError) {
            enqueueSnackbar(message, {variant: "error"});
            setIsError(false);
        }
    }, [message, isError, setIsError]);

    /**
     * constructRoutes
     * @return {React.ReactElement}
     */
    const constructRoutes=():React.ReactElement => (
        <RoutesSwitch>
            <Route path={APP_PATHS.HOME} element={<ProtectedRoute component={<SplashView />} public />} />
            <Route path={APP_PATHS.PROPERTIES} element={<ProtectedRoute component={<Properties />} />} />
            <Route path={APP_PATHS.PROPERTY} element={<ProtectedRoute component={<WithTabs tab="PROPERTY" component={Property} />} />} />
            <Route path={APP_PATHS.BAS_POST} element={<ProtectedRoute component={<WithTabs tab="BAS" component={BasDetails} />} />} />
            <Route path={APP_PATHS.BAS_DETAILS} element={<ProtectedRoute component={<WithTabs tab="BAS" component={BasDetails} />} />} />
            <Route path={APP_PATHS.BAS} element={<ProtectedRoute component={<WithTabs tab="BAS" component={Bas} />} />} />
            <Route path={APP_PATHS.EQUIPMENT_POST} element={<ProtectedRoute component={<WithTabs tab="EQUIPMENT" component={EquipmentDetails} />} />} />
            <Route path={APP_PATHS.EQUIPMENT_DETAILS} element={<ProtectedRoute component={<WithTabs tab="EQUIPMENT" component={EquipmentDetails} />} />} />
            <Route path={APP_PATHS.EQUIPMENT} element={<ProtectedRoute component={<WithTabs tab="EQUIPMENT" component={Equipment} />} />} />
            <Route path={APP_PATHS.CAMPUS} element={<ProtectedRoute component={<WithTabs tab="CAMPUS" component={Campus} />} />} />
            <Route path={APP_PATHS.CAMPUS_PROPERTIES} element={<ProtectedRoute component={<WithTabs tab="CAMPUS_PROPERTIES" component={CampusProperties} />} />} />
            <Route path={APP_PATHS.DOCS} element={<ProtectedRoute component={<Docs />} />} />
            <Route path="*" element={<ProtectedRoute component={<Http404 />} />} />
        </RoutesSwitch>
    );

    return (
        <Context value={{token, isError, setIsError, viewState: "NONE"}}>
            <SnackbarProvider {...{anchorOrigin: {horizontal: "center", vertical: "top"}}}>
                <ThemeProvider theme={theme}>
                    <MenuBar />
                    {isLoading
                        ?(
                            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{minHeight: "75vh"}}>
                                <Grid item xs={3}><CircularProgress /></Grid>
                            </Grid>
                        )
                        :constructRoutes()}
                </ThemeProvider>
            </SnackbarProvider>
        </Context>
    );
}

export default Routes;

import React from "react";
import {Grid} from "@mui/material";
import * as yup from "yup";
import {useFormContext} from "react-hook-form";
import {Field, ControlledTextField} from "../../generics/inputs";

interface OperatorDetailsProps{
    fields:Field[]
}

export const Fields:Field[] = [
    {
        key: "operator_name",
        label: "Operator Name",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
    },
    {
        key: "operator_title",
        label: "Position/Title",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
    },
    {
        key: "operator_email",
        label: "Email",
        yup: yup.string().email("Invalid Email."),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
    },
    {
        key: "operator_phone",
        label: "Phone #",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            maskedFormat: "(###)###-####",
            mask: "_",
        },
    },
    {
        key: "operator_working_hours",
        label: "Operator Working Hours",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            maxLength: 128,
            textArea: {
                multiline: true,
                rows: 6,
            },
        },
    },
    {
        key: "operator_notes",
        label: "Notes",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            maxLength: 400,
            textArea: {
                multiline: true,
                rows: 6,
            },
        },
    },
];

/**
 * OperatorDetails
 * @param {OperatorDetailsProps} props
 * @return {React.ReactElement}
 */
function OperatorDetails(props:OperatorDetailsProps):React.ReactElement {
    const methods=useFormContext();
    return (
        <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6} spacing={2}>
                {props.fields.slice(0, 4).map((field:Field) => (
                    <Grid item xs={12} key={field.key}><ControlledTextField field={field} control={methods.control} errors={methods.formState.errors} /></Grid>
                ))}
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6} spacing={3}>
                {props.fields.slice(4, 6).map((field:Field) => (
                    <Grid item xs={12} key={field.key}><ControlledTextField field={field} control={methods.control} errors={methods.formState.errors} /></Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default OperatorDetails;

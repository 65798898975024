import React, {useEffect, useRef, useState, ReactElement} from "react";
import {Box, Grid, List, ListItemIcon, ListItemText, ListItemButton, Typography, Divider, Skeleton, Collapse} from "@mui/material";
import {
    SvgIconComponent,
    ExpandLess,
    ExpandMore,
    ArchiveOutlined,
    DeleteOutlined,
    DoNotDisturbAltOutlined,
    EditNoteOutlined,
    SaveOutlined,
    UnarchiveOutlined,
    FlagOutlined,
    ControlPointDuplicateOutlined,
} from "@mui/icons-material";
import {UseFormReturn, useFormContext, FieldValues, FieldErrors} from "react-hook-form";
import {ViewMode} from "../../types";
import {Button, IconButton, Chip} from "../generics/inputs";

interface Action{
    key:string
    label:string
    onClick?:any
    onError?:any
    type?:"submit"|"button"
    color?:"primary" | "secondary" | "error" | "inherit" | "success" | "info" | "warning"
    variant?:"text"|"contained"|"outlined"
    startIcon:React.ReactElement
    disabled?:boolean

}

export interface Header{
    actions?:Action[]
    primaryLabel:string
    secondaryLabel?:string
    archived?:boolean
    viewMode?:ViewMode
    rootSticky?:boolean
}

export interface SubHeader{
    actions?:Action[]
    tip?:React.ReactElement
    label:string
}

export interface SubMenuItem extends Omit<MenuItem, "content"|"label"|"icon">{
    content:React.ReactElement

}

export interface MenuItem{
    key:string
    label:string
    header?:Header
    subHeader?:SubHeader
    icon:SvgIconComponent
    content:React.ReactElement|SubMenuItem[]
    noBreak?:boolean
    form?:UseFormReturn
    emptyState?:React.ReactElement
}

interface Props {
    menu:MenuItem[]
    log?:React.ReactElement
}

interface Callbacks{
    edit?:{onClick: (args:React.MouseEvent) => void, disabled?:boolean}
    issues?:{onClick: (args:React.MouseEvent) => void, disabled?:boolean}
    archive?:{onClick: (args:React.MouseEvent) => void, disabled?:boolean}
    duplicate?:{onClick: (args:React.MouseEvent) => void, disabled?:boolean}
    restore?:{onClick: (args:React.MouseEvent) => void, disabled?:boolean}
    delete?:{onClick: (args:React.MouseEvent) => void, disabled?:boolean}
    discard?:{onClick: (args:React.MouseEvent) => void, disabled?:boolean}
    update?:{onClick: (values:FieldValues) => void, onError: (args:FieldErrors) => void, disabled?:boolean}
    cancel?:{onClick: (args:React.MouseEvent) => void, disabled?:boolean}
    post?:{onClick: (values:FieldValues) => void, onError: (args:FieldErrors) => void, disabled?:boolean}
}

/**
 * scrollTo
 * @param {string} to
 * @param {"top" | "bottom"} align
 */
export const scrollTo=(to:string, align: "top" | "bottom"):void => {
    const element:any=document.getElementById(to.replace("#", ""));
    if (align === "top") {
        setTimeout(() => {
            const y=(element?.getBoundingClientRect().top||0)+window.scrollY-250;
            if (element) window.scrollTo({top: y});
        }, 0);
    }
    if (align === "bottom") {
        setTimeout(() => {
            const rect = element?.getBoundingClientRect();
            const elementBottom = rect?.bottom;
            const isOutOfView = elementBottom && (elementBottom > window.innerHeight);

            if (isOutOfView) {
                element?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        }, 0);
    }
};

/**
 * resolveActions
 * @param {ViewMode} viewMode
 * @param {boolean|undefined} archived
 * @param {Callbacks} callbacks
 */
export const resolveActions=(viewMode:ViewMode, archived:boolean|undefined, callbacks:Callbacks):Action[] => {
    // header actions
    let actions:any=[
        (callbacks.edit?.onClick && {
            key: "edit",
            label: "EDIT",
            startIcon: <EditNoteOutlined />,
            onClick: callbacks.edit.onClick,
            variant: "contained",
            disabled: callbacks.edit.disabled||false,
        }),
        (callbacks.issues?.onClick && {
            key: "issues",
            label: "ISSUES",
            startIcon: <FlagOutlined />,
            onClick: callbacks.issues.onClick,
            variant: "contained",
            disabled: callbacks.issues.disabled||false,
        }),
        (callbacks.archive?.onClick && {
            key: "archive",
            label: "ARCHIVE",
            startIcon: <ArchiveOutlined />,
            onClick: callbacks.archive.onClick,
            variant: "contained",
            disabled: callbacks.archive.disabled||false,
        }),
        (callbacks.duplicate?.onClick && {
            key: "duplicate",
            label: "Duplicate",
            startIcon: <ControlPointDuplicateOutlined />,
            onClick: callbacks.duplicate.onClick,
            variant: "contained",
            disabled: callbacks.duplicate.disabled||false,
        }),
    ];
    if (archived) {
        actions=[
            (callbacks.restore?.onClick && {
                key: "restore",
                label: "Restore",
                startIcon: <UnarchiveOutlined />,
                onClick: callbacks.restore.onClick,
                color: "primary",
                variant: "contained",
                disabled: callbacks.restore.disabled||false,
            }),
            (callbacks.delete?.onClick && {
                key: "delete",
                label: "Delete",
                startIcon: <DeleteOutlined />,
                onClick: callbacks.delete.onClick,
                color: "warning",
                variant: "contained",
                disabled: callbacks.delete.disabled||false,
            }),
        ];
    } else if (viewMode==="EDIT_MODE") {
        actions=[
            (callbacks.discard?.onClick && {
                key: "discard",
                label: "DISCARD",
                startIcon: <DoNotDisturbAltOutlined />,
                onClick: callbacks.discard.onClick,
                color: "secondary",
                variant: "contained",
                disabled: callbacks.discard.disabled||false,
            }),
            (callbacks.update?.onClick && {
                key: "update",
                label: "SAVE",
                startIcon: <SaveOutlined />,
                onClick: callbacks.update.onClick,
                onError: callbacks.update.onError,
                type: "submit",
                variant: "contained",
                disabled: callbacks.update.disabled||false,
            }),
        ];
    } else if (viewMode==="POST_MODE") {
        actions=[
            (callbacks.cancel?.onClick && {
                key: "cancel",
                label: "CANCEL",
                startIcon: <DoNotDisturbAltOutlined />,
                onClick: callbacks.cancel.onClick,
                color: "secondary",
                variant: "contained",
                disabled: callbacks.cancel.disabled||false,
            }),
            (callbacks.post?.onClick && {
                key: "post",
                label: "SAVE",
                startIcon: <SaveOutlined />,
                onClick: callbacks.post.onClick,
                onError: callbacks.post.onError,
                type: "submit",
                variant: "contained",
                disabled: callbacks.post.disabled||false,
            }),
        ];
    }

    return actions.filter((action:Action) => action!==undefined) as Action[];
};

/**
 * handleKeyDown
 * @param {any} e
 * @returns {void}
 */
export const handleKeyDown = (e: any):void => {
    if (e.key === "Enter" && !["textarea"].includes(e.target.tagName.toLowerCase())) {
        e.preventDefault();
        const fields = Array.from(document.querySelectorAll("input, textarea")).filter((element) => (element as HTMLElement).tabIndex > -1) || [];
        const position = fields.indexOf(e.target);
        if (fields[position + 1]) (fields[position + 1] as HTMLElement).focus();
    }
};

/**
 * WithMenu
 * @param {Props} props
 * @return {React.ReactElement}
 */
function WithMenu(props:Props):React.ReactElement {
    const methods=useFormContext();
    const [hash, setHash]=useState<string>("");
    const wrapperEl:any=useRef();
    const [collapse, setCollapse]=useState(props.menu.reduce((a:any, v:any) => (Array.isArray(v.content)?({...a, [v.key]: true}):a), {}));
    /**
     * onMenuItemClick
     * @param {string} to
     * @return {void}
     */
    const onMenuItemClick=(to:string) => (args:React.MouseEvent):void => {
        setHash(to);
        // NOTE: loading varies per view and therefore might mis-navigate to desired block/div
        window.history.replaceState({hash: to}, "", `${window.location.pathname}#${to}`);
    };

    /**
     * onCollapseClick
     * @param {string} key
     * @return {void}
     */
    const onCollapseClick=(key:string) => (args:React.MouseEvent):void => {
        args.stopPropagation();
        setCollapse({...collapse, [key]: (!collapse[key])});
    };

    // resolve in page nav using hash
    useEffect(() => {
        if (window.location.hash && window.location.hash.length>0) {
            scrollTo(window.location.hash, "top");
        }
    }, [hash]);

    /**
     * constructActionsBlock
     * @param {Action[]} actions
     * @return {React.ReactElement}
     */
    const constructActionsBlock=(actions:Action[]):React.ReactElement => (
        <Box sx={{display: "inline-flex", gap: "16px"}}>
            {actions.map((action:Action) => (
                <Button sx={{padding: "6px 16px"}} {...action} onClick={action?.type==="submit"?undefined:action.onClick} />
            ))}
        </Box>
    );

    /**
     * constructBlock
     * @param {MenuItem|SubMenuItem} menuItem
     * @param {noBreak} boolean
     * @return {React.ReactElement}
     */
    const constructBlock=(menuItem:MenuItem|SubMenuItem, noBreak?:boolean):React.ReactElement => (
        <Grid
            id={menuItem.key}
            key={menuItem.key}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
                marginTop: "0",
                marginBottom: noBreak===true?"0":"var(--4, 32px)",
                borderBottomLeftRadius: noBreak===true && menuItem.subHeader!==undefined?"0":"var(--borderRadius, 4px)",
                borderBottomRightRadius: noBreak===true?"0":"var(--borderRadius, 4px)",
                border: "1px solid var(--primary-focusVisible, rgba(51, 59, 57, 0.30))",
                ...(menuItem.subHeader===undefined && menuItem?.header?.viewMode!==undefined && ["EDIT_MODE", "POST_MODE"].includes(menuItem?.header?.viewMode) && {border: "2px solid var(--primary-main, #333B39)"}),
                borderBottom: noBreak===true?"0":"1px solid var(rgba(51, 59, 57, 0.30))",
            }}
        >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{padding: "var(--2, 16px) var(--4, 32px)"}}>
                {menuItem.content as React.ReactElement}
            </Grid>
        </Grid>
    );

    /**
     * constructHeader
     * @param {MenuItem|SubMenuItem} menuItem
     * @return {React.ReactElement}
     */
    const constructHeader=(menuItem:MenuItem|SubMenuItem):React.ReactElement => (
        <Box
            sx={{
                position: "sticky",
                top: 80,
                zIndex: 2,
                // border + background view mode
                borderRadius: "var(--borderRadius, 4px)",
                ...((menuItem?.header?.viewMode===undefined || menuItem?.header?.viewMode==="VIEW_MODE") && {border: "1px solid var(--primary-focusVisible, rgba(51, 59, 57, 0.30))"}),
                ...((menuItem?.header?.viewMode===undefined || menuItem.header?.viewMode==="VIEW_MODE") && {background: "var(--grey-100, #F5F5F5)"}),
                ...(
                    menuItem?.header?.viewMode!==undefined
                        && ["EDIT_MODE", "POST_MODE"].includes(menuItem?.header.viewMode)
                        && {border: "2px solid var(--primary-main, #333B39)"}),
                ...(
                    menuItem?.header?.viewMode!==undefined
                        && ["EDIT_MODE", "POST_MODE"].includes(menuItem?.header?.viewMode)
                        && {backgroundImage: "repeating-linear-gradient(69deg, rgb(217, 217, 217), rgb(217, 217, 217) 10px, rgb(238, 238, 238) 10px, rgb(238, 238, 238) 20px)"}),
                borderBottomLeftRadius: menuItem.subHeader?"var(--borderRadius, 4px)":"0",
                borderBottomRightRadius: menuItem.subHeader?"var(--borderRadius, 4px)":"0",
            }}
        >
            <Grid sx={{padding: "var(--2, 16px) var(--4, 32px)"}} container direction="row" justifyContent="space-between" alignItems="center">
                {/* labels primary + secondary */}
                <Grid item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                        {menuItem?.header?.secondaryLabel && (
                            <Grid item>
                                <Typography variant="overline">{menuItem?.header?.secondaryLabel}</Typography>
                                {menuItem.header?.archived===true && <Box sx={{marginLeft: "8px", display: "inline"}}><Chip color="secondary" size="small" label="Archived" /></Box>}
                            </Grid>
                        )}
                        <Grid item><Typography variant="h5">{menuItem?.header?.primaryLabel}</Typography></Grid>
                    </Grid>
                </Grid>
                {/* actions */}
                <Grid item>
                    {/* actions */}
                    {menuItem?.header?.actions && (constructActionsBlock(menuItem?.header?.actions))}
                </Grid>
            </Grid>
        </Box>
    );

    /**
     * constructSubHeader
     * @param {MenuItem} menuItem
     * @return {React.ReactElement}
     */
    const constructSubHeader=(menuItem:MenuItem):React.ReactElement => (
        <Box
            sx={{
                marginTop: menuItem.header?"var(--4, 32px)":"0px",
                borderTopLeftRadius: menuItem.noBreak?"0":"var(--borderRadius, 4px)",
                borderTopRightRadius: menuItem.noBreak?"0":"var(--borderRadius, 4px)",
                border: "var(--none, 1px) solid var(--primary-focusVisible, rgba(51, 59, 57, 0.30))",
                borderBottom: "0",
                background: "var(--primary-hover, rgba(51, 59, 57, 0.04))",
                padding: "var(--2, 16px) var(--4, 32px)",
                gap: "var(--1, 8px)",
            }}
        >
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                {/* label + tooltip */}
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item><Typography variant="overline">{menuItem?.subHeader?.label}</Typography></Grid>
                        <Grid item>{menuItem.subHeader?.tip && <Box sx={{marginTop: "-5px", marginLeft: "2px"}}>{menuItem.subHeader?.tip}</Box>}</Grid>
                    </Grid>
                </Grid>
                {/* actions */}
                <Grid item>
                    {/* actions */}
                    {menuItem?.subHeader?.actions && (constructActionsBlock(menuItem?.subHeader?.actions))}
                </Grid>
            </Grid>
        </Box>
    );

    /**
     * constructItem
     * @param {MenuItem} menuItem
     * @param {boolean} noBreak
     * @return {React.ReactElement}
     */
    const constructItem=(menuItem:MenuItem, noBreak?:boolean):React.ReactElement => {
        if (Array.isArray(menuItem.content)) {
            return (
                <Box key={menuItem.key} id={menuItem.key}>
                    {/* main array header */}
                    <Grid sx={{padding: "var(--2, 16px) var(--4, 0px)"}} container direction="row" justifyContent="space-between" alignItems="center">
                        {/* labels primary + secondary */}
                        <Grid item>
                            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item sx={{display: "flex"}}>
                                    <Typography variant="h5">{menuItem.label}</Typography>
                                    <Chip sx={{marginLeft: "12px", marginTop: "1px"}} label={menuItem.content.length} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* actions */}
                        <Grid item>
                            {/* actions */}
                            {menuItem?.header?.actions && (constructActionsBlock(menuItem.header.actions))}
                        </Grid>
                    </Grid>
                    {/* array content */}
                    {(menuItem.content as SubMenuItem[]).length===0 && menuItem.emptyState}
                    {(menuItem.content as SubMenuItem[]).map((subMenu:SubMenuItem) => {
                        let content=(
                            <Box key={subMenu.key}>
                                {/* header */}
                                {subMenu.header && constructHeader(subMenu)}
                                {/* block */}
                                <Box>{constructBlock(subMenu, noBreak)}</Box>
                            </Box>
                        );
                        if (subMenu.form) {
                            const subMenuA:Action=subMenu.header?.actions?.find((action:Action) => action.type==="submit") as Action;
                            content=(
                                <form key={menuItem.key} onSubmit={subMenu.form.handleSubmit(subMenuA?.onClick, subMenuA?.onError)}>
                                    {content}
                                </form>
                            );
                        }
                        return content;
                    })}
                </Box>
            );
        }
        let content=(
            <Box key={menuItem.key}>
                {/* header */}
                {menuItem.header && menuItem.header.rootSticky!==true && constructHeader(menuItem)}
                {/* sub header */}
                {menuItem.subHeader && constructSubHeader(menuItem)}
                {/* block */}
                <Box>{constructBlock(menuItem, noBreak)}</Box>
            </Box>
        );

        if (menuItem.form) {
            const menuA:Action=menuItem.header?.actions?.find((action:Action) => action.type==="submit") as Action;
            content=(
                <form key={menuItem.key} onSubmit={menuItem.form.handleSubmit(menuA?.onClick, menuA?.onError)}>
                    {content}
                </form>
            );
        }

        return content;
    };
    /**
     * constructMainList
     * @param {MenuItem[]} menu
     * @return {React.ReactElement[]}
     */
    const constructMainList=(menu:MenuItem[]):React.ReactElement[] => {
        const content:React.ReactElement[]=[];
        menu.forEach((item:MenuItem, index:number) => {
            if (item.noBreak===true && index!==0) content[index-1]=constructItem(menu[index-1], true);
            content.push(constructItem(item));
        });
        return content;
    };

    /**
     * constructSideListSubItems
     * @param {MenuItem} menuItem
     * @return {React.ReactElement}
     */
    const constructSideListSubItems=(menuItem:MenuItem):React.ReactElement|null => {
        const menuItems:React.ReactElement[]=[];
        // escape if null
        if (menuItem.content===null) return <Skeleton sx={{marginLeft: "5%"}} height={40} width="95%" />;
        // escape if failure
        if (!Array.isArray(menuItem.content) || (menuItem.content as SubMenuItem[]).length===0) return null;
        // construct sub menu items
        (menuItem.content as SubMenuItem[]).forEach((subItem:SubMenuItem, index:number) => {
            menuItems.push(
                <ListItemButton
                    sx={{background: subItem.header?.viewMode!=="VIEW_MODE"?"var(--grey-100, #F5F5F5)":undefined}}
                    onClick={onMenuItemClick(subItem.key)}
                    key={subItem.key}
                >
                    <ListItemText primary={subItem.header?.primaryLabel} secondary={subItem.header?.secondaryLabel?.toUpperCase()} />
                </ListItemButton>,
            );
            if (index<(menuItem.content as SubMenuItem[]).length-1) menuItems.push(<Divider key={`divider-${subItem.key}`} />);
        });

        return (
            <List dense sx={{padding: 0, maxHeight: `${window.innerHeight/2}px`, overflow: "auto"}}>
                {menuItems}
            </List>
        );
    };

    /**
     * constructSideList
     * @param {MenuItem[]} menu
     * @return {React.ReactElement}
     */
    const constructSideList=(menu:MenuItem[]):ReactElement => {
        const menuItems:React.ReactElement[]=[];
        menu.forEach((menuItem:MenuItem, index:number) => {
            // resolve primary text
            let primaryLabel:any=menuItem.label;
            if (Array.isArray(menuItem.content) && (menuItem.content as SubMenuItem[]).length!==0) {
                primaryLabel=(
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Grid item><Typography sx={{marginTop: "8px"}}>{primaryLabel}</Typography></Grid>
                        <Grid item><IconButton onClick={onCollapseClick(menuItem.key)}>{collapse[menuItem.key]?<ExpandLess />:<ExpandMore />}</IconButton></Grid>
                    </Grid>
                );
            }
            // push menu
            menuItems.push(
                <ListItemButton onClick={onMenuItemClick(menuItem.key)} key={menuItem.key}>
                    <ListItemIcon><menuItem.icon /></ListItemIcon>
                    <ListItemText primary={primaryLabel} />
                </ListItemButton>,
            );
            // push sub menu (if applicable)
            menuItems.push(<Collapse key={`${menuItem.key}-subitems`} in={collapse[menuItem.key]}><Box sx={{marginLeft: "60px", marginRight: "16px"}}>{constructSideListSubItems(menuItem)}</Box></Collapse>);
            // push divider
            if (index<menu.length-1) menuItems.push(<Divider key={`divider-${menuItem.key}`} />);
        });

        const height=wrapperEl?.current?.clientHeight;
        return (
            <Box sx={{height: {md: `${(height && height-150)||500}px`}}}>
                <List
                    sx={{
                        position: "sticky",
                        top: 80,
                        border: "1px solid #C2C4C4",
                        borderRadius: "var(--borderRadius, 4px)",
                    }}
                >
                    {menuItems}
                </List>
            </Box>
        );
    };

    let content=(
        <Box ref={wrapperEl} onKeyDown={handleKeyDown}>
            {/* wrapper */}
            <Grid spacing={4} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {/* side list */}
                <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                    {constructSideList(props.menu)}
                </Grid>
                {/* main list */}
                <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
                    {/* inject header if sticky to root */}
                    {props.menu[0].header?.rootSticky===true && constructHeader(props.menu[0])}
                    {constructMainList(props.menu)}
                    {/* changelog section */}
                    {props.log}
                </Grid>
            </Grid>
        </Box>
    );

    if (methods) {
        const a:Action=props.menu[0]?.header?.actions?.find((action:Action) => action.type==="submit") as Action;
        content=(
            <form onSubmit={methods.handleSubmit(a?.onClick, a?.onError)}>{content}</form>
        );
    }

    return content;
}

WithMenu.defaultProps={
    log: undefined,
};

export default WithMenu;

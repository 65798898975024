import React, {useEffect, useMemo, useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {Box} from "@mui/material";
import {useAuth0, Auth0ContextInterface, User, withAuthenticationRequired} from "@auth0/auth0-react";
import {styled} from "@mui/material/styles";
import {APP_PATHS} from "../../config";
import {asyncForEach, validateId} from "../../handlers";
import Breadcrumbs from "./Breadcrumbs";
import {EQUIPMENTS} from "../forms";

/**
 * replaceEmail
 * @param {string} s
 * @param {string} e
 * @return {string}
 */
const replaceEmail=(s:string, e:string):string => s?.replace(/<EMAIL>/g, e);

const MESSAGES:any={
    signout: "You have signed out.",
    signin: "Signed in as <EMAIL>.",
};

export const Main=styled("main", {shouldForwardProp: (prop) => prop!=="isAuth"})<{
    isAuth?:boolean;
    }>(({theme, isAuth}) => ({
        flexGrow: 1,
        padding: isAuth?theme.spacing(3):0,
        paddingLeft: isAuth?"100px":0,
        paddingRight: isAuth?"100px":0,
        marginLeft: 0,
        height: "100%",
    }));

export const AppBarSpacer = styled("div")(({theme}) => ({
    ...theme.mixins.toolbar,
}));

interface ProtectedRouteProps {
    component:React.ReactElement
    public?:boolean
}

/**
 * ProtectedRoute
 * @param {ProtectedRouteProps} props
 * @return {React.ReactElement}
 */
function ProtectedRoute(props:ProtectedRouteProps):React.ReactElement {
    const isHome=window.location.pathname!==APP_PATHS.HOME;
    const {user}:Auth0ContextInterface<User>= useAuth0();
    const search:any=useMemo(() => new URLSearchParams(window.location.search), []);
    const navigate=useNavigate();
    const params=useParams();

    /**
     * validateParams
     * @param {any} values
     * @return {void}
     */
    const validateParams=useCallback(async (values:any):Promise<void> => {
        // change values to array and async loop over
        asyncForEach(Object.keys(values).map((key:string) => ({key, value: values[key]})), async (item:any) => {
            // redirect to /404 -- pathname does not exist under route however will result into *
            if (item.key==="systemId") {
                if (EQUIPMENTS.find((e:any) => e.key===item.value)===undefined) navigate("/404");
                return;
            } if (!await validateId(item.value)) navigate("/404");
        });
    }, [navigate]);

    useEffect(() => {
        // validate params
        // NOTE: needed so to check if id isNumber
        validateParams(params);
    }, [params, validateParams]);

    // trigger snackbar based on URL params
    useEffect(() => {
        // resolve access_denied
        if (search.has("error")) {
            enqueueSnackbar(search.get("error_description"), {variant: "error", transitionDuration: {enter: 200, exit: 1000}, autoHideDuration: 10000});
            navigate(APP_PATHS.HOME);
        }
        // resolve "signin"
        if (search.has("signin")) {
            enqueueSnackbar(replaceEmail(MESSAGES.signin, user?.email || ""), {variant: "success"});
            search.delete("signin");
            navigate(window.location.pathname);
        }
        // resolve "signout"
        if (search.has("signout")) {
            enqueueSnackbar(MESSAGES.signout, {variant: "success"});
            search.delete("signout");
            navigate(APP_PATHS.HOME);
        }
    }, [search, user?.email, navigate]);

    // content
    const main=(
        <Main isAuth={isHome}>
            <AppBarSpacer />
            {isHome && <Box sx={{marginBottom: "24px"}}><Breadcrumbs /></Box>}
            <Box sx={{paddingBottom: isHome?"24px":"none"}}>{props.component}</Box>
        </Main>
    );

    // escape if route is public
    if (props.public===true) return main;

    // validate via Auth0 if route is private
    const Component=withAuthenticationRequired(() => main);
    return <Component />;
}

ProtectedRoute.defaultProps={
    public: undefined,
};

export default ProtectedRoute;

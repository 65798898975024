import React from "react";
import {Box} from "@mui/material";
import {NewReleasesOutlined, Search, ArrowBack} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import EmptyStateBlock from "../components/generics/EmptyStateBlock";
import {APP_PATHS} from "../config";

/**
 * Http404
 * @return {React.ReactElement}
 */
function Http404():React.ReactElement {
    const navigate=useNavigate();
    return (
        <Box>
            <EmptyStateBlock
                header="Page not found..."
                subHeaders={["Sorry, but the page you requested does not exist."]}
                icon={NewReleasesOutlined}
                primaryAction={{
                    onClick: (args:React.MouseEvent) => navigate(APP_PATHS.PROPERTIES),
                    label: "SEARCH PROPERTIES",
                    startIcon: <Search />,
                }}
                secondaryAction={{
                    onClick: (args:React.MouseEvent) => navigate(-2),
                    label: "BACK",
                    startIcon: <ArrowBack />,
                }}
            />
        </Box>
    );
}

export default Http404;

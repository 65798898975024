import React, {useEffect} from "react";
import {AxiosResponse} from "axios";
import {UseFieldArrayReturn, useFormContext} from "react-hook-form";
import _ from "lodash";
import {Box, Grid, Skeleton} from "@mui/material";
import {Field} from "../generics/inputs";
import Alert from "../generics/Alert";

interface Props {
    fieldArrayReturn:UseFieldArrayReturn
    fields:Field[]
    response:AxiosResponse|null
    form:{
        component:React.JSXElementConstructor<any>
        key:string
        setter:(item:any) => any
        props?:any
    }
    noRowsOverlay:React.ReactElement
}

/**
 * WithTable
 * @param {Props} props
 * @return {React.ReactElement}
 */
function WithTable(props:Props):React.ReactElement {
    const {reset}=useFormContext();
    const {append, fields, remove}:UseFieldArrayReturn=props.fieldArrayReturn;
    const {key, setter}=props.form;

    // initialize field array with response results (records)
    useEffect(() => {
        if (props.response && Array.isArray(props.response?.data.results)) {
            props.response.data.results.forEach((item:any) => {
                append(setter(item));
            });
        } else reset({[key]: []});
    }, [append, props.response, reset, key, setter]);

    // escape if no response!
    if (props.response===null) {
        return (
            <Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="flex-start">
                {[1, 2, 3, 4].map((n:number) => (
                    <Grid item key={n} container spacing={2}>
                        <Grid item xs={0.7} sx={{marginTop: "15px"}}><Skeleton variant="circular" width={40} height={40} /></Grid>
                        <Grid item xs={2}><Skeleton variant="text" sx={{fontSize: "3rem"}} /></Grid>
                        <Grid item xs={2}><Skeleton variant="text" sx={{fontSize: "3rem"}} /></Grid>
                        <Grid item xs={7.3}><Skeleton variant="text" sx={{fontSize: "3rem"}} /></Grid>
                    </Grid>
                ))}
            </Grid>
        );
    }
    // escape if response is not success!
    if (props.response.status!==200) return <Alert severity="error" title="Fetch Failure" body={`An error occured while fetching ${props.form.key}`} />;

    if (props.response.data.results===0) return props.noRowsOverlay;

    const items:React.ReactElement[]=[];
    // iterate over field array (form array)
    fields.forEach((item:any, index:number) => {
        const clone=_.cloneDeep(props.fields);
        clone.forEach((field:Field) => { field.key=`${key}.${index}.${field.key}`; }); // eslint-disable-line no-param-reassign
        items.push(
            <props.form.component
                index={index}
                key={item.id}
                fields={clone}
                onDelete={(args:React.MouseEvent) => remove(index)}
                {...props.form.props}
            />,
        );
    });

    if (items.length===0) return props.noRowsOverlay;

    return (
        <Box>{items}</Box>
    );
}

export default WithTable;

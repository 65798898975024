import React, {useState} from "react";
import {InfoOutlined, SvgIconComponent} from "@mui/icons-material";
import {IconButton, Popover, Box} from "@mui/material";

interface Props{
    icon?:SvgIconComponent
    content:React.ReactElement
}

/**
 * HelpPopover
 * @param {Props} props
 * @return {React.ReactElement}
 */
function HelpPopover(props:Props):React.ReactElement {
    const [anchorEl, setAnchorEl]=useState<HTMLButtonElement|null>(null);
    /**
     * onMouseEnter
     * @param {React.MouseEvent<HTMLButtonElement>} args
     * @return {void}
     */
    const onMouseEnter=(args:React.MouseEvent<HTMLButtonElement>):void => setAnchorEl(args.currentTarget);

    /**
     * onMouseLeave
     * @param {React.MouseEvent<HTMLButtonElement>} args
     * @return {void}
     */
    const onMouseLeave=(args:React.MouseEvent<HTMLButtonElement>):void => setAnchorEl(null);

    return (
        <Box>
            {/* button */}
            <IconButton
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {props.icon?<props.icon />:<InfoOutlined />}
            </IconButton>
            {/* popover */}
            <Popover
                sx={{pointerEvents: "none"}}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                transformOrigin={{vertical: "top", horizontal: "left"}}
                onClose={onMouseLeave}
                disableRestoreFocus
            >
                <Box
                    sx={{
                        padding: "var(--1, 8px)",
                        borderRadius: "var(--borderRadius, 4px)",
                        background: "var(--components-tooltip-fill, rgba(97, 97, 97, 0.90))",
                        color: "var(--common-white-main, #FFF)",
                    }}
                >
                    {props.content}
                </Box>
            </Popover>
        </Box>
    );
}

HelpPopover.defaultProps={
    icon: undefined,
};

export default HelpPopover;

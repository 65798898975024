import React, {useContext as useContextBase, useMemo, createContext, SetStateAction, Dispatch, useState} from "react";

export const APP_CONTEXT = createContext({});

type VIEW_STATE="NONE"|"LOADING"|"REFRESH"|"PENDING_BEGIN"|"PENDING_END";

interface Props {
    children:React.ReactNode,
    value:{
        token:string
        isError:boolean
        setIsError:Dispatch<SetStateAction<boolean>>
        viewState:VIEW_STATE
    }
}

/**
 * useContext
 * @return {any}
 */
export const useContext=():any => useContextBase(APP_CONTEXT) as Props;

/**
 * Context
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Context(props:Props):React.ReactElement {
    const [viewState, setViewState]=useState<VIEW_STATE>(props.value.viewState);
    return (
        <APP_CONTEXT.Provider
            value={useMemo(
                () => ({
                    token: props.value.token,
                    isError: props.value.isError,
                    setIsError: props.value.setIsError,
                    viewState,
                    setViewState,
                }),
                [
                    props.value.token,
                    props.value.isError,
                    props.value.setIsError,
                    viewState,
                    setViewState,
                ],
            )}
        >
            {props.children}
        </APP_CONTEXT.Provider>
    );
}

export default Context;

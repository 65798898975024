import React from "react";
import {Grid, IconButton, Typography, Chip, Box} from "@mui/material";
import {DeleteOutlined} from "@mui/icons-material";
import * as yup from "yup";
import {useFormContext} from "react-hook-form";
import {populateValues} from "../../../handlers";
import {Field, ControlledTextField, ControlledAutocomplete} from "../../generics/inputs";
import {ViewMode} from "../../../types";
import {ALPHA_NUMERIC_FIELD_REGEX} from "../../../config";

interface RealTimeMetersProps{
    fields:Field[]
    index:number
    viewMode:ViewMode
    types:string[]
    sources:string[]
    onDelete:(args:React.MouseEvent) => void
}

export const Fields:Field[] = [
    {
        key: "type",
        label: "Meter Type",
        yup: yup.string().required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: false,
            fieldLabel: true,
            tooltip: true,
        },
    },
    {
        key: "meter_id",
        label: "Meter ID",
        yup: yup.string().matches(ALPHA_NUMERIC_FIELD_REGEX.regex, ALPHA_NUMERIC_FIELD_REGEX.message).required("Required"),
        type: "textfield",
        textfieldOptions: {
            readOnly: false,
            fieldLabel: true,
        },
    },
    {
        key: "source",
        label: "Source",
        yup: yup.string().required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: false,
            fieldLabel: true,
            tooltip: true,
        },
    },
    {
        key: "notes",
        label: "Meter Serves",
        yup: yup.string().required("Required"),
        type: "textfield",
        textfieldOptions: {
            readOnly: false,
            fieldLabel: true,
        },
    },
];

/**
 * RealTimeMeters
 * @param {RealTimeMetersProps} props
 * @return {React.ReactElement}
 */
function RealTimeMeters(props:RealTimeMetersProps):React.ReactElement {
    const methods=useFormContext();
    // set field options
    populateValues(props.types, props.fields, "type");
    populateValues(props.sources, props.fields, "source");

    // breakpoints
    const breaks=[
        {xs: 0.5, sm: 0.5, md: 1.0, lg: 1.0, xl: 0.6},
        {xs: 2.5, sm: 2.5, md: 2.5, lg: 2.5, xl: 2.0},
        {xs: 2.5, sm: 2.5, md: 2.5, lg: 2.5, xl: 2.0},
        {xs: 2.5, sm: 2.5, md: 2.5, lg: 2.5, xl: 2.0},
        {xs: 2.5, sm: 2.5, md: 2.5, lg: 2.5, xl: 4.7},
        {xs: 0.5, sm: 0.5, md: 1.0, lg: 0.7, xl: 0.5},
    ];
    // resolve header
    const header:React.ReactElement[]=[
        <Grid item {...breaks[0]} key={`${props.index}-index`}><Typography variant="body1">#</Typography></Grid>,
    ];
    // iterate over fields headers
    props.fields.forEach((field:Field, index:number) => header.push(<Grid item key={field.key} {...breaks[index+1]}>{field.label}</Grid>));

    // pushing delete placeholder
    header.push(<Grid item {...breaks[breaks.length-1]} key={`${props.index}-delete`} />);

    return (
        <Box>
            {props.index===0 && (
                <Grid sx={{marginLeft: "-20px", marginBottom: "20px"}} container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">{header}</Grid>
            )}
            <Grid container spacing={{xs: 1, lg: 3}} direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item {...breaks[0]} sx={{marginTop: "10px"}}><Chip label={props.index+1} /></Grid>
                <Grid item {...breaks[1]}><ControlledAutocomplete field={props.fields[0]} control={methods.control} errors={methods.formState.errors} /></Grid>
                <Grid item {...breaks[2]}><ControlledTextField field={props.fields[1]} control={methods.control} errors={methods.formState.errors} /></Grid>
                <Grid item {...breaks[3]}><ControlledAutocomplete field={props.fields[2]} control={methods.control} errors={methods.formState.errors} /></Grid>
                <Grid item {...breaks[4]}><ControlledTextField field={props.fields[3]} control={methods.control} errors={methods.formState.errors} /></Grid>
                <Grid item {...breaks[5]} sx={{marginTop: "6px"}}><IconButton disabled={props.viewMode==="VIEW_MODE"} onClick={props.onDelete}><DeleteOutlined /></IconButton></Grid>
            </Grid>
        </Box>
    );
}

export default RealTimeMeters;
